import defaultTheme from "./default";

import { createMuiTheme } from "@material-ui/core";

const overrides = {
  typography: {
    h1: {
      fontSize: "60px",
      fontFamily: '"Roboto Slab", serif !important',
    },
    h2: {
      fontSize: "40px",
      fontFamily: '"Roboto Slab", serif !important',
    },
    h3: {
      fontSize: "30px",
      fontFamily: '"Roboto Slab", serif !important',
    },
    h4: {
      fontSize: "24px",
      fontFamily: '"Roboto Slab", serif !important',
    },
    h5: {
      fontSize: "1.285rem",
      fontFamily: '"Roboto Slab", serif !important',
    },
    h6: {
      fontSize: "1.142rem",
      lineHeight: "25px",
      fontFamily: '"Roboto", sans-serif !important',
    },
    body1: {
      fontSize: "12px",
      fontWeight: "700",
      fontFamily: '"Roboto", sans-serif !important',
    },
    body2: {
      fontSize: "16px",
      lineHeight: "25px",
      fontFamily: '"Roboto", sans-serif !important',
    },
  },
};

const themes = {
  default: createMuiTheme({ ...defaultTheme, ...overrides }),
};

export default themes;
