import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Grid,
  Button,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import clsx from "clsx";
import { BiChevronDown } from "react-icons/bi";
import { BiTransfer } from "react-icons/bi";
import MetaReflectABI from "../../abis/MetaReflectABI.json";
import { MetaReflectBinance } from "../../constants/index";
import { HiOutlineInformationCircle } from "react-icons/hi";
import Tooltip from "@material-ui/core/Tooltip";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { BsSearch } from "react-icons/bs";
import PancakeRouterABI from "../../abis/PancakeRouterABI.json";
import ERC20ABI from "../../abis/IERC20ABI.json";
import Web3 from "web3";
import axios from "axios";
import {
  pancakeRouter,
  getWeb3Obj,
  getWeb3ContractObject,
  getContract,
  accountBNBBalance,
  mainnetRPCURL,
} from "../../utils";
import moment from "moment";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    "& .MuiDialogContentText-root": {
      padding: "0 24px",
    },
  },
  heading: {
    paddingTop: "20px",
    "& h1": {
      // fontFamily: "Montserrat",
      fontWeight: "600",
      marginBottom: "12px",
      fontSize: "50px",
      lineHeight: "76px",
      textAlign: "center",
      color: "#efab00",
      marginTop: "30px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
        lineHeight: "40px",
        marginBottom: "0px",
      },
    },
  },
  swapBox: {
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto",
    border: "1px solid #16abd31f",
    padding: "15px",
    position: "relative",
    background: "rgb(38 40 49)",
    transition: " 0.5s",
    borderRadius: "10px",
    // height:"100%",
    "& label": {
      color: "#e6e6e6",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
      "& button": {
        minWidth: "auto",
        padding: 0,
        fontSize: "18px",
        color: "#fff",
        marginLeft: "5px",
      },
    },
    "& small": {
      color: "#b2b2b2",
      fontSize: "14px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
      "& strong": {
        color: "#fff",
      },
    },
    "& .paybox": {
      padding: "10px",
      backgroundColor: "#1d1e20",
      borderRadius: "5px",
      "& input": {
        backgroundColor: " transparent",
        border: "none",
        color: "#ffff",
        fontSize: "18px",
        fontWeight: 800,
        textAlign: "right",
        width: "100%",
        "&:focus-visible": {
          outline: "none !important",
        },
      },
      "& p": {
        fontSize: "14px",
        color: "#b2b2b2",
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px",
        },
      },
      "& h6": {
        display: "flex",
        alignItems: "center",
        fontWeight: 800,
        color: "#fff",
        [theme.breakpoints.down("xs")]: {
          fontSize: "14px",
        },
        "& img": {
          width: "25px",
          marginRight: "6px",
          [theme.breakpoints.down("xs")]: {
            width: "18px",
          },
        },
      },
    },
    "& .transfer": {
      width: " 100%",
      maxWidth: "30px",
      height: "30px",
      borderRadius: "50%",
      backgroundColor: "#071426",
      color: " #fff",
      fontSize: "15px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "10px auto",
      transform: "rotate(90deg)",
      cursor: "pointer",
    },
  },
  buttonright: {
    fontSize: "13px ",
    border: "2px solid transparent",
    fontWeight: 600,
    height: "44px ",
    background: "linear-gradient(272.26deg, #efab00e0 36.78%, #efab00 86.13%)",
    color: "#fff",
    minWidth: "125px ",
    width: "100%",
    borderRadius: "30px",
    boxShadow: "inset 0px 0px 2px rgba(0, 0, 0, 0.4)",
    // boxShadow: "none ",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "45px ",
      minWidth: "120px ",
    },
    "&:hover": {
      color: "#fff",
      background: "#0c2739",

      border: "2px solid transparent",
      boxSizing: "border-box",
      backgroundClip: "padding-box, border-box",
      backgroundImage:
        "linear-gradient(#1d1e20, #262831), linear-gradient(#efab00, #efab00cc)",
      backgroundOrigin: "padding-box, border-box",
    },
  },
  tokenBox: {
    paddingTop: "10px",
    height: "246px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
  },
  solutionBox: {
    backgroundColor: "#071426",
    padding: "10px",
    display: "flex",
    marginBottom: "6px",
    alignItems: "center",
    borderRadius: " 5px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#113044",
    },
    "& h4": {
      fontSize: "16px",
      color: "#ffffff",
      fontWeight: " 800",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
    "& p": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
    "& figure": {
      margin: "0",
      width: "30px",
      marginRight: "20px",
      [theme.breakpoints.down("xs")]: {
        width: "25px",
        marginRight: "15px",
      },
      "& img": {
        width: "auto",
        maxWidth: "100%",
      },
    },
  },
  paddingbox: {
    paddingTop: "20px",
    "& p": {
      color: "#fff",
    },
  },
  toastStyl: {
    // "& .Toastify__toast-body": {
    overflowX: "auto",
    // },
  },
}));
const SolutionCard = [
  {
    image: "images/bnb.png",
    heading: "BNB",
    discription: "BNB",
    type: "noApprove",
    function: "swapExactETHForTokens",
    token: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
  },
  {
    image: "images/logo.png",
    heading: "MRF",
    discription: "MetaReflect (MRF)",
    type: "approve",
    function: "swapExactTokensForETHSupportingFeeOnTransferTokens",
    token: "0x1e2d9f6a71857853f414bf799415b5e56c19def3",
  },
  {
    image: "images/BUSD.png",
    heading: "BUSD",
    discription: "Binance Pegged BUSD",
    type: "approve",
    function: "swapExactTokensForETHSupportingFeeOnTransferTokens",
    token: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  },
  {
    image: "images/USDT.png",
    heading: "USDT",
    discription: "Binance Pegged USDT",
    type: "approve",
    function: "swapExactTokensForETHSupportingFeeOnTransferTokens",
    token: "0x55d398326f99059ff775485246999027b3197955",
  },
];
export default function Dashboard(props) {
  var classes = useStyles();
  const marketCap = window.sessionStorage.getItem("marketcap");
  // console.log("datatatta", marketCap);
  const [alignment, setAlignment] = React.useState();
  const [checked, setChecked] = React.useState(false);
  const [swapFrom, setSwapFrom] = useState(SolutionCard[0]);
  const [swapFromValue, setSwapFromValue] = useState(0);
  const [swapToValue, setSwapToValue] = useState(0);
  const [swapTo, setSwapTo] = useState(SolutionCard[1]);
  const [selectedToken, setSelectedToken] = useState();
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const [selectTokenType, setSelectTokenType] = useState();
  const [coinBalance, setCoinBalance] = useState();
  const slippage = 12;
  const { account, library, chainId } = useWeb3React();

  const getBalance = async () => {
    const web3 = new Web3(library.provider);

    const VAYUContract = getContract(
      SolutionCard[1].token,
      ERC20ABI,
      library,
      account
    );
    const BUSDContract = getContract(
      SolutionCard[2].token,
      ERC20ABI,
      library,
      account
    );
    const USDTContract = getContract(
      SolutionCard[3].token,
      ERC20ABI,
      library,
      account
    );
    const Bbalance = await web3.eth.getBalance(account);
    const YAbalance = (await VAYUContract.balanceOf(account)).toString();
    const BUbalance = (await BUSDContract.balanceOf(account)).toString();
    const USbalance = (await USDTContract.balanceOf(account)).toString();
    const BNB = web3.utils.fromWei(Bbalance);
    const VAYU = web3.utils.fromWei(YAbalance);
    const BUSD = web3.utils.fromWei(BUbalance);
    const USDT = web3.utils.fromWei(USbalance);
    setCoinBalance({
      BNB,
      VAYU,
      BUSD,
      USDT,
    });
  };
  useEffect(() => {
    if (chainId === 56) {
      getBalance();
    }
  }, [account]);

  const handleAlignment = async (event, newAlignment) => {
    setAlignment(newAlignment);
    let selectedBalance = coinBalance[swapFrom.heading];
    const percentValue = ((selectedBalance / 100) * newAlignment).toString();
    // console.log("selectedBalance", percentValue);
    if (percentValue > 0) {
      setFromValue(percentValue);
      const price = await recivePrice(
        swapFrom.token,
        swapTo.token,
        percentValue
      );
      setToValue(Number(price).toFixed(9).toString());
    } else {
      setFromValue("");
      setToValue("");
    }
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (name, type) => {
    setOpen(true);
    setSelectedToken(name);
    setSelectTokenType(type);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const swapTokenData = () => {
    const tempFromData = { ...swapFrom };
    const tempToData = { ...swapTo };
    const tempFromValue = fromValue;
    const tempToValue = toValue;
    setSwapFrom(tempToData);
    setSwapTo(tempFromData);
    setFromValue(Number(tempToValue).toFixed(9).toString());
    setToValue(Number(tempFromValue).toFixed(9).toString());
  };

  const getdata = () => {
    const pancakeRouterContract = getContract(
      MetaReflectBinance,
      MetaReflectABI,
      library,
      account
    );
    console.log("pancakeRouterContract", pancakeRouterContract);
  };
  useEffect(() => {
    getdata();
  }, []);

  const swapToken = async () => {
    try {
      if (account) {
        let functionName;
        const web3 = await getWeb3Obj();
        const amoutInWei = web3.utils.toWei(fromValue);
        const pancakeRouterContract = getContract(
          pancakeRouter,
          PancakeRouterABI,
          library,
          account
        );
        console.log("pancakeRouterContract", pancakeRouterContract);
        const tenPercent = Number(toValue) - (Number(toValue) / 100) * slippage;
        const crtTime = moment(new Date()).add(20, "minutes").unix();
        if (swapFrom.type === "noApprove" && swapTo.type === "approve") {
          functionName = "swapExactETHForTokens";
          const estimatedGas = await estimateGas(
            "swapExactETHForTokens",
            tenPercent,
            crtTime
          );
          pancakeRouterContract.swapExactETHForTokens(
            web3.utils.toWei(tenPercent.toFixed(9).toString(), "gwei"),
            [swapFrom.token, swapTo.token],
            account,
            crtTime,
            {
              from: account,
              value: web3.utils.toWei(fromValue),
              gasPrice: "10000000000",
              gasLimit: web3.utils.toHex(estimatedGas),
            }
          );
        } else if (swapFrom.type === "approve" && swapTo.type === "approve") {
          functionName =
            "swapExactTokensForTokensSupportingFeeOnTransferTokens";
          approveToken();
        } else {
          functionName = "swapExactTokensForETHSupportingFeeOnTransferTokens";
          approveToken();
        }
      }
    } catch (err) {
      console.log(err.message);
      toast.error(<span className={classes.toastStyl}>{err.message}</span>);
    }
  };

  const approveToken = async () => {
    const web3 = await getWeb3Obj();
    let maxValue = await accountBNBBalance(account);
    console.log("maxValue", maxValue);

    if (maxValue - 0.003 < 0) {
      // alert("Insufficient funds for Tx Fees");
      // toast.error("Insufficient funds for Tx Fees");
      // return;
    }
    // setLoader(true);
    const myContract = getContract(swapFrom.token, ERC20ABI, library, account);
    console.log("myContract", myContract);

    let allowance = await myContract.allowance(account, pancakeRouter);
    console.log("allowance", allowance);

    if (Number(allowance) < Number(web3.utils.toWei(fromValue, "gwei"))) {
      myContract.approve(
        pancakeRouter,
        "115792089237316195423570985008687907853269984665640564039457584007913129639935",
        {
          from: account,
          gasPrice: "50000000000",
          gasLimit: web3.utils.toHex("300000"),
        }
      );
    } else {
      console.log("Else");
    }
  };

  const estimateGas = async (functionName, tenPercent, crtTime) => {
    const pancakeRouterContract = await getWeb3ContractObject(
      PancakeRouterABI,
      pancakeRouter,
      mainnetRPCURL
    );
    const web3 = await getWeb3Obj();
    const gasEstimate = await pancakeRouterContract.methods[functionName](
      web3.utils.toWei(tenPercent.toFixed(9).toString(), "gwei"),
      [swapFrom.token, swapTo.token],
      account,
      crtTime
    ).estimateGas({
      from: account,
      value: web3.utils.toWei(fromValue),
    });
    return gasEstimate;
  };

  const SwapToValuesFunction = async () => {
    try {
      axios({
        method: "GET",
        url: `https://api.pancakeswap.info/api/v2/tokens/${swapTo.token}`,
      }).then(async (res) => {
        console.log("to Token Price", res.data.data);
        if (res.status === 200) {
          // console.log("stakingList", res.data.data.);
          setSwapToValue(res.data.data.price);
          // setState(1);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    SwapToValuesFunction();
  }, [swapTo.token]);

  // useEffect(async () => {
  const SwapFromValuesFunction = async () => {
    try {
      axios({
        method: "GET",
        url: `https://api.pancakeswap.info/api/v2/tokens/${swapFrom.token}`,
      }).then(async (res) => {
        console.log("From Token Price", res.data.data);
        if (res.status === 200) {
          // console.log("stakingList", res.data.data.);
          setSwapFromValue(res.data.data.price);
          // setState(1);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    SwapFromValuesFunction();
  }, [swapFrom.token]);

  const changeTokenName = async (data) => {
    console.log("token select name values ", data);
    if (selectTokenType === "from") {
      setSwapFrom(data);
      try {
        axios({
          method: "GET",
          url: `https://api.pancakeswap.info/api/v2/tokens/${data.token}`,
        }).then(async (res) => {
          console.log("rewardTokenPrice", res);
          if (res.status === 200) {
            // console.log("stakingList", res.data.data.);
            // setrewardTokenPrice(res.data.data.price);
            // setState(1);
          }
        });
      } catch (error) {
        console.log(error);
      }
      if (fromValue != 0) {
        const price = await recivePrice(
          swapFrom.token,
          swapTo.token,
          fromValue
        );
        setToValue(Number(price).toFixed(9).toString());
      }
    } else {
      setSwapTo(data);
      if (toValue != 0) {
        const price = await recivePrice(swapTo.token, swapFrom.token, toValue);
        setFromValue(Number(price).toFixed(9).toString());
      }
    }
    handleClose();
    setAlignment();
  };

  const changeTokenValue = async (value, type) => {
    if (type === "from") {
      setFromValue(value);
      if (value > 0) {
        const price = await recivePrice(swapFrom.token, swapTo.token, value);
        setToValue(Number(price).toFixed(9).toString());
      } else {
        setFromValue("");
        setToValue("");
      }
    } else {
      setToValue(value);
      if (value > 0) {
        const price = await recivePrice(swapTo.token, swapFrom.token, value);
        setFromValue(Number(price).toFixed(9).toString());
      } else {
        setFromValue("");
        setToValue("");
      }
    }
  };

  const recivePrice = async (fromToken, toToken, inputValue) => {
    const pancakeRouterContract = await getWeb3ContractObject(
      PancakeRouterABI,
      pancakeRouter,
      mainnetRPCURL
    );
    console.log("web3===", pancakeRouterContract);
    const web3 = await getWeb3Obj();
    const amoutInWei = web3.utils.toWei(inputValue);
    console.log("amoutInWei", amoutInWei);
    const youGetPrice = await pancakeRouterContract.methods
      .getAmountsOut(amoutInWei, [fromToken, toToken])
      .call();
    const finaldata = web3.utils.fromWei(youGetPrice[1], "ether");
    console.log("ReciveInether", finaldata);
    return finaldata;
  };

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.heading}>
        <Typography variant="h1"> Swap</Typography>
      </Box>
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          {/* <Grid item lg={6} sm={6} xs={12}>
            <Box className={classes.swapBox} mt={5}>
              <Box className="swapAnimate"></Box>
              <Grid container>
                <Grid item lg={6} sm={6} xs={6}>
                  <Box className={classes.paddingbox}>
                    <Typography>Max Supply</Typography>
                  </Box>
                </Grid>
                <Grid item lg={6} sm={6} xs={6}>
                  <Box className={classes.paddingbox}>
                    <Typography>0.00</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={6} sm={6} xs={6}>
                  <Box className={classes.paddingbox}>
                    <Typography>Circulation Supply</Typography>
                  </Box>
                </Grid>
                <Grid item lg={6} sm={6} xs={6}>
                  <Box className={classes.paddingbox}>
                    <Typography>0.00</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={6} sm={6} xs={6}>
                  <Box className={classes.paddingbox}>
                    <Typography>Total Burned</Typography>
                  </Box>
                </Grid>
                <Grid item lg={6} sm={6} xs={6}>
                  <Box className={classes.paddingbox}>
                    <Typography>0.00</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={6} sm={6} xs={6}>
                  <Box className={classes.paddingbox}>
                    <Typography>Market Cap</Typography>
                  </Box>
                </Grid>
                <Grid item lg={6} sm={6} xs={6}>
                  <Box className={classes.paddingbox} pb={2}>
                    <Typography>
                      {marketCap?.toString()?.substring(0, 6)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid> */}
          <Grid item lg={12} sm={12} xs={12}>
            <Box className={classes.swapBox} mt={5}>
              <Box className="swapAnimate"></Box>
              <label>You Pay</label>
              <Box className="paybox" mt={1}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClickOpen(swapTo.heading, "from")}
                    >
                      {" "}
                      <img src={swapFrom.image} alt="token" />{" "}
                      {swapFrom.heading}
                      <BiChevronDown />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <input
                      type="number"
                      min={0}
                      placeholder="0.0"
                      value={fromValue}
                      onChange={(e) => changeTokenValue(e.target.value, "from")}
                    />
                  </Grid>
                  <Grid item xs={12} align="right">
                    <Typography variant="body2">
                      ~${(parseFloat(swapFromValue) * fromValue).toFixed(4)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              {account && (
                <Box mt={1}>
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value="25"
                      aria-label="left aligned"
                      style={{ color: "#fff" }}
                    >
                      25%
                    </ToggleButton>
                    <ToggleButton
                      value="50"
                      aria-label="centered"
                      style={{ color: "#fff" }}
                    >
                      50%
                    </ToggleButton>
                    <ToggleButton
                      value="75"
                      aria-label="right aligned"
                      style={{ color: "#fff" }}
                    >
                      75%
                    </ToggleButton>
                    <ToggleButton
                      value="100"
                      aria-label="justified"
                      style={{ color: "#fff" }}
                    >
                      100%
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              )}

              <Box mt={2} className="transfer">
                <BiTransfer onClick={swapTokenData} />
              </Box>
              <label>You Receive</label>
              <Box className="paybox" mt={1} mb={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClickOpen(swapFrom.heading, "to")}
                    >
                      {" "}
                      <img src={swapTo.image} alt="token" /> {swapTo.heading}
                      <BiChevronDown />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <input
                      type="number"
                      min={0}
                      placeholder="0.0"
                      value={toValue}
                      onChange={(e) => changeTokenValue(e.target.value, "to")}
                    />
                  </Grid>
                  <Grid item xs={12} align="right">
                    <Typography variant="body2">
                      ~${(parseFloat(swapToValue) * toValue).toFixed(4)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <button
                className={classes.buttonright}
                variant="contained"
                size="large"
                style={{ color: "#fff !important" }}
                onClick={swapToken}
                disabled={fromValue <= 0 || chainId !== 56}
              >
                Swap
              </button>
              {/* <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <label>
                  Minimum Received{" "}
                  <Tooltip
                    title="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
                    placement="top"
                  >
                    <Button>
                      {" "}
                      <HiOutlineInformationCircle />
                    </Button>
                  </Tooltip>
                </label>
              </Grid>
              <Grid item xs={7} align="right">
                <small>
                  {" "}
                  <strong>0.98781</strong> BUSD
                </small>{" "}
                <br />
                <small>~$0.98781</small>
              </Grid>
              <Grid item xs={5}>
                <label>
                  Transaction Cost{" "}
                  <Tooltip
                    title="The shown cost is an approximate fee required to successfully submit the transaction."
                    placement="top"
                  >
                    <Button>
                      {" "}
                      <HiOutlineInformationCircle />
                    </Button>
                  </Tooltip>
                </label>
              </Grid>
              <Grid item xs={7} align="right">
                <small>
                  {" "}
                  <strong>0.98781</strong> BNB
                </small>{" "}
                <br />
                <small>~$0.98781</small>
              </Grid>
              <Grid item xs={5}>
                <label>
                  Gas Optimization{" "}
                  <Tooltip
                    title="Gas Optimization Mode helps minimize the transaction fees to save cost and enable more chances to maximize trading profit."
                    placement="top"
                  >
                    <Button>
                      {" "}
                      <HiOutlineInformationCircle />
                    </Button>
                  </Tooltip>
                </label>
              </Grid>
              <Grid item xs={7} align="right">
                <FormControlLabel
                  style={{ justifyContent: "end" }}
                  control={
                    <Switch
                      checked={checked}
                      color="Primary"
                      onChange={toggleChecked}
                    />
                  }
                />

              </Grid>
            </Grid>
          </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableScrollLock={true}
        fullWidth
        maxWidth="xs"
        style={{ padding: "8px" }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ color: "#fff", paddingBottom: "10px" }}
        >
          {"Select Token"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ padding: "0 22px" }}
            id="alert-dialog-description"
          >
            {/* <Box mb={2}>
              <FormControl
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
                fullWidth
              >
                <OutlinedInput
                  id="outlined-adornment-weight"
                  placeholder="Search Token"
                  endAdornment={
                    <InputAdornment position="end">
                      <BsSearch />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box> */}
            <span style={{ color: "#fff", paddingBottom: "10px" }}>
              Token(s)
            </span>
            <Box className={classes.tokenBox} mt={2}>
              {SolutionCard.map((data, i) => {
                return (
                  <Box
                    key={i}
                    className={classes.solutionBox}
                    data={data}
                    type="card"
                    index={i}
                    onClick={() => changeTokenName(data)}
                    style={
                      selectedToken === data.heading ? { display: "none" } : {}
                    }
                  >
                    <figure>
                      {" "}
                      <img src={data.image} alt="icons" />
                    </figure>
                    <Box>
                      <Typography variant="h4"> {data.heading}</Typography>
                      <Typography variant="body2">
                        {" "}
                        {data.discription}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
