import React, { useState } from "react";
import {
    Box,
    Container,
    makeStyles,
    Typography,
    Grid,
    Button
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import KingstakeCard from "../../components/KingstakeCard";
const useStyles = makeStyles((theme) => ({
    table:{
        backgroundColor: "#15172d",
    
        "& p": {
            margin: 0,
            display: "flex",
            alignItems: "center",
            color: "#fff",
            justifyContent: "center",
            marginTop: "10px",
            "& img": {
                marginRight: "5px",
            },
        },
        "& span":{
          backgroundColor: "#7e8181",
          color: "#fff",
          padding: "3px 10px",
          borderRadius: "50px",
          fontSize: "12px",
        },
      },
    mainBox: {
        borderRadius: "10px",
        minHeight: "250px",
        overflow: "hidden",
        position: "relative",
        background: "linear-gradient( 152.97deg, rgba(255, 255, 255, 0.08) 0%, rgba(232, 66, 76, 0.062) 100%)",
        transition: "0.5s",
        backdropFilter: "blur(42px)",
        padding: "30px 0px 30px",
        "&:hover": {
            transform: "translateY(-10px)",
        },
    },
    apyBox: {
        padding: "20px",
        "& h4": {
            fontSize: "15px",
            fontWeight: "700",
            color: "#fff",
        },
        "& h5": {
            fontSize: "15px",
            fontWeight: "700",
            color: "#ffffffcf",
        },
  
    },

    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },

    inputBox: {
        border: "none",
        height: "30px",
        width:"100%",
        background: "rgba(141, 137, 137, 0.2)",

    },
    DialogBox: {
        backgroundColor: "#090c16 !important",
        transition: "0.5s",
        position: "relative",
        borderRadius: "10px",
        backdropFilter: "blur(4px)",
     
        "& h4": {
            fontSize: "15px",
            fontWeight: "700",
            color: "#ffffffab",
        },
        "& h1": {
            fontSize: "30px",
            fontWeight: "bold",
            lineHeight: "76px",
            color: "rgb(246, 165, 45)",
        },
        "& h5": {
            fontSize: "15px",
            fontWeight: "700",
            color: "#fff",
        },
    },
    CoinBox: {
        padding: "0 20px",
        display: "flex",
        alignItems: "center",
        "& h5": {
            fontSize: "25px",
            fontWeight: "700",
            color: "#fff",
        },

    },
    cardimg: {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        marginRight: "15px",
    },
    bannerBox: {
        position: "relative",
        padding: " 10px 0px",
        zIndex: " 1",
    },
    textbox: {
        "& h1": {
            fontSize: "40px",
            fontWeight: "bold",
            lineHeight: "76px",
            color: "#FABE25",
            "@media (max-width: 767px)": {
                fontSize: "30px",
                }, 
        },
        "& p": {
            fontSize: "18px",
            color: "#fff",
        },
    },
    Buttonbox:{
        "@media (max-width: 767px)": {
            textAlign:"center",
            },     
    },
    rewardbutton:{
        "@media (max-width: 767px)": {
            minWidth: "120px",
            }, 

    },
}));

const Token = [
    {
        icon: "images/icon.png",
        name: "Fees",
        discription: "$300 USD in King Shiba token to deploy pre sale contract 0% Token tax to ensure no malicious token dumping like Pink Sale and Dx-Sale   3% Contribution token Fee on each pre sale",
    },
    {
        icon: "images/icons/1.svg",
        name: "King Swap",
        discription: "Instant access to claim and swap your tokens conveniently through our pre sale page",
    },
    {
        icon: "images/icons/1.svg",
        name: "Token distribution",
        discription: "Token distribution checker",
    },
    {
        icon: "images/icons/1.svg",
        name: "Community",
        discription: "Community vote on project safety",
    },

    {
        icon: "images/icon.png",
        name: "Fees",
        discription: "$300 USD in King Shiba token to deploy pre sale contract 0% Token tax to ensure no malicious token dumping like Pink Sale and Dx-Sale   3% Contribution token Fee on each pre sale",
    },
    {
        icon: "images/icons/1.svg",
        name: "King Swap",
        discription: "Instant access to claim and swap your tokens conveniently through our pre sale page",
    },
    {
        icon: "images/icons/1.svg",
        name: "Token distribution",
        discription: "Token distribution checker",
    },
    {
        icon: "images/icons/1.svg",
        name: "Community",
        discription: "Community vote on project safety",
    },

];

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#262831",
        },
    },
}))(TableRow);
export default function Dashboard(props) {
    var classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [tabview, setTabView] = useState("My");
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    return (
        <>
            <Box className={classes.bannerBox}> 
            <Box className={classes.modalBody}>
                            <TableContainer  className={classes.maintable}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableBody>
                                        <StyledTableRow >
                                        <StyledTableCell component="th" scope="row">
                                                <p style={{fontWeight:"bold", color:"#EFAB00"}}> Address</p>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                <p style={{fontWeight:"bold", color:"#EFAB00"}}> Trades</p>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                            <p style={{fontWeight:"bold", color:"#EFAB00"}}>Total Amount</p>
                                            </StyledTableCell>
                                    
                                        </StyledTableRow>
                                        <StyledTableRow >
                                        <StyledTableCell component="th" scope="row">
                                                <p> 0xab...e3d9</p>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                <p> 2</p>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                            <p> 1.3 T</p>
                                            </StyledTableCell>
                                     
                                        </StyledTableRow>
                                        <StyledTableRow >
                                        <StyledTableCell component="th" scope="row">
                                        <p> 0xab...e3d9</p>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                <p> 5</p>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                            <p> 1.3 T</p>
                                            </StyledTableCell>
                                      
                                        </StyledTableRow>
                                        <StyledTableRow >
                                        <StyledTableCell component="th" scope="row">
                                        <p> 0xab...e3d9</p>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                <p> 2</p>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                            <p> 1.3 T</p>
                                            </StyledTableCell>
                                          
                                        </StyledTableRow>
                                        <StyledTableRow >
                                        <StyledTableCell component="th" scope="row">
                                        <p> 0xab...e3d9</p>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                <p> 7</p>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                            <p> 1.3 T</p>
                                            </StyledTableCell>
                                       
                                        </StyledTableRow>
                                        <StyledTableRow >
                                        <StyledTableCell component="th" scope="row">
                                        <p> 0xab...e3d9</p>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                <p> 6</p>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                            <p> 1.3 T</p>
                                            </StyledTableCell>
                                     
                                        </StyledTableRow>
                                        <StyledTableRow >
                                        <StyledTableCell component="th" scope="row">
                                        <p> 0xab...e3d9</p>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                <p> 8</p>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                            <p> 1.3 T</p>
                                            </StyledTableCell>
                                          
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
            </Box>
        </>
    );
}
