import React from "react";
import { Box, Typography } from "@material-ui/core";
export default function TeamCard(props) {
  const { data } = props;
  return (
    <Box className="overlay">
      <img src={data.image} alt="" />
      <Box className="teamtext-main">
        <Typography variant="h1" align="left">
          {data.name}
        </Typography>
        <Typography variant="h4" align="left">
          {data.post}
        </Typography>
      </Box>
      <Box className="teamtext">
        <Typography variant="h1" align="left">
          {data.name}
        </Typography>
        <Typography variant="h4" align="left">
          {data.post}
        </Typography>
        <Typography variant="body2" align="left">
          {data.discription}
        </Typography>
      </Box>
    </Box>
  );
}
