import { HiOutlineBan } from "react-icons/hi";

export const NetworkContextName = "Binance test network";
export const ACTIVE_NETWORK = 56;

export const MetaReflectBinance = "0x9a0b57175d92F628B657D8F11113Bdfff6435432";

export const TokenBinance = "0x1e2d9f6a71857853f414bf799415b5e56c19def3";
export const TokenBinanceForOther =
  "0x44500d3a280a2f79af6914918c1ae09ef8d7510a";

export const farmConAddress = "0xA51C7B4F87d0959EC7C03BEee160e9E2135AefeC";

export const stakingConAddress = "0x96f95f72614CD8f5E7700e1a25d1b179C6840940";
/* ********** mainnet ************ */
export const stakingConAddress1 = "0xe46f80fcdde23d47abe2291b1f9263b50903aa1a";
export const stakingConAddress2 = "0x00de270378fad1e33e9b285fc180b446ec3645f1";
export const stakingConAddress3 = "0x00de270378fad1e33e9b285fc180b446ec3645f1";
export const stakingConAddress4 = "0x75a8c6Cc166a47B87aBB2e83Cd5B5a9696b26b40";

// export const stakingConAddress5 = "0xcD242bBFD1125E54028fc2A4Fd8A59795b037B8f";
export const deadAddress = "0x0000000000000000000000000000000000000000";
export const default_RPC_URL = "https://bsc-dataseed.binance.org/";
// export const default_RPC_URL =
//   "https://data-seed-prebsc-1-s1.binance.org:8545/";

export const getNetworkDetails = (chianId) => {
  switch (chianId.toString()) {
    case "42":
      return [
        {
          chainId: "0x2A",
          chainName: "Kovan Test Network",
          nativeCurrency: {
            name: "Kovan Test Network",
            symbol: "ETH",
            decimals: 18,
          },
          rpcUrls: [
            "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
          ],
          blockExplorerUrls: ["https://kovan.etherscan.io"],
        },
      ];
    case "97":
      return [
        {
          chainId: "0x61",
          chainName: "Smart Chain - Testnet",
          nativeCurrency: {
            name: "Smart Chain - Testnet",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
          blockExplorerUrls: ["https://testnet.bscscan.com/"],
        },
      ];
    case "80001":
      return [
        {
          chainId: "0x13881",
          chainName: "Mumbai testnet",
          nativeCurrency: {
            name: "Mumbai testnet",
            symbol: "MATIC",
            decimals: 18,
          },
          rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
          blockExplorerUrls: ["https://explorer-mumbai.maticvigil.com/"],
        },
      ];
    case "43113":
      return [
        {
          chainId: "0xA869",
          chainName: "Avalanche Network",
          nativeCurrency: {
            name: "Avalanche Network",
            symbol: "AVAX",
            decimals: 18,
          },
          rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
          blockExplorerUrls: ["https://testnet.snowtrace.io/"],
        },
      ];
    case "4002":
      return [
        {
          chainId: "0xfa2",
          chainName: "Fantom Opera",
          nativeCurrency: {
            name: "Fantom Opera",
            symbol: "FTM",
            decimals: 18,
          },
          rpcUrls: ["https://rpc.testnet.fantom.network/"],
          blockExplorerUrls: ["https://rpc.testnet.fantom.network/"],
        },
      ];
  }
};

export const networkList = [
  {
    name: "Ethereum",
    chainId: "42",
    description: "",
    symbol: "ETH",
  },
  {
    name: "Binance",
    chainId: "97",
    description: "",
    symbol: "BNB",
  },
  {
    name: "Matic",
    chainId: "80001",
    description: "",
    symbol: "MATIC",
  },
  {
    name: "Avalanche",
    chainId: "43113",
    description: "",
    symbol: "AVAX",
  },
  {
    name: "Fantom",
    chainId: "4002",
    description: "",
    symbol: "FTM",
  },
];

export const NetworkDetails = [
  {
    chainId: "0xA869",
    chainName: "Avalanche FUJI C-Chain",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://testnet.snowtrace.io/"],
  },
];

export const CategoryButtons = [
  {
    name: "Art",
    startIcon: <i className="icon-palette"></i>,
    img: "images/category/1.png",
  },
  {
    name: "Music",
    startIcon: <i className="icon-music"></i>,
    img: "images/category/2.png",
  },
  {
    name: "Games",
    startIcon: <i className="icon-games"></i>,
    img: "images/category/3.png",
  },
  {
    name: "NSFW",
    startIcon: <HiOutlineBan />,
    img: "images/category/4.png",
  },
  {
    name: "Memes",
    startIcon: <i className="icon-baby"></i>,
    img: "images/category/5.png",
  },
  {
    name: "Punks",
    startIcon: <i className="icon-punks"></i>,
    img: "images/category/6.png",
  },
  // {
  //   name: "DeFi",
  //   startIcon: <RiMoneyDollarCircleFill />,
  // },
  // {
  //   name: "Domains",
  //   startIcon: <i className="icon-web"></i>,
  // },
  // {
  //   name: "Metaverses",
  //   startIcon: <i className="icon-camera"></i>,
  // },
];
