import React, { useContext, useState, useEffect } from "react";
import axios from "axios";

import { TokenBinance } from "../../constants";
import { Box, Typography, makeStyles, Link } from "@material-ui/core";
import { FaDiscord, FaTwitter, FaTelegramPlane } from "react-icons/fa";
// import useStyles from "./styles";

// const Token = [
//   {
//     count: "#1",
//     token: "Shiba",
//     icon: "images/logo_2.png",
//   },
//   {
//     count: "#1",
//     token: "Shiba",
//     icon: "images/logo_2.png",
//   },
//   {
//     count: "#1",
//     token: "Shiba",
//     icon: "images/logo_2.png",
//   },
//   {
//     count: "#1",
//     token: "Shiba",
//     icon: "images/logo_2.png",
//   },
//   {
//     count: "#1",
//     token: "Shiba",
//     icon: "images/logo_2.png",
//   },
//   {
//     count: "#1",
//     token: "Shiba",
//     icon: "images/logo_2.png",
//   },
// ];
const useStyles = makeStyles((theme) => ({
  mainmenus: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    "& .coinPrice": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      whiteSpace: "nowrap",
      "& span": {
        background: "#EFAB00",
        fontSize: "12px",
        borderRadius: "3px",
        padding: "5px 15px",
        color: "#000",
        marginRight: "17px",
        color: "#FFFFFF",
        fontFamily: "Roboto",
      },
      "& h4": {
        fontSize: "14px",
        color: "#fff",
        marginRight: "15px",
        fontWeight: "600",
      },
    },
    "& .icons": {
      minWidth: "100px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginRight: "20px",
      "& a": {
        color: "#FFFFFF",
        fontSize: "22px",
      },
    },
  },
}));
export default function HeaderCoins(props) {
  var classes = useStyles();

  const [usdprice, setUsdprice] = useState([]);
  const myStakingList = async () => {
    axios({
      method: "GET",
      // url: "https://api.coingecko.com/api/v3/simple/price?ids=wbnb&vs_currencies=usd",   //0x1e2d9f6a71857853f414bf799415b5e56c19def3
      // url: `https://api.bscscan.com/api?module=token&action=tokeninfo&contractaddress=${TokenBinance}&apikey=MSQGYEXNG19DG6DF6R6KXNJFQ6S2GEFFPW`,
      url: "https://api.pancakeswap.info/api/v2/tokens/0x1e2d9f6a71857853f414bf799415b5e56c19def3",
    })
      .then(async (res) => {
        console.log("res", res);
        if (res.status === 200) {
          console.log("stakingList", res.data.price_BNB);
          setUsdprice(parseFloat(res.data.data.price_BNB).toFixed(4));
          window.sessionStorage.setItem(
            "usd",
            parseFloat(res.data.data.price_BNB).toFixed(4)
          );
          // setState(1);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    myStakingList();
  }, []);

  return (
    <Box className={classes.mainmenus}>
      <Box className="icons">
        <Link target="_blank" href="https://discord.com/invite/discord-testers">
          <FaDiscord />
        </Link>
        <Link target="_blank" href="https://twitter.com/">
          <FaTwitter />
        </Link>
        <Link target="_blank" href="https://web.telegram.org/z/">
          <FaTelegramPlane />
        </Link>
      </Box>
      <Box className="coinPrice">
        <span>MRF</span>
        <Typography variant="h4">$ {usdprice}</Typography>
      </Box>
    </Box>
  );
}
