import { Contract } from "@ethersproject/contracts";
import { toast } from "react-toastify";
import Web3 from "web3";
import { default_RPC_URL } from "../constants/";
import axios from "axios";
export function sortAddress(add) {
  if (add) {
    const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
    return sortAdd;
  } else {
    return add;
  }
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export const mainnetRPCURL = "https://bsc-dataseed.binance.org/";
export const pancakeRouter = "0x10ED43C718714eb63d5aA57B78B54704E256024E";
const BASEURL = `https://api.bscscan.com/`;
export const accountBNBBalance = async (senderAddress) => {
  const web3 = await getWeb3Obj();
  try {
    const response = await axios.get(
      `${BASEURL}api?module=account&action=balance&address=${senderAddress}&tag=latest&apikey=13QG8WB4HIGMBHVN8ZGQZRN896ZGXCY5QJ`
    );
    let balance = web3.utils.fromWei(response.data.result, "ether");
    console.log("price", Number(balance));
    return Number(balance);
  } catch (error) {
    alert(error);
  }
};

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export const getWeb3Obj = async (RPC_URL = default_RPC_URL) => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = await new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = async (abi, contractAddress, RPC_URL) => {
  const web3 = await getWeb3Obj(RPC_URL);
  const contract = await new web3.eth.Contract(abi, contractAddress);
  return contract;
};

export function copyTextById(id) {
  var copyText = document.getElementById(id);
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */
  navigator.clipboard.writeText(copyText.value);
  toast.info(`Copied ${copyText.value}`);
}

export const getChartData = async (cb) => {
  try {
    const url = "https://graphql.bitquery.io/";
    let query = `{
        ethereum(network: bsc) {
          dexTrades(
            options: {asc: "date.date"}
            date: {since: "2022-02-08", till: "2022-03-09T23:59:59"}
            baseCurrency: {is: "0xe9e7cea3dedca5984780bafc599bd69add087d56"}
          ) {
            date: date {
              date(format: "%Y-%m-%d")
            }
            trades: countBigInt
            amount: baseAmount
            baseCurrency {
              symbol
            }
            contracts: countBigInt(uniq: smart_contracts)
            currencies: countBigInt(uniq: quote_currency)
          }
        }
      }
        `;
    let opts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "BQYVLvwnTv9XIlcKAww2MBvxdftPjz42",
      },
      body: JSON.stringify({
        query,
      }),
    };
    fetch(url, opts)
      .then((res) => res.json())
      .then((result) => {
        console.log("count===>>>>>", result.data.ethereum.dexTrades);
        cb(result.data.ethereum.dexTrades);
      })
      .catch(console.error);
  } catch (error) {
    console.log("=================================>566256", error);
    return error;
  }
  // return res.data.data.ethereum.dexTrades;
};
