import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import { Link } from "react-router-dom";
import { IoMdRefresh } from "react-icons/io";
import { BiChevronDown } from "react-icons/bi";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { GoVerified } from "react-icons/go";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { HiOutlineExternalLink } from "react-icons/hi";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ConnectWallet from "../components/ConnectWalletPopUp/index";
import Tooltip from "@material-ui/core/Tooltip";
import { AiOutlineCalculator } from "react-icons/ai";
import RoiCalculator from "./RoiCalculator/RoiCalculator";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  mainBox: {
    borderRadius: "16px",
    minHeight: "250px",
    overflow: "hidden",
    position: "relative",
    transition: "0.5s",
    background: "#262831",
    boxShadow: "7px 7px 4px rgb(0 0 0 / 10%)",
    backdropFilter: "blur(42px)",
    "& label": {
      backgroundColor: "#E8424C",
      color: "#fff",
      padding: "2px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      fontSize: "9px",
      fontWeight: "800",
      width: "150px",
      fontFamily: "Roboto Slab",
      top: "14px",
      right: "-44px",
      transform: "rotate(40deg)",
    },
    "&:hover": {
      transform: "translateY(-10px)",
    },
  },
  nameBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 20px 0px",
    alignItems: "center",

    "& h4": {
      fontSize: "16px",
      fontWeight: "700",
      color: "#fff",
      marginBottom: "5px",
      textAlign: " right",
    },
    "& p": {
      fontSize: "12px",
      color: "#fff",
    },
    "& img": {
      width: "50px",
      // height: "40px",
      borderRadius: "50%",
    },
  },
  apyBox: {
    padding: "20px",
    "& h4": {
      fontSize: "12px",
      fontWeight: "700",
      color: "#fff",
    },
    "& span": {
      display: "flex",
      fontSize: "12px",
      fontWeight: "700",
      color: "#fff",
      alignItems: "center",

      "& img": {
        margin: "0 5px",
        width: "17px",
      },
    },
    "& input": {
      background: " rgba(141, 137, 137, 0.2)",
      color: "#ffffff54",
      maxWidth: "100px",
      border: "none",
      width: "100%",
      height: "35px",
      paddingLeft: "10px",
      borderRadius: "7px",
      "&:focus-visible": {
        outline: "none",
      },
    },
  },
  manualBtn: {
    border: "0.5px solid #FFFFFF",
    boxSizing: "border-box",
    borderRadius: "3px",
    fontSize: "14px",
    color: "#fff",
    "& svg": {
      color: "#FFFFFF",
    },
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  viewLink: {
    paddingTop: "10px",
    textDecoration: "underline",
    color: "#f6a52d",
    alignItems: "left",
    fontSize: "14px",
    "& link": {
      marginLeft: "2px",
    },
  },
  iconBox: {
    position: " absolute",
    left: "12px",
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    background: "#0a0e22cf",
    padding: "2px",
    top: "12px",
    border: "1px solid #fff",
  },
  customWidth: {
    maxWidth: 230,
    zIndex: "1000",
  },
}));
export default function TokenCard({
  type,
  account,
  user,
  StakingHarvest,
  CompoundEarned,
  CompoundDivident,
  StakingDeposit,
  pendingReward,
  pendingDividends,
  StakingWidthdraw,
  stakeName,
  earned,
  reflected,
  APY,
  stakingConAddress,
  buttonLoader,
  harvestLoader,
  totalStaked1,
  depositFee,
  widthdrawFee,
  harvestclaimDividend,
}) {
  const classes = useStyles();
  // console.log("buttonLoader", buttonLoader);

  const [open, setOpen] = useState(false);
  const [openId, setOpenId] = useState("");
  const [amount, setAmount] = useState("");
  const [roiCalculator, setRoiCalculator] = useState(false);
  // console.log(amount);
  const handleClickOpen = (id) => {
    setOpenId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (!buttonLoader) {
      setOpen(buttonLoader);
    }
  }, [buttonLoader]);

  const [openWalletConnect, setOpenWalletConnect] = React.useState(false);
  return (
    <Box className={classes.mainBox}>
      {openWalletConnect && (
        <ConnectWallet
          open={openWalletConnect}
          handleClose={() => setOpenWalletConnect(false)}
        />
      )}
      <Box style={{ textDecoration: "none" }}>
        <Box className={classes.mainBoxShade}></Box>

        <Box className={classes.nameBox}>
          <img src="images/icon.png" alt="" />
          <Box className={classes.iconBox}>
            <img src="images/gold.png" alt="" style={{ width: "18px" }} />
          </Box>

          <Box>
            <Typography variant="h4">
              {stakeName ? stakeName : "MemePad Old Pool5"}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.apyBox}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={5}>
              {/* This pool’s rewards aren’t compounded automatically, so we show APR */}
              <Tooltip
                classes={{ tooltip: classes.customWidth }}
                title="This pool’s rewards aren’t compounded automatically, so we show APY"
              >
                <Typography variant="h4">APY :</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={7} align="right">
              <Typography variant="body1" style={{ color: "#fff" }}>
                {Math.round(APY)}% <AiOutlineCalculator onClick={()=>setRoiCalculator(true)}/>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4"> {earned} EARNED </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body1"> {pendingReward} </Typography>
            </Grid>
            <Grid item xs={7} align="right">
              <Button onClick={() => CompoundEarned()}>Compound</Button>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body1"> 0 USD </Typography>
            </Grid>
            {type === "manual" && (
              <Grid item xs={7} align="right">
                <Button onClick={() => StakingHarvest()}>Harvest</Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h4"> {reflected} REFLECTED </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body1"> {pendingDividends} </Typography>
            </Grid>
            <Grid item xs={7} align="right">
              <Button onClick={() => CompoundDivident()}>Compound</Button>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body1"> 0 USD </Typography>
            </Grid>
            <Grid item xs={7} align="right">
              <Button onClick={() => harvestclaimDividend()}>Harvest</Button>
            </Grid>
            {/* {data.token === "meta" && (
              <Grid item xs={7} align="right">
                <Typography variant="body2" style={{ color: "#fff" }}>
                  Metareflects
                </Typography>
              </Grid>
            )} */}
            {/* {data.token === "busd" && (
              <Grid item xs={7} align="right">
                <Typography variant="body2" style={{ color: "#fff" }}>
                  BUSD
                </Typography>
              </Grid>
            )} */}
            <Grid item xs={7}>
              <Typography
                variant="h6"
                style={{ color: "#FFFFFF", fontSize: "13px" }}
              >
                Deposit Fee:
              </Typography>
            </Grid>
            <Grid item xs={5} align="right">
              {/* <input type="text" /> */}
              <Typography
                variant="body2"
                style={{ color: "#FFFFFF", fontSize: "13px" }}
              >
                {depositFee} %
              </Typography>
            </Grid>

            <Grid item xs={7}>
              <Typography
                variant="h6"
                style={{ color: "#FFFFFF", fontSize: "13px" }}
              >
                Widthdraw Fee:
              </Typography>
            </Grid>

            <Grid item xs={5} align="right">
              {/* <input type="text" /> */}
              <Typography
                variant="body2"
                style={{ color: "#FFFFFF", fontSize: "13px" }}
              >
                {widthdrawFee} %
              </Typography>
            </Grid>

            {/* <Grid item xs={12}>
              <Box className="sc-eCssSg iconbox" mt={1}>
                <div className="sc-cxFLnm icontext">
                  <GoVerified
                    width="20px"
                    className="icon"
                    style={{ fontFamily: "Roboto Slab, serif" }}
                  />
                  Verified
                </div>
                <div className="sc-cxFLnm eQhwtG sc-bTvRPi bkmCaf">45X</div>
              </Box>
            </Grid> */}
            {/* {data.token === "meta" && (
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  style={{ color: "#EFAB00", fontSize: "14px" }}
                >
                  Metareflects EARNED
                </Typography>
              </Grid>
            )} */}
            {/* {data.token === "busd" && (
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  style={{ color: "#EFAB00", fontSize: "14px" }}
                >
                  BUSD EARNED
                </Typography>
              </Grid>
            )} */}
            {/* {type === "inactive" ? ( */}
            <>
              {/* {account && (
                <>
                  <Grid item xs={5}>
                    <Typography variant="h4">
                      {pendingReward.toString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={7} align="right">
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ minWidth: "auto" }}
                        onClick={StakingHarvest}
                      >
                        Harvest
                        {harvestLoader && (
                          <Box>
                            <CircularProgress />
                          </Box>
                        )}
                      </Button>
                    </Box>
                  </Grid>
                </>
              )} */}
              {/* <Grid item xs={5}>
                <Typography variant="h4">0</Typography>
              </Grid>
              <Grid item xs={7} align="right">
                <Box>
                  <Button variant="contained" color="primary">
                    Stake
                  </Button>
                </Box>
              </Grid> */}
              <Grid
                item
                xs={12}
                style={{ borderBottom: "1px solid #8080808c" }}
              >
                <Box mb={2} mt={1}>
                  {account ? (
                    <Grid container spacing={1}>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleClickOpen("Deposit")}
                          //   onClick={StakingDeposit}
                          fullWidth
                        >
                          Deposit
                        </Button>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleClickOpen("Widthdraw")}
                          //   onClick={StakingDeposit}
                          fullWidth
                        >
                          Widthdraw
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => setOpenWalletConnect(true)}
                    >
                      Connect wallet
                    </Button>
                  )}
                </Box>
              </Grid>
            </>
            {/* <>
              <Grid
                item
                xs={12}
                style={{ borderBottom: "1px solid #8080808c" }}
              >
                <Box mb={2} mt={1}>
                  {account ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={StakingHarvest}
                      fullWidth
                    >
                      Claim
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={user?.connectWallet}
                    >
                      Connect Wallet
                    </Button>
                  )}
                </Box>
              </Grid>
            </> */}
            {/* // ) : (
               <>
                <Grid item xs={5}>
                   <Typography variant="h4">0</Typography>
                 </Grid>
                 <Grid item xs={7} align="right">
                   <Box>
                     <Button
                   variant="contained"
                       color="primary"
                       style={{ minWidth: "auto" }}
                     >
                       Harvest
                     </Button>
                   </Box>
                 </Grid>
                 {/* <Grid item xs={5}>
                   <Typography variant="h4">0</Typography>
                 </Grid>
             <Grid item xs={7} align="right">
                   <Box>
                     <Button variant="contained" color="primary">
                       Stake
                     </Button>
                   </Box>
                 </Grid> 
                 <Grid
                   item
                   xs={12}
                   style={{ borderBottom: "1px solid #8080808c" }}
                 >
                   <Box mb={2} mt={1}>
                     {account ? (
                       <Button variant="contained" color="primary" fullWidth>
                         Approve Contract
                       </Button>
                     ) : (
                       <Button
                         variant="contained"
                         color="primary"
                         fullWidth
                         onClick={user?.connectWallet}
                       >
                         Connect wallet
                       </Button>
                     )}
                   </Box>
                 </Grid>
               </>
             )} */}
            <Grid item xs={12} align="right">
              <div className={classes.root}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h4">Details</Typography>
                    {/* <Typography className={classes.heading}>Accordion 1</Typography> */}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={7} align="left">
                        <Typography variant="h4">Total Staked :</Typography>
                      </Grid>
                      <Grid item xs={5} align="right">
                        {/* <input type="text" /> */}
                        <span>
                          {" "}
                          <img src="images/bnb.png" alt="BNB" />
                          {totalStaked1} BNB
                        </span>
                      </Grid>
                      <Grid item xs={7} align="left">
                        <Typography variant="h4">
                          Metareflects Earned :
                        </Typography>
                      </Grid>
                      <Grid item xs={5} align="right">
                        {/* <input type="text" /> */}
                        <span>
                          {" "}
                          <img src="images/bnb.png" alt="BNB" /> 0.21 BNB
                        </span>
                      </Grid>
                      <Grid item xs={7} align="left">
                        <Typography variant="h4">BUSD Earned :</Typography>
                      </Grid>
                      <Grid item xs={5} align="right">
                        {/* <input type="text" /> */}
                        <span>
                          {" "}
                          <img src="images/bnb.png" alt="BNB" /> 0.13 BUSD
                        </span>
                      </Grid>
                      <Grid item xs={12} align="left">
                        <Box align="left">
                          {" "}
                          <Link
                            href={`https://testnet.bscscan.com/address/${stakingConAddress}`}
                            // href="https://bscscan.com/"
                            target={"_blank"}
                            className={classes.viewLink}
                          >
                            View Contract{" "}
                          </Link>
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span style={{ color: "gray" }}> {`Enter ${openId} Amount?`}</span>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
          <TextField
            variant="outlined"
            onChange={(e) => setAmount(e.target.value)}
            fullWidth
            type="number"
            style={{ width: "300px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          {openId !== "Widthdraw" ? (
            <Button
              onClick={() => StakingDeposit(amount)}
              color="primary"
              autoFocus
              disabled={buttonLoader}
            >
              Agree {buttonLoader && <CircularProgress />}
            </Button>
          ) : (
            <Button
              onClick={() => StakingWidthdraw(amount)}
              color="primary"
              autoFocus
              disabled={buttonLoader}
            >
              Agree {buttonLoader && <CircularProgress />}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={roiCalculator}
        onClose={()=>setRoiCalculator(false)}
      >
        <DialogContent>
         <RoiCalculator />
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </Box>
  );
}
