import React from "react";
import {
  Box,
  // Container,
  // Typography,
  makeStyles,
  // Button,
  // Grid,
} from "@material-ui/core";
import Chart from "react-apexcharts";

// import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  mainBox: {
    "& h1": {
      fontSize: "30px",
      fontWeight: "600",
      textDecoration: "underline",
      color: "#fff",
      marginBottom: "25px",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },
  },
  dexBox: {
    width: "100%",
    maxWidth: "400px",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
    "& span": {
      color: "#fff !important",
      fontSize: "15px !important",
    },
  },
}));

export default function BestSeller() {
  const classes = useStyles();
  const options = {
    chart: {
      height: "100%",
      width: "100%",
      type: "line",
      id: "basic-bar",
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },

      toolbar: {
        show: false,
      },
      labels: {
        color: "#fff",
        fontSize: "15px !important",
      },
    },
    legend: {
      position: "bottom",
    },

    labels: [
      "Uncommon - 19% Of Reflections",
      "Rare - 25% of Reflection",
      "Epic - 20% of Reflection",
    ],
  };

  const series = [19, 25, 20]; //our data
  return (
    <Box className={classes.mainBox}>
      <Box className={classes.dexBox}>
        <Chart options={options} series={series} type="donut" />
      </Box>
    </Box>
  );
}
