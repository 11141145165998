import React from "react";
import Banner from "./Banner";
import Features from "./Features";
import Rewards from "./Rewards";
// import Team from "./Team";
import Wallet from "./Wallet";

export default function Dashboard(props) {
  return (
    <>
      <Banner />
      <Wallet />
      <Features />
      <Rewards />
      {/* <Team /> */}
    </>
  );
}
