import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { makeStyles, Box, useTheme } from "@material-ui/core";
import { getChartData } from "../../utils";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#081b29",
    borderRadius: 14,
    padding: "14px 0 0px 0px",
  },
}));

const PairChartgraph = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [series, setSeries] = useState();
  const [seriesTime, setSeriesTime] = useState();
  let dataMap = [];
  let seriesTimeone = [];

  const TradingChartSearch = async () => {
    await getChartData((data1) => {
      dataMap = data1;
      dataMap.push(data1);
      const filter = data1?.map((item) => {
        return item?.amount;
      });
      for (let index = 0; index < data1.length; index++) {
        let element = data1[index];
        const time = moment(element?.date?.date).format("MMM Do");
        seriesTimeone.push(time);
      }

      setSeries([
        {
          name: "MRF",
          data: filter,
        },
      ]);
      setSeriesTime({
        chart: {
          background: "#081b29",
          stacked: false,
          toolbar: {
            show: false,
          },
          zoom: false,
        },
        colors: ["#0ccdfb"],
        dataLabels: {
          enabled: false,
        },
        grid: {
          borderColor: theme.palette.divider,
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
          labels: {
            colors: theme.palette.text.secondary,
          },
        },
        markers: {
          size: 4,
          strokeColors: ["#0ccdfb"],
          strokeWidth: 0,
          shape: "circle",
          radius: 2,
          hover: {
            size: undefined,
            sizeOffset: 2,
          },
        },
        stroke: {
          width: 2,
          curve: "smooth",
          lineCap: "butt",
          dashArray: [0, 0],
        },
        theme: {
          mode: theme.palette.type,
        },
        tooltip: {
          theme: theme.palette.type,
        },
        xaxis: {
          axisBorder: {
            color: theme.palette.divider,
          },
          axisTicks: {
            show: true,
            color: theme.palette.divider,
          },
          categories: seriesTimeone,
          labels: {
            style: {
              colors: theme.palette.text.secondary,
            },
          },
        },
        yaxis: [
          {
            axisBorder: {
              show: true,
              color: theme.palette.divider,
            },
            axisTicks: {
              show: true,
              color: theme.palette.divider,
            },
            labels: {
              style: {
                colors: theme.palette.text.secondary,
              },
            },
          },
        ],
      });
    });
  };

  useEffect(() => {
    TradingChartSearch();
  }, []);

  return (
    <Box className={classes.root} mb={3}>
      <Box mt={1}>
        {series && seriesTime && (
          <Chart
            type="area"
            height="220"
            options={seriesTime}
            series={series}
          />
        )}
      </Box>
    </Box>
  );
};

export default PairChartgraph;
