import React from "react";
import {
  Grid,
  Box,
  makeStyles,
  Container,
  Link,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { FaDiscord, FaTwitter, FaTelegramPlane } from "react-icons/fa";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& .logo": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .icons": {
      width: "100px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& a": {
        color: "#FFFFFF",
        fontSize: "23px",
      },
    },
    "& .text": {
      padding: "15px 0px",
      "& p": {
        color: "#ffffff",
        fontSize: "14px",
        textAlign: "center",
      },
      "& a": {
        padding: "0px 10px",
        color: "#ffffff",
      },
    },
  },
  basebox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    borderTop: "1px solid #3A3A3A",
    "& p": {
      color: "#FFFFFF",
      padding: "15px 0px",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.footerSection}>
        <Box className="logo">
          <Link to="/app/dashboard">
            <img
              src="images/logo.png"
              style={{ maxWidth: "130px" }}
              alt="logo"
            />
          </Link>
        </Box>
        <Box className="icons">
          <Link
            target="_blank"
            href="https://discord.com/invite/discord-testers"
          >
            <FaDiscord />
          </Link>
          <Link target="_blank" href="https://twitter.com/login">
            <FaTwitter />
          </Link>
          <a target="_blank" href="https://web.telegram.org/z/">
            <FaTelegramPlane />
          </a>
        </Box>
        <Box className="text">
          <Typography variant="body2">
            <RouterLink to="/app/terms-conditions">Terms and conditions</RouterLink>
            <RouterLink to="/app/privacy-policy">Privacy Policy</RouterLink>
            <RouterLink to="/app/documentation">Documentation</RouterLink>
          </Typography>
        </Box>
      </Box>
      <Box className={classes.basebox}>
        <Typography variant="body1">
          Copyright &copy; 2022 MetaReflect.{" "}
        </Typography>
      </Box>
    </>
  );
}
