import React from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Container,
} from "@material-ui/core";
import TeamCard from "../../components/TeamCard";
const useStyles = makeStyles((theme) => ({
  bannerBox: {
    position: "relative",
    padding: " 100px 0 70px",
    "& .headbox1": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop:"30px",
      "& h2": {
        color: "#EFAB00",
        padding: "0px 0px 24px",
      },
    },
    "& .headbox": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& h2": {
        color: "#EFAB00",
        padding: "0px 0px 24px",
      },
      "& p": {
        color: "#FFFFFF",
        "@media(max-width:767px)": {
          fontSize: "14px",
        },
      },
    },
    "& .textbox ": {
      // textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "30px 0px",
      "& h1": {
        fontSize: "40px",
        fontWeight: "bold",
        marginBottom: "10px",
        color: "#FFFFFF",
        [theme.breakpoints.down("xs")]: {
          fontSize: "30px",
        },
      },
      "& p": {
        color: "#fff",
        "@media(max-width:767px)": {
          fontSize: "14px",
        },
      },
    },
  },
}));

const teamData = [
  {
    image: "images/team1.png",
    name: "Vincent VU",
    post: "CEO & Co-Founder",
    discription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    image: "images/team2.png",
    name: "Tim Cook",
    post: "CEO",
    discription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    image: "images/team3.png",
    name: "Eric Lee",
    post: "Lead Engineer",
    discription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    image: "images/team4.png",
    name: "Tim Cook",
    post: "CEO",
    discription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
];

export default function Dashboard(props) {
  var classes = useStyles();
  return (
    <>
      <Box className={classes.bannerBox}>
        <Box className="headbox">
          <Typography variant="h2">About Metareflects</Typography>
  
          <Typography variant="body2">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s. Lorem Ipsum is simply dummy text of the
            printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s.Lorem Ipsum is
            simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy text ever since the
            1500s, when an unknown printer took a galley of type and scrambled
            it to make a type specimen book. Lorem Ipsum is simply dummy text of
            the printing and typesetting industry. Lorem Ipsum is simply dummy
            text of the printing and typesetting industry. Lorem Ipsum has been
            the industry's standard dummy text ever since the 1500s, when an
            unknown printer took a galley of type and scrambled it to make a
            type specimen book.{" "}
          </Typography>

          <Typography variant="body2">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s. Lorem Ipsum is simply dummy text of the
            printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s.Lorem Ipsum is
            simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy text ever since the
            1500s, when an unknown printer took a galley of type and scrambled
            it to make a type specimen book. Lorem Ipsum is simply dummy text of
            the printing and typesetting industry. Lorem Ipsum is simply dummy
            text of the printing and typesetting industry. Lorem Ipsum has been
            the industry's standard dummy text ever since the 1500s, when an
            unknown printer took a galley of type and scrambled it to make a
            type specimen book.{" "}
          </Typography>
        </Box>
        <Box>
          <Box className="headbox" mt={4}>
            <Typography variant="h2">Meet The Team</Typography> 
          </Box>
          <Box mt={2}>
              <Grid container spacing={3}>
                {teamData.map((data, i) => {
                  return (
                    <Grid item xs={12} sm={6} md={3} lg={3} key={i}>
                      <TeamCard data={data} index={i} />
                    </Grid>
                  );
                })}
              </Grid>
          
          </Box>
        </Box>
      </Box>
  
    </>
  );
}
