import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import { Link } from "react-router-dom";
import { IoMdRefresh } from "react-icons/io";
import { BiChevronDown } from "react-icons/bi";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { GoVerified } from "react-icons/go";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { HiOutlineExternalLink } from "react-icons/hi";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ConnectWallet from "../../components/ConnectWalletPopUp/index";
import ButtonCircularProgress from "../../components/ButtonCircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  compoundBtn: {
    backgroundColor: "#505255",
    padding: "0 10px",
    color: "#323232 !important",
    "&:hover": {
      backgroundColor: "#efab00",
    },
    "& span": {
      color: "#262626 !important",
      textTransform: "capitalize",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  mainBox: {
    borderRadius: "16px",
    minHeight: "250px",
    overflow: "hidden",
    position: "relative",
    transition: "0.5s",
    background: "#262831",
    boxShadow: "7px 7px 4px rgb(0 0 0 / 10%)",
    backdropFilter: "blur(42px)",
    "& label": {
      backgroundColor: "#E8424C",
      color: "#fff",
      padding: "2px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      fontSize: "9px",
      fontWeight: "800",
      width: "150px",
      fontFamily: "Roboto Slab",
      top: "14px",
      right: "-44px",
      transform: "rotate(40deg)",
    },
    "&:hover": {
      transform: "translateY(-10px)",
    },
  },
  nameBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 20px 0px",
    alignItems: "center",

    "& h4": {
      fontSize: "16px",
      fontWeight: "700",
      color: "#fff",
      marginBottom: "5px",
      textAlign: " right",
    },
    "& p": {
      fontSize: "12px",
      color: "#fff",
    },
    "& img": {
      width: "50px",
      // height: "40px",
      borderRadius: "50%",
    },
  },
  modalContant: {
    padding: "20px",
  },
  apyBox: {
    padding: "20px",
    "& h4": {
      fontSize: "12px",
      fontWeight: "700",
      color: "#fff",
    },
    "& span": {
      display: "flex",
      fontSize: "12px",
      fontWeight: "700",
      color: "#fff",
      alignItems: "center",

      "& img": {
        margin: "0 5px",
        width: "17px",
      },
    },
    "& input": {
      background: " rgba(141, 137, 137, 0.2)",
      color: "#ffffff54",
      maxWidth: "100px",
      border: "none",
      width: "100%",
      height: "35px",
      paddingLeft: "10px",
      borderRadius: "7px",
      "&:focus-visible": {
        outline: "none",
      },
    },
  },
  manualBtn: {
    border: "0.5px solid #FFFFFF",
    boxSizing: "border-box",
    borderRadius: "3px",
    fontSize: "14px",
    color: "#fff",
    "& svg": {
      color: "#FFFFFF",
    },
  },
  // root: {
  //   width: "100%",
  // },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  viewLink: {
    paddingTop: "10px",
    textDecoration: "underline",
    color: "#f6a52d",
    alignItems: "left",
    fontSize: "14px",
    "& link": {
      marginLeft: "2px",
    },
  },
  iconBox: {
    position: " absolute",
    left: "12px",
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    background: "#0a0e22cf",
    padding: "2px",
    top: "12px",
    border: "1px solid #fff",
  },
}));
export default function FormsCards({
  updateWalletA,
  updateWalletALoader,
  harvest1,
  stakingConAddress,
  stakeName,
  account,
  getOwner,
  user,
  type,
  data,
  rewardPerBlock,
  depositFee,
  widthdrawFee,
  reflected,
  earned,
  totalStaked,
  UpdateRewardPerBlock,
  buttonLoader,
  harvestLoader,
  updatePoolLimit,
  updateLockup,
}) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [openId, setOpenId] = useState("");
  const [amount, setAmount] = useState("");
  const [openWalletConnect, setOpenWalletConnect] = useState(false);
  const [updateAddress, setupdateAddress] = useState(false);
  const [poollimit, setPoolLimit] = useState(false);
  const [updateLockupOpen, setupdateLockupOpen] = useState(false);
  const [lockUpValues, setLockUpValues] = useState({
    duration: "",
    widthdrawFee: "",
    depositFee: "",
    rewardperBlock: "",
  });
  // console.log(amount);
  const handleClickOpen = (id) => {
    setOpenId(id);
    setOpen(true);
  };
  // console.log("lockUpValues---------------", lockUpValues);
  const handleSubmit = async (event) => {
    setLockUpValues({
      ...lockUpValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setAmount("");
    setOpen(false);
    setupdateAddress(false);
    setPoolLimit(false);
    setupdateLockupOpen(false);
    setLockUpValues({
      duration: "",
      widthdrawFee: "",
      depositFee: "",
    });
  };
  React.useEffect(() => {
    if (!harvestLoader) {
      handleClose();
    }
  }, [harvestLoader]);
  React.useEffect(() => {
    if (!buttonLoader) {
      handleClose();
    }
  }, [buttonLoader]);
  React.useEffect(() => {
    if (!harvestLoader) {
      handleClose();
    }
  }, [harvestLoader]);
  React.useEffect(() => {
    if (!updateWalletALoader) {
      handleClose();
    }
  }, [updateWalletALoader]);

  function convertToInternationalCurrencySystem(labelValue) {
    // twelve Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e12
      ? (Math.abs(Number(labelValue)) / 1.0e12).toFixed(2) + "T"
      : // Nine Zeroes for Billions
      Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
      : Math.abs(Number(labelValue));
  }

  return (
    <Box className={classes.mainBox}>
      {openWalletConnect && (
        <ConnectWallet
          open={openWalletConnect}
          handleClose={() => setOpenWalletConnect(false)}
        />
      )}
      <Box style={{ textDecoration: "none" }}>
        <Box className={classes.mainBoxShade}></Box>

        <Box className={classes.nameBox}>
          <img src="images/icon.png" alt="" />
          <Box className={classes.iconBox}>
            <img src="images/gold.png" alt="" style={{ width: "18px" }} />
          </Box>

          {/* <Box>
            <Typography variant="h4">{stakeName}</Typography>
          </Box> */}
          <Box>
            <Typography variant="h4">
              Earn {stakeName ? stakeName : "MetaReflect"}
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: "12px",
                color: "#b5afaf",
                fontWeight: 600,
                whiteSpace: "nowrap",
              }}
            >
              Stake {stakeName}
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: "12px",
                color: "#b5afaf",
                fontWeight: 600,
                whiteSpace: "nowrap",
              }}
            >
              Reflection {reflected}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.apyBox}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={5}>
              <Typography variant="h4">APY :</Typography>
            </Grid>
            <Grid item xs={7} align="right">
              <Typography variant="body1" style={{ color: "#fff" }}>
                {rewardPerBlock ? parseFloat(rewardPerBlock)?.toFixed(8) : "0"}%
              </Typography>
            </Grid>
            {/* <Grid item xs={5}>
              <Typography variant="h4">Earn :</Typography>
            </Grid>{" "}
            <Grid item xs={7} align="right">
              <Typography variant="body2" style={{ color: "#fff" }}>
                BUSD
              </Typography>
            </Grid> */}

            {account && (
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h4">
                    {" "}
                    <small> {earned}</small> EARNED{" "}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "16px",
                      color: "#b5afaf",
                      fontWeight: 600,
                    }}
                  >
                    {" "}
                    {/* {Number(pendingReward) === 0
                        ? 0.0
                        : parseFloat(pendingReward).toFixed(8)} */}
                  </Typography>
                </Grid>
                <Grid item xs={7} align="right">
                  <Button
                    className={classes.compoundBtn}
                    // onClick={() => updateWalletA()}
                    onClick={() => setupdateAddress(true)}
                    // disabled={updateWalletALoader}
                  >
                    Update Address
                    {/* {updateWalletALoader && <ButtonCircularProgress />} */}
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">{/* 0 USD */}</Typography>
                </Grid>
                {type === "Lockup" ? (
                  <Grid item xs={7} align="right">
                    <Box style={{ height: "25px" }}>
                      <Button
                        className={classes.compoundBtn}
                        onClick={() => setupdateLockupOpen(true)}
                        // disabled={type === "Lockup" ? false : true}
                      >
                        Update Lockup
                      </Button>
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12} align="right">
                    <Box style={{ height: "25px" }}>
                      <Button
                        className={classes.compoundBtn}
                        // onClick={() => setupdateLockupOpen(true)}
                        // disabled={type === "Lockup" ? false : true}
                        onClick={() => setOpen(true)}
                      >
                        Update Reward Per Block
                      </Button>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography variant="h4">
                    {" "}
                    <small> {reflected}</small> REFLECTED{" "}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "16px",
                      color: "#b5afaf",
                      fontWeight: 600,
                    }}
                  >
                    {" "}
                    {/* {Number(pendingDividends) === 0
                        ? 0.0
                        : parseFloat(pendingDividends).toFixed(8)} */}
                  </Typography>
                </Grid>
                <Grid item xs={7} align="right">
                  <Button
                    className={classes.compoundBtn}
                    onClick={() => setPoolLimit(true)}
                    // disabled={Number(pendingDividends) === 0 ? true : false}//updatePoolLimit
                  >
                    Update Pool Limit
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">{/* 0 USD */}</Typography>
                </Grid>
                <Grid item xs={7} align="right">
                  <Button
                    className={classes.compoundBtn}
                    onClick={() => harvest1()}
                    disabled={harvestLoader}
                  >
                    Harvest
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid item xs={7}>
              <Typography
                variant="body1`"
                style={{ color: "#FFFFFF", fontSize: "14px" }}
              >
                Deposit Fee:
              </Typography>
            </Grid>
            <Grid item xs={5} align="right">
              {/* <input type="text" /> */}
              <Typography
                variant="body2"
                style={{ color: "#FFFFFF", fontSize: "14px" }}
              >
                {depositFee}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                variant="body1"
                style={{ color: "#FFFFFF", fontSize: "14px" }}
              >
                Widthdraw Fee:
              </Typography>
            </Grid>
            <Grid item xs={5} align="right">
              {/* <input type="text" /> */}
              <Typography
                variant="body2"
                style={{ color: "#FFFFFF", fontSize: "14px" }}
              >
                {widthdrawFee}
              </Typography>
            </Grid>
            {/* <Grid item xs={12}>
              <Box className="sc-eCssSg iconbox" mt={1}>
                <div className="sc-cxFLnm icontext">
                  <GoVerified
                    width="20px"
                    className="icon"
                    style={{ fontFamily: "Roboto Slab, serif" }}
                  />
                  Verified
                </div>
                <div className="sc-cxFLnm eQhwtG sc-bTvRPi bkmCaf">45X</div>
              </Box>
            </Grid> */}
            {account ? (
              <>
                {/* <Grid item xs={6} lg={6}>
                  <Typography variant="h4">Reward Per block</Typography>
                </Grid>

                <Grid
                  item
                  xs={6}
                  lg={6}
                  // style={{ borderBottom: "1px solid #8080808c" }}
                >
                  <Box mb={2} mt={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      // style={{ minWidth: "auto" }}
                      // onClick={() => UpdateRewardPerBlock()}
                      onClick={() => setOpen(true)}
                    >
                      Update
                    </Button>
                  </Box>
                </Grid> */}
              </>
            ) : (
              <Grid item xs={12} lg={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  // onClick={user?.connectWallet}
                  onClick={() => setOpenWalletConnect(true)}
                >
                  Connect wallet
                </Button>
              </Grid>
            )}
            <Grid item xs={12} align="right">
              <div className={classes.root}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h4">Details</Typography>
                    {/* <Typography className={classes.heading}>Accordion 1</Typography> */}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={7} align="left">
                        <Typography variant="h4">Total Staked :</Typography>
                      </Grid>
                      <Grid item xs={5} align="right">
                        {/* <input type="text" /> */}
                        <span>
                          {" "}
                          <img src="images/bnb.png" alt="BNB" />{" "}
                          {convertToInternationalCurrencySystem(totalStaked)}{" "}
                          {stakeName}
                        </span>
                      </Grid>
                      {/* <Grid item xs={7} align="left">
                        <Typography variant="h4">
                          Metareflects Earned :
                        </Typography>
                      </Grid>
                      <Grid item xs={5} align="right">
                        <span>
                          {" "}
                          <img src="images/bnb.png" alt="BNB" /> 0.21 BNB
                        </span>
                      </Grid>
                      <Grid item xs={7} align="left">
                        <Typography variant="h4">BUSD Earned :</Typography>
                      </Grid>
                      <Grid item xs={5} align="right">
                        <span>
                          {" "}
                          <img src="images/bnb.png" alt="BNB" /> 0.13 BUSD
                        </span>
                      </Grid> */}
                      <Grid item xs={12} align="left">
                        <Box align="left">
                          {" "}
                          <Link
                            // stakingConAddress
                            href={`https://testnet.bscscan.com/address/${stakingConAddress}`}
                            target={"_blank"}
                            className={classes.viewLink}
                          >
                            View Contract{" "}
                          </Link>
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
          </Grid>
        </Box>

        <Dialog
          open={poollimit}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            <span style={{ color: "gray" }}> {`Update Pool Limit`}</span>
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
            <Box className={classes.modalContant}>
              <Typography
                variant="h6"
                align="right"
                style={{ color: "#efab00", fontSize: " 16px" }}
              ></Typography>
              <TextField
                disabled={harvestLoader}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                variant="outlined"
                onChange={(e) => setAmount(e.target.value)}
                fullWidth
                value={amount}
                placeholder="0"
                type="text"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              style={{ color: "#ccc" }}
              disabled={harvestLoader}
            >
              Cancel
            </Button>
            {/* {openId !== "Widthdraw" ? ( */}
            <Button
              onClick={() => updatePoolLimit(amount)}
              autoFocus
              style={{ color: "#efab00" }}
              disabled={harvestLoader}
            >
              {/* Confirm {buttonLoader && <CircularProgress />} */}
              <span
                style={{
                  paddingRight: "13px",
                }}
              >
                Confirm
              </span>

              {harvestLoader && (
                <CircularProgress
                  style={{
                    width: "32px",
                    height: "32px",
                    color: "#efab00",
                  }}
                />
              )}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={updateAddress}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            <span style={{ color: "gray" }}> {`Enter New WalletA`}</span>
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
            <Box className={classes.modalContant}>
              <Typography
                variant="h6"
                align="right"
                style={{ color: "#efab00", fontSize: " 16px" }}
              ></Typography>
              <TextField
                disabled={updateWalletALoader}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                variant="outlined"
                onChange={(e) => setAmount(e.target.value)}
                fullWidth
                value={amount}
                placeholder="Wallet Address"
                type="text"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              style={{ color: "#ccc" }}
              disabled={updateWalletALoader}
            >
              Cancel
            </Button>
            {/* {openId !== "Widthdraw" ? ( */}
            <Button
              onClick={() => updateWalletA(amount)}
              autoFocus
              style={{ color: "#efab00" }}
              disabled={updateWalletALoader}
            >
              {/* Confirm {buttonLoader && <CircularProgress />} */}
              <span
                style={{
                  paddingRight: "13px",
                }}
              >
                Confirm
              </span>

              {updateWalletALoader && (
                <CircularProgress
                  style={{
                    width: "32px",
                    height: "32px",
                    color: "#efab00",
                  }}
                />
              )}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={updateLockupOpen}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            <span style={{ color: "gray" }}> {`Update Lockup `}</span>
          </DialogTitle>
          <DialogContent>
            <Box className={classes.modalContant}>
              <Typography
                variant="h6"
                align="start"
                style={{ color: "#efab00", fontSize: " 16px" }}
              >
                Lockup Duration
              </Typography>
              <TextField
                disabled={harvestLoader}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                variant="outlined"
                name="duration"
                onChange={handleSubmit}
                fullWidth
                value={lockUpValues.duration}
                placeholder="0"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        disabled={true}
                        style={{
                          backgroundColor: "#676666",
                          padding: 0,
                          color: "#d6d1d1",
                        }}
                      >
                        DAYS
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box className={classes.modalContant}>
              <Typography
                variant="h6"
                align="start"
                style={{ color: "#efab00", fontSize: " 16px" }}
              >
                Deposit Fee
              </Typography>
              <TextField
                disabled={harvestLoader}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                variant="outlined"
                name="depositFee"
                onChange={handleSubmit}
                fullWidth
                value={lockUpValues.depositFee}
                placeholder="0"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        disabled={true}
                        style={{
                          backgroundColor: "#676666",
                          padding: 0,
                          color: "#d6d1d1",
                        }}
                      >
                        %
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box className={classes.modalContant}>
              <Typography
                variant="h6"
                align="start"
                style={{ color: "#efab00", fontSize: " 16px" }}
              >
                Widthdraw Fee
              </Typography>
              <TextField
                disabled={harvestLoader}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                name="widthdrawFee"
                variant="outlined"
                onChange={handleSubmit}
                fullWidth
                value={lockUpValues.widthdrawFee}
                placeholder="0"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        disabled={true}
                        style={{
                          backgroundColor: "#676666",
                          padding: 0,
                          color: "#d6d1d1",
                        }}
                      >
                        %
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box className={classes.modalContant}>
              <Typography
                variant="h6"
                align="start"
                style={{ color: "#efab00", fontSize: " 16px" }}
              >
                Reward Per Block
              </Typography>
              <TextField
                disabled={harvestLoader}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                name="rewardperBlock"
                variant="outlined"
                onChange={handleSubmit}
                fullWidth
                value={lockUpValues.rewardperBlock}
                placeholder="0"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        disabled={true}
                        style={{
                          backgroundColor: "#676666",
                          padding: 0,
                          color: "#d6d1d1",
                        }}
                      >
                        {/* DAYS */}
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              style={{ color: "#ccc" }}
              disabled={harvestLoader}
            >
              Cancel
            </Button>
            {/* {openId !== "Widthdraw" ? ( */}
            <Button
              onClick={() => updateLockup(lockUpValues)}
              autoFocus
              style={{ color: "#efab00" }}
              disabled={harvestLoader}
            >
              <span
                style={{
                  paddingRight: "13px",
                }}
              >
                Confirm
              </span>

              {harvestLoader && (
                <CircularProgress
                  style={{
                    width: "32px",
                    height: "32px",
                    color: "#efab00",
                  }}
                />
              )}
            </Button>
            {/* ) : (
              <Button
                onClick={() => StakingWidthdraw(amount)}
                color="primary"
                style={{ color: "#efab00" }}
                autoFocus
                disabled={buttonLoader}
              >
                <span
                  style={{
                    paddingRight: "13px",
                  }}
                >
                  Confirm
                </span>

                {buttonLoader && (
                  <CircularProgress
                    style={{
                      width: "32px",
                      height: "32px",
                      color: "#efab00",
                    }}
                  />
                )}
              </Button>
            )} */}
          </DialogActions>
        </Dialog>

        <Dialog
          open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            <span style={{ color: "gray" }}> {`Enter Reward Per Block ?`}</span>
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
            <Box className={classes.modalContant}>
              <Typography
                variant="h6"
                align="right"
                style={{ color: "#efab00", fontSize: " 16px" }}
              ></Typography>
              <TextField
                disabled={buttonLoader}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                variant="outlined"
                onChange={(e) => setAmount(e.target.value)}
                fullWidth
                value={amount}
                placeholder="0"
                type="number"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              style={{ color: "#ccc" }}
              disabled={buttonLoader}
            >
              Cancel
            </Button>
            {/* {openId !== "Widthdraw" ? ( */}
            <Button
              onClick={() => UpdateRewardPerBlock(amount)}
              autoFocus
              style={{ color: "#efab00" }}
              disabled={buttonLoader}
            >
              {/* Confirm {buttonLoader && <CircularProgress />} */}
              <span
                style={{
                  paddingRight: "13px",
                }}
              >
                Confirm
              </span>

              {buttonLoader && (
                <CircularProgress
                  style={{
                    width: "32px",
                    height: "32px",
                    color: "#efab00",
                  }}
                />
              )}
            </Button>
            {/* ) : (
              <Button
                onClick={() => StakingWidthdraw(amount)}
                color="primary"
                style={{ color: "#efab00" }}
                autoFocus
                disabled={buttonLoader}
              >
                <span
                  style={{
                    paddingRight: "13px",
                  }}
                >
                  Confirm
                </span>

                {buttonLoader && (
                  <CircularProgress
                    style={{
                      width: "32px",
                      height: "32px",
                      color: "#efab00",
                    }}
                  />
                )}
              </Button>
            )} */}
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
