import React, { useContext, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Box,
  Hidden,
  Button,
  Select,
  Menu,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Menu as MenuIcon } from "@material-ui/icons";
import axios from "axios";
import classNames from "classnames";
// styles
import useStyles from "./styles";
// components
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { UserContext } from "../../context/User";
import MenuLink from "./MenuLink";
import HeaderCoins from "./HeaderCoins";
import { useWeb3React } from "@web3-react/core";
import LogoutButton from "../LogoutButton";
import Web3 from "web3";
import { default_RPC_URL } from "../../constants/index";
import ConnectWallet from "../../components/ConnectWalletPopUp";

export default function Header(props) {
  var classes = useStyles();
  const { account, chainId } = useWeb3React();
  const [balanceValue, setBalanceValue] = React.useState("");
  const user = useContext(UserContext);

  const httpProvider = new Web3.providers.HttpProvider(default_RPC_URL);
  const web3 = new Web3(httpProvider);

  const GetBalance = async () => {
    try {
      const value = await web3.eth.getBalance(account);
      setBalanceValue(web3.utils.fromWei(value));

      // console.log("getBalance", web3.utils.fromWei(value));
    } catch (error) {}
  };
  const newBalance = async () => {
    // const res =
    //   await get("https://api.bscscan.com/api?module=account&action=balance&address=0xA0Ae9ECb5f0E58E450A4273d333696Da6A670C17&apikey=VIQZASWPV5HTCR28EZ73SK3AF9T461YFMH";
    //   ).then(console.log("=====++++=+==+=+==+=++=+=++=+==", res));
    await axios
      .get(
        `https://api.bscscan.com/api?module=account&action=balance&address=${account}&apikey=${"VIQZASWPV5HTCR28EZ73SK3AF9T461YFMH"}`,
        {}
      )
      .then(async (res) => {
        // console.log("=====++++=+==+=+==+=++=+=++=+==", res);
        if (res.status === 200) {
          // setBalanceValue(res.data.result);
        }
      });
  };
  useEffect(() => {
    newBalance();
    GetBalance();
  }, [account]);
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  // console.log("account", balanceValue);

  const [openWalletConnect, setOpenWalletConnect] = React.useState(false);
  return (
    <>
      <AppBar
        position="fixed"
        className={layoutState.isSidebarOpened ? `appBar` : `appBarCloses`}
        elevation={0}
        style={{ backgroundColor: "#15161C !important" }}
      >
        <Grid container spacing={2} alignItems="center">
          <Hidden mdUp>
            <Grid item xs={5} sm={4} md={12}>
              <Toolbar className={classes.toolbar}>
                <Hidden mdUp>
                  <IconButton
                    color="inherit"
                    onClick={() => toggleSidebar(layoutDispatch)}
                    className={classNames(
                      classes.headerMenuButtonSandwich,
                      classes.headerMenuButtonCollapse
                    )}
                  >
                    {layoutState.isSidebarOpened ? (
                      <MenuIcon
                        classes={{
                          root: classNames(
                            classes.headerIcon,
                            classes.headerIconCollapse
                          ),
                        }}
                      />
                    ) : (
                      <MenuIcon
                        classes={{
                          root: classNames(
                            classes.headerIcon,
                            classes.headerIconCollapse
                          ),
                        }}
                      />
                    )}
                  </IconButton>
                </Hidden>
                <Hidden smUp>
                  <Link to="/">
                    {" "}
                    <img
                      src="../images/fav.ico"
                      className="logoimg"
                      alt="logo"
                    />
                  </Link>
                </Hidden>
                <div className={classes.grow} />
              </Toolbar>
            </Grid>
          </Hidden>
          <Grid
            item
            xs={7}
            sm={8}
            md={12}
            align="right"
            className={classes.sidbar}
          >
            <Box className={classes.sidbar2}>
              <Hidden smDown>
                <HeaderCoins />
              </Hidden>
              {account ? (
                <>
                  <Hidden xsDown>
                    <Button
                      className={classes.buttonright}
                      variant="contained"
                      size="small"
                      style={{ color: "#EFAB00 !important" }}
                      href="https://pancakeswap.finance/swap?outputCurrency=0xEC8A7738b3B2C014d572aB4Db4A091490b4f325A"
                      target="_blanck"
                    >
                      BUY
                    </Button>
                  </Hidden>
                  {/* <Button
                  className={classes.buttonright}
                  variant="contained"
                  size="large"
                  style={{ color: "#EFAB00 !important" }}
                  // href="https://pancakeswap.finance/add/BNB/0x1e2D9f6a71857853f414Bf799415b5E56c19DeF3"
                  // target="_blanck"
                >
                  {account !== null
                    ? `${account?.slice(0, 6)}....${account?.slice(-4)}`
                    : ""}
                </Button> */}
                  <Box style={{ paddingLeft: "15px" }}>
                    <LogoutButton
                      account={account}
                      balanceValue={balanceValue}
                    />
                  </Box>
                </>
              ) : (
                <Button
                  className={classes.buttonright}
                  variant="contained"
                  size="large"
                  // onClick={user.connectWallet}

                  onClick={() => setOpenWalletConnect(true)}
                  style={{ color: "#EFAB00 !important" }}
                >
                  Connect
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </AppBar>

      {openWalletConnect && (
        <ConnectWallet
          open={openWalletConnect}
          handleClose={() => setOpenWalletConnect(false)}
        />
      )}
    </>
  );
}
