import React from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Container,
} from "@material-ui/core";
import FeatureCard from "../../components/FeatureCard";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "50px 0px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      padding: "10px 0px",
    },
    "& .mainbox": {
      marginTop: "-60px",
      "& h2": {
        color: "#FFFFFF",
        marginBottom: "20px",

        [theme.breakpoints.down("xs")]: {
          height: "auto",
          fontSize: "35px",
        },
      },
      "& p": {
        color: "#FFF9F9",
        "@media(max-width:767px)": {
          fontSize: "14px",
        },
      },
    },
  },
  imgtextbox: {
    width: "600px",
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
}));
const Token = [
  {
    image: "images/logo.png",
    name: "Rewards",
    discription:
      "Easily view your rewards and holdings! Passive income at your fingertips!",
  },
  {
    image: "images/logo.png",
    name: "Stake",
    discription:
      "Stake a portion or all of your MRF and earn more rewards! Staking periods vary as do APY so choose what fits you best and HOLD!",
  },
  {
    image: "images/logo.png",
    name: "Swap",
    discription:
      "Easily swap and trade your tokens with our integrated DEX! Connect your wallet and go! Endless projects all at your fingertips!",
  },

  // {
  //   image: "images/logo.png",
  //   name: "Features-4",
  //   discription:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor habitant donec sagittis viverra euismod.",
  // },
];

function Features() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth="md">
          <Box className="imgbox">
            <img
              src="images/Features.png"
              alt="Feature "
              className={classes.imgtextbox}
            />
          </Box>
          <Box style={{ paddingBottom: "15px" }} className="mainbox">
            <Typography variant="h2">Features</Typography>
            <Typography variant="body2">
              Below are a few of our features that can be accessed through this
              dashboard! Check out your rewards, staking, swapping and more to
              come! This dashboard will be the home for a majority of our
              ecosystem so get comfortable!
            </Typography>
          </Box>
        </Container>
        <Box mt={9}>
          <Grid container spacing={3}>
            {Token.map((data, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  key={i}
                  className="walletSet"
                >
                  <FeatureCard data={data} type="card" index={i} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Features;
