import { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import {
  Box,
  Flex,
  Text,
  Input,
  CheckmarkIcon,
  PencilIcon,
  IconButton,
} from "@pancakeswap/uikit";
import {
  CalculatorMode,
  RoiCalculatorReducerState,
} from "./useRoiCalculatorReducer";

const MILLION = 1000000;
const TRILLION = 1000000000000;

const RoiCardWrapper = styled(Box)`
  background: linear-gradient(180deg, #53dee9, #efab00);
  padding: 1px;
  width: 100%;
  border-radius: ${({ theme }) => theme.radii.default};
`;

const RoiCardInner = styled(Box)`
  height: 120px;
  padding: 24px;
  border-radius: ${({ theme }) => theme.radii.default};
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
`;

const RoiInputContainer = styled(Box)`
  position: relative;
  & > input {
    padding-left: 28px;
    max-width: 70%;
  }
  &:before {
    position: absolute;
    content: "$";
    // color: ${({ theme }) => theme.colors.textSubtle};
    left: 16px;
    top: 8px;
  }
`;

const RoiDisplayContainer = styled(Flex)`
  max-width: 82%;
  margin-right: 8px;
`;

const RoiDollarAmount = styled(Text)`
  position: relative;
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 0px;
  }
`;

const RoiCard = ({
  earningTokenSymbol,
  calculatorState,
  setTargetRoi,
  setCalculatorMode,
}) => {
  const [expectedRoi, setExpectedRoi] = useState("");
  const inputRef = useRef(null);
  const { roiUSD, roiTokens, roiPercentage } = calculatorState.data;
  const { mode } = calculatorState.controls;

  useEffect(() => {
    if (mode === CalculatorMode.PRINCIPAL_BASED_ON_ROI && inputRef.current) {
      inputRef.current.focus();
    }
  }, [mode]);

  const onEnterEditing = () => {
    setCalculatorMode(CalculatorMode.PRINCIPAL_BASED_ON_ROI);
    setExpectedRoi(
      roiUSD.toLocaleString("en", {
        minimumFractionDigits: roiUSD > MILLION ? 0 : 2,
        maximumFractionDigits: roiUSD > MILLION ? 0 : 2,
      })
    );
  };

  const onExitRoiEditing = () => {
    setCalculatorMode(CalculatorMode.ROI_BASED_ON_PRINCIPAL);
  };
  const handleExpectedRoiChange = (event) => {
    if (event.currentTarget.validity.valid) {
      const roiAsString = event.target.value.replace(/,/g, ".");
      setTargetRoi(roiAsString);
      setExpectedRoi(roiAsString);
    }
  };
  return (
    <RoiCardWrapper>
      <RoiCardInner>
        <Text
          fontSize="12px"
          color="secondary"
          bold
          textTransform="uppercase"
          style={{ color: "#e7a602" }}
        >
          {"ROI at current rates"}
        </Text>
        <Flex justifyContent="space-between" mt="4px" height="36px">
          {mode === CalculatorMode.PRINCIPAL_BASED_ON_ROI ? (
            <>
              <RoiInputContainer>
                <Input
                  ref={inputRef}
                  type="text"
                  inputMode="decimal"
                  pattern="^[0-9]+[.,]?[0-9]*$"
                  scale="sm"
                  value={expectedRoi}
                  placeholder="0.0"
                  onChange={handleExpectedRoiChange}
                />
              </RoiInputContainer>
              <IconButton scale="sm" variant="text" onClick={onExitRoiEditing}>
                <CheckmarkIcon color="primary" />
              </IconButton>
            </>
          ) : (
            <>
              <RoiDisplayContainer onClick={onEnterEditing}>
                {/* Dollar sign is separate cause its not supposed to scroll with a number if number is huge */}
                <Text fontSize="24px" bold>
                  $
                </Text>
                <RoiDollarAmount
                  fontSize="24px"
                  bold
                  fadeOut={roiUSD > TRILLION}
                >
                  {roiUSD.toLocaleString("en", {
                    minimumFractionDigits: roiUSD > MILLION ? 0 : 2,
                    maximumFractionDigits: roiUSD > MILLION ? 0 : 2,
                  })}
                </RoiDollarAmount>
              </RoiDisplayContainer>
              <IconButton scale="sm" variant="text" onClick={onEnterEditing}>
                <PencilIcon color="#2628319c" />
              </IconButton>
            </>
          )}
        </Flex>
        <Text fontSize="12px" color="textSubtle">
          ~ {roiTokens} {earningTokenSymbol} (
          {roiPercentage.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          %)
        </Text>
      </RoiCardInner>
    </RoiCardWrapper>
  );
};

export default RoiCard;
