import React from "react";
import { makeStyles, Typography, Box, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .textbox": {
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "30px",
      },
      "& h2": {
        color: "#FFFFFF",
        fontSize: "47px",
        [theme.breakpoints.down("xs")]: {
          height: "auto",
          fontSize: "35px",
        },
      },
      "& p": {
        color: "#FFF9F9",
        fontSize: "15px",
      },
      "& h3": {
        color: "#EFAB00",
        fontSize: "18px",
        "@media(max-width:767px)": {
          fontSize: "16px",
        },
      },
    },
    "& .imgbox": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "auto",
    },
  },
  worldmap: {
    width: "100%",
    backgroundImage: "url('../images/worldmap.png')",
    // position: "relative",
    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    height: "350px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mapgroup: {
    backgroundImage: "url('../images/Group 276.png')",
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    height: "370px",
    width: "370px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 767px)": {
      height: "275px",
      width: "275px",
    },
    "& .group1": {
      "& img": {
        "@media (max-width: 767px)": {
          width: "80%",
        },
      },
      position: "absolute",
      top: "30px",
      left: "38%",
      "@media (max-width: 767px)": {
        top: "15px",
        left: "37%",
      },
      textAlign: "center",
      "& p": {
        color: "#9A7AFA",
      },
    },
    "& .group2": {
      "& img": {
        "@media (max-width: 767px)": {
          width: "80%",
        },
      },
      position: "absolute",
      top: "36%",
      right: "36px",
      "@media (max-width: 767px)": {
        top: "35%",
        right: "20px",
      },
      textAlign: "center",
      "& p": {
        color: "#FFFFFF",
      },
    },
    "& .group3": {
      "& img": {
        "@media (max-width: 767px)": {
          width: "60%",
        },
      },
      position: "absolute",
      bottom: "15px",
      right: "35%",
      "@media (max-width: 767px)": {
        bottom: "10px",
        right: "32%",
      },
      textAlign: "center",
      "& p": {
        color: "#828384",
      },
    },
    "& .group4": {
      "& img": {
        "@media (max-width: 767px)": {
          width: "80%",
        },
      },
      position: "absolute",
      bottom: "34%",
      left: "45px",
      "@media (max-width: 767px)": {
        bottom: "30%",
        left: "30px",
      },
      textAlign: "center",
      "& p": {
        color: "#EFAB00",
      },
    },
    "& .groupcenter": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  bannerbox: {
    padding: "50px 0px",
    "@media(max-width:767px)": {
      padding: "50px 0 0px",
    },
  },
  rewardicon: {
    width: "84px",
    "@media(max-width:767px)": {
      width: "64px",
    },
  },
  imgtextbox: {
    [theme.breakpoints.down(" md")]: {
      width: "85%",
    },
  },
}));

function Rewards() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.bannerbox}>
        {/* <Container maxWidth="md">
        <Box className={classes.root}>
        <Box className="imgbox">
                <img src="images/Rewards.png" alt="Reward Image" width="100%" />
              </Box>
              <Box className="textbox">
                <Typography variant="h2">Rewards distribution</Typography>
                </Box>
                </Box>
        </Container> */}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <Box className={classes.root}>
              <Box className="imgbox">
                <img
                  src="images/Rewards.png"
                  alt="Reward Image"
                  className={classes.imgtextbox}
                />
              </Box>
              <Box className="textbox">
                <Typography variant="h2">Rewards Distribution</Typography>
                <Box mt={2}>
                  <Typography variant="body2">
                    Every Buy/Sell/Transfer Transaction will have a 14% Tax. 8%
                    will be distributed to all holders in BUSD, 4% will go
                    towards marketing, 1% will go towards Buyback and Burn, 1%
                    will go towards liquidity.
                  </Typography>
                </Box>

                <Box mt={2}>
                  <Typography variant="h3">
                    All holders of $MRF will earn 8% Binance (BUSD) rewards from
                    every BUY/SELL/TRANSFER Transaction. These Rewards will be
                    sent out to all $MRF holders automatically.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <Box className={classes.worldmap}>
              <Box className={classes.mapgroup}></Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Rewards;
