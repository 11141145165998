import tinycolor from "tinycolor2";
const primary = "#536DFE";
const secondary = "#efab00";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";
const lightenRate = 7.5;
const darkenRate = 15;
const defaultTheme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
    },
    background: {
      default: "#F6F7FF",
      light: "#F3F5FF",
    },
  },
  overrides: {
    MuiDialogContent: {
      root: {
        flex: "1 1 auto",
        padding: "0px",
        overflowY: "auto",
        "&:first-child": {
          paddingTop: "0px",
        },
      },
    },
    // MuiToggleButton: {
    //   root: {
    //     "&.Mui-selected": {
    //       color: "rgb(250 250 250)",
    //       backgroundColor: "rgb(243 165 45)",
    //     },
    //   },
    // },
    MuiToggleButtonGroup: {
      root: {
        display: "inline-flex",
        borderRadius: "4px",
        width: "100%",
      },
    },
    MuiToggleButton: {
      root: {
        color: "rgba(0, 0, 0, 0.38)",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        padding: "11px",
        fontSize: "0.875rem",
        boxSizing: "border-box",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: "500",
        lineHeight: "1.75",
        borderRadius: "4px",
        letterSpacing: "0.02857em",
        textTransform: "uppercase",
        width: "20%",
        "&.Mui-selected": {
          color: "rgb(250 250 250)",
          backgroundColor: "rgb(243 165 45)",
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        display: "flex",
        padding: "0px",
      },
    },
    MuiIconButton: {
      root: {
        flex: "0 0 auto",
        color: "rgb(26 27 29)",
        padding: "0px",
        overflow: "visible",
        fontSize: "1.5rem",
        textAlign: "center",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "50%",
      },
      edgeEnd: {
        // /* margin-right: -12px; */
        marginRight: "0px",
      },
    },
    MuiSwitch: {
      track: {
        backgroundColor: "#EFAB00",
      },
    },
    MuiAccordionSummary: {
      root: {
        display: "flex",
        padding: "0px 16px",
        minHeight: "0px",
        /* min-height: 48px; */
      },
      content: {
        margin: "0px 0",
        display: "block",
        flexGrow: "0",
        transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      },
    },

    MuiStepIcon: {
      text: {
        fill: "#090b16",
      },
      root: {
        color: "rgb(255 255 255 / 38%)",
        MuiStepIconActive: {
          color: "#fff",
        },
        "&.MuiStepIcon-completed": {
          color: "#fabe25",
        },
        "&.MuiStepIcon-active": {
          color: "#fabe25",
        },
      },
    },
    MuiTableRow: {
      root: {
        color: "inherit",
        display: "table-row",
        outline: "0",
        verticalAlign: "middle",
        background: "#23252e",
      },
    },
    MuiTableCell: {
      body: {
        color: "#fff",
      },
      root: {
        display: "table-cell",
        padding: "7px 15px",
        fontSize: "0.875rem",
        textAlign: "left",
        fontWeight: "400",
        lineHeight: "1.43",
        borderBottom: "1px solid #19182254",
        letterSpacing: "0.01071em",
        verticalAlign: "inherit",
      },
    },

    MuiDialog: {
      paperWidthSm: {
        maxWidth: "450px",
      },
    },

    MuiTab: {
      root: {
        padding: "6px 12px",
        overflow: "hidden",
        position: "relative",
        fontSize: "16px",
        fontWeight: "bold",
        maxWidth: "264px",
        minWidth: "72px",
        boxSizing: "border-box",
        minHeight: "48px",
        textAlign: "center",
        lineHeight: "1.75",
        whiteSpace: "normal",
        letterSpacing: "0.02857em",
        textTransform: "uppercase",
        "@media (max-width: 767px)": {
          fontSize: "13px",
        },
      },
    },
    MuiSlider: {
      root: {
        color: "#f6a52d",
        width: "100%",
        cursor: "pointer",
        height: "8px",
        display: "inline-block",
        padding: "13px 0",
        position: "relative",
        boxSizing: "content-box",
        touchAction: "none",
      },
    },
    MuiInputBase: {
      input: {
        font: "inherit",
        color: "#fff",
        // border: "1px solid #8a8b8b",
        width: "100%",
        borderRadius: "5px",
        fontSize: "13px",
        height: "0.1876em",
        margin: "0",
        display: "block",
        padding: "6px 0 7px",
        minWidth: "0",
        background: "none",
        boxSizing: "content-box",
        letterSpacing: "inherit",
        "&:focus-visible": {
          outline: "none",
        },
      },
    },

    MuiSvgIcon: {
      root: {
        color: "#F6A52D",
        height: "1em",
        display: "inline-block",
        transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        flexShrink: "0",
        userSelect: "none",
        marginRight: "7px",
      },
    },
    MuiAppBar: {
      root: {
        width: "100%",
        display: "flex",
        boxSizing: "border-box",
        flexShrink: "0",
      },
      colorPrimary: {
        color: "#fff",
        backgroundColor: "#262831",
      },
    },
    MuiMenuItem: {
      root: { color: "#fff", padding: "6px" },
    },
    MuiPaper: {
      root: { boxShadow: "none !important" },
      rounded: {
        // backgroundColor: " #090c16",
        // // width: "100%",
        // border: "1px solid #f6a52d8f",
        // borderRadius: "14px",
        border: "1px solid #262831",
        borderRadius: "14px",
        backgroundColor: "#262831",
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: " #090b16",
        border: "1px solid #443309",
        "@media (max-width: 767px)": {
          display: "block",
        },
      },
    },
    MuiStep: {
      horizontal: {
        width: "25%",
        "@media (max-width: 767px)": {
          width: "100%",
        },
      },
    },
    MuiButton: {
      label: {
        fontFamily: "Roboto Slab, serif",
      },
      outlinedSizeLarge: {
        padding: "7px 21px",
        width: "100%",
        maxWidth: "140px",
        height: "45px",
      },
      containedSizeLarge: {
        fontSize: "16px",
        color: "#FFFFFF",
        background: "#EFAB00",
        borderRadius: "30px",
        textTransform: "capitalize",
        fontFamily: "Roboto Slab",
        "@media (max-width: 767px)": {
          fontSize: "14px",
        },
      },
      containedSecondary: {
        color: "#FFFFFF",
        border: "1px solid #E8424C",
        cursor: "pointer",
        height: "50px",
        fontSize: "14px",
        minWidth: "143px",
        backgroundColor: "#E8424C",
        boxShadow: "none",
        fontWeight: "600",
        borderRadius: "50px",
        "&:hover": {
          borderColor: "#E8424C",
          backgroundColor: "#E8424C",
        },
      },
      outlinedPrimary: {
        color: " #ffffff",
        border: "1.5px solid #EFAB00",
        borderRadius: "32px",
        fontSize: "16px !important",
        lineHeight: "16px",
        // height: " 50px",
        padding: "13px 29px",
        fontWeight: "600",
        maxWidth: "153px",
        boxSizing: "border-box",
        textTransform: "capitalize",
        fontFamily: "Roboto Slab, serif !important",
        "&:hover": {
          background: "#EFAB00 !important",
          border: "1.5px solid #EFAB00",
          color: "#FFFFFF",
        },
      },
      root: {
        // "&:hover": {
        //   backgroundColor: "#efab00",
        // },
        "&.Mui-disabled": {
          color: "rgba(255, 255, 255, 0.7) !important",
        },
      },
      contained: {
        "&:hover": {
          backgroundColor: "linear-gradient(180deg, #F8B900 0%, #8F5409 100%)",
        },
        "&.Mui-disabled": {
          color: "rgb(219 219 219 / 48%)",
        },
      },
      containedPrimary: {
        borderRadius: "50px",
        fontSize: "14px",
        fontWeight: 600,
        // minWidth: "150px",
        height: "45px",
        color: "#FFFFFF",
        transition: "0.5s",
        backgroundColor: "#EFAB00",
        "&:hover": {
          backgroundColor: "#EFAB00",
        },
        "&.active": {
          background: "linear-gradient( 180deg, #8F5409 0%, #F8B900 100%)",
        },
        "@media (max-width: 1024px)": {
          fontSize: "13px",
        },
        "@media (max-width: 767px)": {
          fontSize: "13px",
          // width: "100%",
          // marginTop:"10px",
        },
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: "Roboto Slab, serif",
      },
    },
    MuiBox: {
      root: {
        fontFamily: "Roboto Slab, serif",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
    },
    MuiSelect: {
      selectMenu: {
        border: "1px solid #8a8b8b",
        width: "100%",
        borderRadius: "5px",
        fontSize: "13px",
        // height: "0.1876em",
        margin: "0",
        display: "block",
        padding: "10px 12px 7px",
        height: "23px",
      },
      select: {
        "&:focus": {
          borderRadius: "5px",
        },
      },
      icon: {
        color: "#B9B9B9",
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 0,
      },
    },

    "& .gewDBn": {
      "& .hVlBZq": {
        color: "#efab00 !important",
      },
    },
    subtle: {
      //   ...light.button.subtle,
      background: "#087476",
      backgroundActive: "#087476",
      backgroundHover: "#087476",
    },
  },
};

export default defaultTheme;
