import React from "react";
import { Box, makeStyles, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  bannerBox: {
    // backgroundImage: "url('../images/banner.png')",
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "top right",
    backgroundRepeat: "no-repeat",
    justifyContent: "center",
    zIndex: " 1",
    textAlign: "center",
    // height: "100vh",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "90px 0px 0",
      height: "auto",
    },
    "& .textcontent": {
      padding: "230px 0px",
      "@media(max-width:767px)": {
        padding: "30px 0px 0px",
      },
      "& h1": {
        color: "#FFFFFF",
        "@media(max-width:767px)": {
          fontSize: "35px",
        },
      },
      "& h3": {
        color: "#EFAB00",
        fontSize: "39px",
        padding: "15px 0px",
        "@media(max-width:767px)": {
          fontSize: "25px",
        },
      },
      "& p": {
        color: "#FFFFFF",
        maxWidth: "862px",
        "@media(max-width:767px)": {
          fontSize: "14px",
        },
      },
    },

    "& .imgbox": {
      position: "absolute",
      right: "-100px",
      top: "-6px",
      zIndex: "-1",
      "@media(max-width:767px)": {
        top: "-197px",
      },
    },
    "& .buttonbox": {
      marginTop: "50px",
      "& button": {
        margin: "0px 5px",
      },
    },
  },
}));
export default function Dashboard(props) {
  var classes = useStyles();
  return (
    <>
      <Box className={classes.bannerBox}>
        <Box className="imgbox">
          <img src="../images/banner.png" alt="banner" />
        </Box>
        <Box className="textcontent">
          <Typography variant="h1">MULTI CHAIN DECENTRALIZED</Typography>
          <Typography variant="h3">PROTOCOLS & SERVICES</Typography>
          <Box>
            <Typography variant="body2">
              Started in June 2020, IDO Metareflect provides an ever-growing
              suite of decentralized services. The objective is to bring value
              to the DeFi space as a whole by delivering disruptive, flexible
              and audited technology. Strenghten your project and reward your
              communities using our services.
            </Typography>
          </Box>

          <Box className="buttonbox">
            <Button
              variant="outlinedPrimary"
              component={Link}
              to="/app/staking"
            >
              Stake
            </Button>
            <Button
              variant="outlinedPrimary"
              // component={Link} to="/app/About"
              href="https://www.metareflect.io/"
              target="_blanck"
              style={{ marginLeft: "15px" }}
            >
              About
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
