import React, { useEffect, useState } from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import FormsCards from "./FormsCards";
import {
  stakingConAddress1,
  stakingConAddress2,
  stakingConAddress3,
  stakingConAddress4,
  // stakingConAddress5,
} from "../../constants/index";
import IERC20ABI from "../../abis/IERC20ABI.json";
import stakingABI1 from "../../abis/StakingABI1.json";
import stakingABI2 from "../../abis/StakingABI2.json";
import stakingABI3 from "../../abis/StakingABI3.json";
import stakingABI4 from "../../abis/StakingABI4.json";
import Web3 from "web3";
import { UserContext } from "../../context/User";
import { default_RPC_URL } from "../../constants/index";
import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import { getContract } from "../../utils";

const useStyles = makeStyles((theme) => ({
  firstletter: {
    textTransform: "lowercase",
  },
  mainBox: {
    "& .MuiDialogContentText-root": {
      padding: "0 24px",
    },
  },
  heading: {
    paddingTop: "20px",
    "& h1": {
      // fontFamily: "Montserrat",
      fontWeight: "600",
      marginBottom: "12px",
      fontSize: "50px",
      lineHeight: "76px",
      textAlign: "center",
      color: "#efab00",
      marginTop: "30px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
        lineHeight: "40px",
        marginBottom: "0px",
      },
    },
  },
  swapBox: {
    border: "1px solid #16abd31f",
    padding: "15px",
    position: "relative",
    background: "rgb(38 40 49)",
    transition: " 0.5s",
    borderRadius: "10px",
    // height:"100%",
    "& label": {
      color: "#e6e6e6",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
      "& button": {
        minWidth: "auto",
        padding: 0,
        fontSize: "18px",
        color: "#fff",
        marginLeft: "5px",
      },
    },
    "& small": {
      color: "#b2b2b2",
      fontSize: "14px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
      "& strong": {
        color: "#fff",
      },
    },
    "& .paybox": {
      padding: "10px",
      backgroundColor: "#1d1e20",
      borderRadius: "5px",
      "& input": {
        backgroundColor: " transparent",
        border: "none",
        color: "#ffff",
        fontSize: "18px",
        fontWeight: 800,
        textAlign: "right",
        width: "100%",
        "&:focus-visible": {
          outline: "none !important",
        },
      },
      "& p": {
        fontSize: "14px",
        color: "#b2b2b2",
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px",
        },
      },
      "& h6": {
        display: "flex",
        alignItems: "center",
        fontWeight: 800,
        color: "#fff",
        [theme.breakpoints.down("xs")]: {
          fontSize: "14px",
        },
        "& img": {
          width: "25px",
          marginRight: "6px",
          [theme.breakpoints.down("xs")]: {
            width: "18px",
          },
        },
      },
    },
    "& .transfer": {
      width: " 100%",
      maxWidth: "30px",
      height: "30px",
      borderRadius: "50%",
      backgroundColor: "#071426",
      color: " #fff",
      fontSize: "15px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "10px auto",
      transform: "rotate(90deg)",
      cursor: "pointer",
    },
  },
  buttonright: {
    fontSize: "13px ",
    border: "2px solid transparent",
    fontWeight: 600,
    height: "44px ",
    background: "linear-gradient(272.26deg, #efab00e0 36.78%, #efab00 86.13%)",
    color: "#fff",
    minWidth: "125px ",
    width: "100%",
    borderRadius: "30px",
    boxShadow: "inset 0px 0px 2px rgba(0, 0, 0, 0.4)",
    // boxShadow: "none ",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "45px ",
      minWidth: "120px ",
    },
    "&:hover": {
      color: "#fff",
      background: "#0c2739",

      border: "2px solid transparent",
      boxSizing: "border-box",
      backgroundClip: "padding-box, border-box",
      backgroundImage:
        "linear-gradient(#1d1e20, #262831), linear-gradient(#efab00, #efab00cc)",
      backgroundOrigin: "padding-box, border-box",
    },
  },
  tokenBox: {
    height: "300px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
  },
  solutionBox: {
    backgroundColor: "#071426",
    padding: "10px",
    display: "flex",
    marginBottom: "6px",
    alignItems: "center",
    borderRadius: " 5px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#113044",
    },
    "& h4": {
      fontSize: "16px",
      color: "#ffffff",
      fontWeight: " 800",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
    "& p": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
    "& figure": {
      margin: "0",
      width: "30px",
      marginRight: "20px",
      [theme.breakpoints.down("xs")]: {
        width: "25px",
        marginRight: "15px",
      },
      "& img": {
        width: "auto",
        maxWidth: "100%",
      },
    },
  },
  paddingbox: {
    paddingTop: "20px",
    "& p": {
      color: "#fff",
    },
  },
}));
export default function Dashboard(props) {
  var classes = useStyles();
  const marketCap = window.sessionStorage.getItem("marketcap");
  // console.log("datatatta", marketCap);
  const { chainId, library, account } = useWeb3React();
  const httpProvider = new Web3.providers.HttpProvider(default_RPC_URL);
  const web3 = new Web3(httpProvider);

  const [depositFee, setdepositFee] = useState("");
  const [depositFee1, setdepositFee1] = useState("");
  const [depositFee3, setdepositFee3] = useState("");
  const [depositFee4, setdepositFee4] = useState("");

  const [getOwner, setgetOwner] = useState("");
  const [getOwner1, setgetOwner1] = useState("");
  const [getOwner3, setgetOwner3] = useState("");
  const [getOwner4, setgetOwner4] = useState("");
  // const [duration5, setduration5] = useState("");
  // console.log("depositFee1", depositFee1);
  const [widthdrawFee, setwidthdrawFee] = useState("");
  const [widthdrawFee1, setwidthdrawFee1] = useState("");
  const [widthdrawFee3, setwidthdrawFee3] = useState("");
  const [widthdrawFee4, setwidthdrawFee4] = useState("");

  const [totalStaked, settotalStaked] = useState("");
  const [totalStaked1, settotalStaked1] = useState("");
  const [totalStaked3, settotalStaked3] = useState("");
  const [totalStaked4, settotalStaked4] = useState("");

  const [rewardPerBlock, setRewardPerBlock] = useState("");
  const [rewardPerBlock1, setRewardPerBlock1] = useState("");
  const [rewardPerBlock3, setRewardPerBlock3] = useState("");
  const [rewardPerBlock4, setRewardPerBlock4] = useState("");

  const [reflected, setreflected] = useState("");
  const [reflected1, setreflected2] = useState("");
  const [reflected3, setreflected3] = useState("");
  const [reflected4, setreflected4] = useState("");

  const [earned, setEarned] = useState("");
  const [earned1, setEarned2] = useState("");
  const [earned3, setEarned3] = useState("");
  const [earned4, setEarned4] = useState("");

  const [userInfo, setuserInfo] = useState("");
  const [userInfo2, setuserInfo2] = useState("");
  const [userInfo3, setuserInfo3] = useState("");
  const [userInfo4, setuserInfo4] = useState("");
  const [userInfo5, setuserInfo5] = useState("");

  const [stakeName, setStakeName] = useState("");
  const [stakeName2, setStakeName2] = useState("");
  const [stakeName3, setStakeName3] = useState("");
  const [stakeName4, setStakeName4] = useState("");

  const [buttonLoader, setbuttonLoader] = useState(false);
  //*********loader for harvest*****************
  const [harvestLoader, setharvestLoader] = useState(false);
  const [harvestLoader2, setharvestLoader2] = useState(false);
  const [harvestLoader3, setharvestLoader3] = useState(false);
  const [harvestLoader4, setharvestLoader4] = useState(false);
  //*********loader for updateWalletA*****************
  const [updateWalletALoader, setupdateWalletALoader] = useState(false);
  const [updateWalletALoader2, setupdateWalletALoader2] = useState(false);
  const [updateWalletALoader3, setupdateWalletALoader3] = useState(false);
  const [updateWalletALoader4, setupdateWalletALoader4] = useState(false);

  // function
  // console.log("getOwner3", getOwner);
  // console.log("account", account);
  const StakingFunction = async () => {
    try {
      const contractObj = getContract(
        stakingConAddress1,
        stakingABI1,
        library,
        account
      );
      const UserInfo1 = await contractObj.userInfo(account);
      setuserInfo(web3.utils.fromWei(UserInfo1?.amount?.toString()));
      // console.log(
      //   account,
      //   "1---------------------------------------------------StakingFunction",
      //   contractObj
      // );

      const bonusEndBlock = await contractObj.bonusEndBlock();
      const bonusEndBlockdata = await web3.eth.getBlock("latest");
      // setbonusEndBlock(
      //   Number(bonusEndBlock.toString()) - bonusEndBlockdata.number
      // );
      const rewardPerBlock = await contractObj.rewardPerBlock();
      // console.log(rewardPerBlock);
      // console.log(
      //   "+++++++++rewardPerBlock",
      //   (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      // );
      setRewardPerBlock(
        (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      );

      const rewardToken = await contractObj.earnedToken();
      // console.log("earnedToken", rewardToken);
      await rewardToken;
      const rewardTokenObj = getContract(
        rewardToken,
        IERC20ABI,
        library,
        account
      );
      // console.log("rewardTokenObjrewardTokenObj------------1", rewardTokenObj);
      // const getOwner = await rewardTokenObj.getOwner();
      // setgetOwner(getOwner);
      // console.log(
      //   "rewardTokenObjrewardTokenObj-----getOwner-------1",
      //   getOwner
      // );
      const symbol = await rewardTokenObj.symbol();
      // console.log("symbol", symbol);
      setEarned(symbol);

      const dividendToken = await contractObj.dividendToken();
      await rewardToken;
      const dividendTokenObj = getContract(
        dividendToken,
        IERC20ABI,
        library,
        account
      );
      const staked = await contractObj.stakingToken();
      await staked;
      // console.log(staked, "stakingToken");
      const contractDeposit = getContract(staked, IERC20ABI, library, account);

      const getOwner = await contractDeposit.getOwner();
      setgetOwner(getOwner);
      const balanceOfdata = await contractDeposit.balanceOf(account);
      // setbalanceOf(balanceOfdata);

      const name12 = await contractDeposit.symbol();
      // await name12.wait();
      // // console.log("contract----------name-----1", name12);
      setStakeName(name12);

      const depositFee1 = await contractObj.depositFee();
      setdepositFee(depositFee1.toString() / 100);

      const withdrawFee = await contractObj.withdrawFee();

      setwidthdrawFee(withdrawFee.toString() / 100);

      const totalStaked1 = await contractObj.totalStaked();
      settotalStaked(web3.utils.fromWei(totalStaked1.toString()));
      // console.log("totalStaked--------------111111", totalStaked1.toString());
      const PendingRewards1 = await contractObj.pendingReward(account);
      // console.log("PendingRewards1+++++++", PendingRewards1);
      // setPendingReward(web3.utils.fromWei(PendingRewards1.toString()));

      const pendingDividends = await contractObj.pendingDividends(account);
      // console.log("pendingDividends+++++++", pendingDividends);
      // setpendingDividends(web3.utils.fromWei(pendingDividends.toString()));

      // console.log(UserInfo);
      // setnextHarvestUntil(userInfo.nextHarvestUntil?.toString());
      // setnextWithdrawUntil(userInfo.nextWithdrawUntil?.toString());
    } catch (error) {
      console.log(error);
    }
  };

  const StakingFunction1 = async () => {
    try {
      const contractObj = getContract(
        stakingConAddress2,
        stakingABI2,
        library,
        account
      );

      const UserInfo1 = await contractObj.userInfo(0, account);
      // console.log(
      //   "userInfoData------2",
      //   web3.utils.fromWei(UserInfo1?.amount?.toString())
      // );
      setuserInfo2(web3.utils.fromWei(UserInfo1?.amount?.toString()));
      // console.log("====compoundReward==-----=-=-2", contractObj);

      // const compoundReward1 = await contractObj.compoundReward(0);
      // console.log("compoundReward ------------11", compoundReward1);
      const lockups = await contractObj.lockups(0);
      // console.log("compoundReward-----lockups-", lockups);
      const values12 = lockups.depositFee.toString() / 100;
      // console.log("compoundReward---------deposit fee----", values12);
      setdepositFee1(values12);
      setwidthdrawFee1(lockups.withdrawFee.toString() / 100);
      settotalStaked1(web3.utils.fromWei(lockups.totalStaked.toString()));

      const rewardToken = await contractObj.earnedToken();
      await rewardToken;
      const rewardTokenObj = getContract(
        rewardToken,
        IERC20ABI,
        library,
        account
      );
      const symbol = await rewardTokenObj.symbol();
      setEarned2(symbol);
      const name = await rewardTokenObj.symbol();
      setStakeName2(name);

      const dividendToken = await contractObj.dividendToken();
      await rewardToken;
      const dividendTokenObj = getContract(
        dividendToken,
        IERC20ABI,
        library,
        account
      );
      const dividendTokensymbol = await dividendTokenObj.symbol();
      setreflected2(dividendTokensymbol);
      const name12 = await rewardTokenObj.symbol();
      // // console.log("rewardTokenObj---- 2 -----name", name);
      setStakeName2(name12);

      const staked = await contractObj.stakingToken();
      await staked;
      const contractDeposit = getContract(staked, IERC20ABI, library, account);

      const getOwners = await contractDeposit.getOwner();
      setgetOwner1(getOwners);
      // console.log("rewardTokenObjrewardTokenObj------------1", getOwners);
      const allowanceallowance = await contractDeposit.allowance(
        account,
        stakingConAddress2
      );
      // setAllowanceAmmount2(allowanceallowance);
      const balanceOfdata = await contractDeposit.balanceOf(account);
      // setbalanceOf2(balanceOfdata);

      // console.log(
      //   contractDeposit,
      //   "contractDeposit-----------contractstakedApprove-------------------1",
      //   allowanceallowance.toString()
      // );
      // const totalStaked1 = await contractObj.totalStaked();
      // settotalStaked1(web3.utils.fromWei(totalStaked1.toString()));
      // const userStaked = await contractObj.userStaked(account);
      // console.log("======-----=-=-2", userStaked);
      // const userInfo = await contractObj.userInfo(0, account);
      // // console.log("======-----=-=-2", userInfo);

      // const bonusEndBlock = await contractObj.bonusEndBlock();
      // const bonusEndBlockdata = await web3.eth.getBlock("latest");
      // setbonusEndBlock2(
      //   Number(bonusEndBlock.toString()) - bonusEndBlockdata.number
      // );
      const rewardPerBlock = await contractObj.rewardPerBlock(0);
      // console.log(
      //   "2+++++++++rewardPerBlock",
      //   (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      // );
      setRewardPerBlock1(
        (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      );

      const PendingRewards1 = await contractObj.pendingReward(account, 0);
      // console.log(PendingRewards1);
      // setPendingReward1(web3.utils.fromWei(PendingRewards1.toString()));

      const pendingDividends = await contractObj.pendingDividends(account, 0);
      // console.log("pendingDividends+++++++", pendingDividends);
      // setpendingDividends1(web3.utils.fromWei(pendingDividends.toString()));
    } catch (error) {
      console.log(error);
    }
  };

  const StakingFunction3 = async () => {
    try {
      const contractObj = getContract(
        stakingConAddress3,
        stakingABI3,
        library,
        account
      );

      const UserInfo1 = await contractObj.userInfo(1, account);
      // console.log("userInfo------3", UserInfo1);
      setuserInfo3(web3.utils.fromWei(UserInfo1?.amount?.toString()));
      // console.log("======-----=-=-2", contractObj);

      const lockups = await contractObj.lockups(1);
      // console.log("------", lockups);
      const values12 = lockups.depositFee.toString() / 100;
      // console.log("depositFee12", values12);
      setdepositFee3(values12);
      setwidthdrawFee3(lockups.withdrawFee.toString() / 100);
      settotalStaked3(web3.utils.fromWei(lockups.totalStaked.toString()));

      const rewardToken = await contractObj.earnedToken();
      await rewardToken;
      const rewardTokenObj = getContract(
        rewardToken,
        IERC20ABI,
        library,
        account
      );
      const symbol = await rewardTokenObj.symbol();
      setEarned3(symbol);
      const name = await rewardTokenObj.symbol();
      setStakeName3(name);

      const dividendToken = await contractObj.dividendToken();
      await rewardToken;
      const dividendTokenObj = getContract(
        dividendToken,
        IERC20ABI,
        library,
        account
      );
      const dividendTokensymbol = await dividendTokenObj.symbol();
      setreflected3(dividendTokensymbol);
      // const name = await rewardTokenObj.name();
      // // console.log("rewardTokenObj---- 2 -----name", name);
      // setStakeName2(name);

      const staked = await contractObj.stakingToken();
      await staked;
      const contractDeposit = getContract(staked, IERC20ABI, library, account);

      const getOwner = await rewardTokenObj.getOwner();
      setgetOwner3(getOwner);
      const allowanceallowance = await contractDeposit.allowance(
        account,
        stakingConAddress3
      );
      // setAllowanceAmmount3(allowanceallowance);

      const balanceOfdata = await contractDeposit.balanceOf(account);
      // setbalanceOf3(balanceOfdata);
      // console.log(
      //   "contractstakedApprove-------------------3",
      //   allowanceallowance.toString()
      // );
      const totalStaked1 = await contractObj.totalStaked();
      settotalStaked3(web3.utils.fromWei(totalStaked1.toString()));
      const userStaked = await contractObj.userStaked(account);
      // // console.log("======-----=-=-2", userStaked);
      const userInfo = await contractObj.userInfo(1, account);
      // // console.log("======-----=-=-2", userInfo);

      // const bonusEndBlock = await contractObj.bonusEndBlock();
      // const bonusEndBlockdata = await web3.eth.getBlock("latest");
      // // setbonusEndBlock3(
      // //   Number(bonusEndBlock.toString()) - bonusEndBlockdata.number
      // // );
      const rewardPerBlock = await contractObj.rewardPerBlock(1);
      // console.log(
      //   "2+++++++++rewardPerBlock",
      //   (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      // );
      setRewardPerBlock3(
        (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      );

      const PendingRewards1 = await contractObj.pendingReward(account, 1);
      // console.log(PendingRewards1);
      // setPendingReward3(web3.utils.fromWei(PendingRewards1.toString()));

      const pendingDividends = await contractObj.pendingDividends(account, 1);
      // console.log("pendingDividends+++++++", pendingDividends);
      // setpendingDividends3(web3.utils.fromWei(pendingDividends.toString()));
    } catch (error) {
      console.log(error);
    }
  };

  const StakingFunction4 = async () => {
    try {
      const contractObj = getContract(
        stakingConAddress4,
        stakingABI4,
        library,
        account
      );

      const UserInfo1 = await contractObj.userInfo(0, account);
      // console.log("userInfo------4", UserInfo1);
      setuserInfo4(web3.utils.fromWei(UserInfo1?.amount?.toString()));
      // console.log("StakingFunction4======-----=-=-4", contractObj);

      const lockups = await contractObj.lockups(0);
      // console.log("StakingFunction4-----4-", lockups);
      const values12 = lockups.depositFee.toString() / 100;
      // console.log("StakingFunction4----------------depositFee124", values12);
      setdepositFee4(values12);
      setwidthdrawFee4(lockups.withdrawFee.toString() / 100);
      settotalStaked4(web3.utils.fromWei(lockups.totalStaked.toString()));

      const rewardToken = await contractObj.earnedToken();
      await rewardToken;
      const rewardTokenObj = getContract(
        rewardToken,
        IERC20ABI,
        library,
        account
      );
      const symbol = await rewardTokenObj.symbol();
      setEarned4(symbol);
      const name = await rewardTokenObj.symbol();
      setStakeName4(name);

      const dividendToken = await contractObj.dividendToken();
      await rewardToken;
      const dividendTokenObj = getContract(
        dividendToken,
        IERC20ABI,
        library,
        account
      );
      const dividendTokensymbol = await dividendTokenObj.symbol();
      // console.log("dividendToken------- 2 --------symbol", dividendTokensymbol);
      setreflected4(dividendTokensymbol);
      // const name = await rewardTokenObj.name();
      // // console.log("rewardTokenObj---- 2 -----name", name);
      // setStakeName2(name);

      // const totalStaked1 = await contractObj.totalStaked();
      // settotalStaked4(web3.utils.fromWei(totalStaked1.toString()));
      const userStaked = await contractObj.userStaked(account);
      // console.log("======-----=-=-2", userStaked);
      const userInfo1 = await contractObj.userInfo(0, account);
      // console.log(
      //   "======--userInfouserInfouserInfouserInfouserInfo---=-=-2",
      //   userInfo1
      // );

      const bonusEndBlock = await contractObj.bonusEndBlock();
      const bonusEndBlockdata = await web3.eth.getBlock("latest");
      // setbonusEndBlock4(
      //   Number(bonusEndBlock.toString()) - bonusEndBlockdata.number
      // );

      const staked = await contractObj.stakingToken();
      await staked;
      const contractDeposit = getContract(staked, IERC20ABI, library, account);

      const getOwner = await rewardTokenObj.getOwner();
      setgetOwner4(getOwner);
      const allowanceallowance = await contractDeposit.allowance(
        account,
        stakingConAddress4
      );
      // setAllowanceAmmount4(allowanceallowance);

      const balanceOfdata = await contractDeposit.balanceOf(account);
      // setbalanceOf4(balanceOfdata);
      // console.log(
      //   "contractstakedApprove-------------------4",
      //   allowanceallowance.toString()
      // );
      const rewardPerBlock = await contractObj.rewardPerBlock(0);
      // console.log(
      //   "2+++++++++rewardPerBlock",
      //   (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      // );
      setRewardPerBlock4(
        (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      );

      const PendingRewards1 = await contractObj.pendingReward(account, 0);
      // console.log(PendingRewards1);
      // setPendingReward4(web3.utils.fromWei(PendingRewards1.toString()));

      const pendingDividends = await contractObj.pendingDividends(account, 0);
      // console.log("pendingDividends+++++++", pendingDividends);
      // setpendingDividends4(web3.utils.fromWei(pendingDividends.toString()));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (chainId === 97) {
      StakingFunction();
      StakingFunction1();
      StakingFunction3();
      StakingFunction4();
    }
  }, [account]);

  const UpdateRewardPerBlock = async (id) => {
    if (!id) {
      toast.error("Missing Argument !");
      return;
    }
    try {
      setbuttonLoader(true);
      const contract = getContract(
        stakingConAddress1,
        stakingABI1,
        library,
        account
      );

      console.log("contract", contract);

      const Withdrawing = await contract.updateRewardPerBlock(
        web3.utils.toWei(id.toString())
      );
      await Withdrawing.wait();
      // console.log(Withdrawing);
      toast.success("Reward Per Block Updated successfully");
      setbuttonLoader(false);
    } catch (error) {
      toast.error(
        <span className={classes.firstletter}>{error.data.message}</span>
      );
      setbuttonLoader(false);
      console.log(error);
    }
    // } else {
    //   toast.error("Reward Per Block Updated more than staked !");
    // }
  };

  const harvest = async (id) => {
    // console.log(id.toString());
    // console.log(userInfo?.toString());
    // if (Number(userInfo?.toString()) >= Number(id?.toString())) {
    try {
      setharvestLoader(true);
      const contract = getContract(
        stakingConAddress1,
        stakingABI1,
        library,
        account
      );

      console.log("contract", contract);

      const Withdrawing = await contract.harvest();
      await Withdrawing.wait();
      toast.success("Harvest successfully completed");
      setharvestLoader(false);
    } catch (error) {
      toast.error(error.data.message);
      setharvestLoader(false);
      console.log(error);
    }
    // } else {
    //   toast.error("Reward Per Block Updated more than staked !");
    // }
  };
  const harvest2 = async (id) => {
    try {
      setharvestLoader2(true);
      const contract = getContract(
        stakingConAddress2,
        stakingABI2,
        library,
        account
      );

      console.log("contract", contract);

      const erc20address = await contract.harvest();
      // console.log(erc20address);
      await erc20address.wait();
      toast.success("Harvest successfully completed");
      setharvestLoader2(false);
    } catch (error) {
      toast.error(error.data.message);
      setharvestLoader2(false);
      console.log(error);
    }
  };

  const harvest3 = async (id) => {
    try {
      setharvestLoader3(true);
      const contract = getContract(
        stakingConAddress3,
        stakingABI3,
        library,
        account
      );

      console.log("contract", contract);

      const erc20address = await contract.harvest();
      // console.log(erc20address);
      await erc20address.wait();
      toast.success("Harvest successfully completed");
      setharvestLoader3(false);
    } catch (error) {
      toast.error(error.data.message);
      setharvestLoader3(false);
      console.log(error);
    }
  };

  const harvest4 = async (id) => {
    try {
      setharvestLoader4(true);
      const contract = getContract(
        stakingConAddress4,
        stakingABI4,
        library,
        account
      );

      // console.log("contract", contract);

      const erc20address = await contract.harvest();
      await erc20address.wait();
      toast.success("Harvest successfully completed");
      setharvestLoader4(false);
    } catch (error) {
      toast.error(error.data.message);
      setharvestLoader4(false);
      console.log(error);
    }
  };

  const updatePoolLimit = async (id) => {
    console.log(id.toString());
    console.log(id);
    if (!id) {
      toast.error("Must be contain value");
      return;
    }
    if (0 > Number(id?.toString())) {
      toast.error("Missing Argument !");
      return;
    }
    try {
      setharvestLoader(true);
      const contract = getContract(
        stakingConAddress1,
        stakingABI1,
        library,
        account
      );

      console.log("contract", contract);

      const Withdrawing = await contract.updatePoolLimitPerUser(true, id);
      await Withdrawing.wait();
      toast.success("Update PoolLimit successfully ");
      setharvestLoader(false);
    } catch (error) {
      toast.error(error.data.message);
      setharvestLoader(false);
      console.log(error);
    }
    // } else {
    //   toast.error("Missing Argument !");
    // }
  };
  const updatePoolLimit2 = async (id) => {
    if (!id) {
      toast.error("Must be contain value");
      return;
    }
    if (0 > Number(id?.toString())) {
      toast.error("Missing Argument !");
      return;
    }
    try {
      setharvestLoader2(true);
      const contract = getContract(
        stakingConAddress2,
        stakingABI2,
        library,
        account
      );

      console.log("contract", contract);
      console.log(web3.utils.toWei(id.toString()));
      const erc20address = await contract.updatePoolLimitPerUser(
        true,
        web3.utils.toWei(id.toString())
      );
      // console.log(erc20address);
      await erc20address.wait();
      toast.success("Update PoolLimit successfully ");
      setharvestLoader2(false);
    } catch (error) {
      toast.error(error.data.message);
      setharvestLoader2(false);
      console.log("err", error);
    }
  };

  const updatePoolLimit3 = async (id) => {
    if (!id) {
      toast.error("Must be contain value");
      return;
    }
    if (0 > Number(id?.toString())) {
      toast.error("Missing Argument !");
      return;
    }
    try {
      setharvestLoader3(true);
      const contract = getContract(
        stakingConAddress3,
        stakingABI3,
        library,
        account
      );

      console.log("contract", contract);

      const erc20address = await contract.updatePoolLimitPerUser(true, id);
      // console.log(erc20address);
      await erc20address.wait();
      toast.success("Update PoolLimit successfully ");
      setharvestLoader3(false);
    } catch (error) {
      toast.error(error.data.message);
      setharvestLoader3(false);
      console.log(error);
    }
  };

  const updatePoolLimit4 = async (id) => {
    if (!id) {
      toast.error("Must be contain value");
      return;
    }
    if (0 > Number(id?.toString())) {
      toast.error("Missing Argument !");
      return;
    }
    try {
      setharvestLoader4(true);
      const contract = getContract(
        stakingConAddress4,
        stakingABI4,
        library,
        account
      );

      // console.log("contract", contract);

      const erc20address = await contract.updatePoolLimitPerUser(true, id);
      await erc20address.wait();
      toast.success("Update PoolLimit successfully ");
      setharvestLoader4(false);
    } catch (error) {
      toast.error(error.data.message);
      setharvestLoader4(false);
      console.log(error);
    }
  };

  const updateLockup = async (id) => {
    console.log(id.toString());
    console.log(id);
    if (0 > Number(id?.toString())) {
      toast.error("Missing Argument !");
      return;
    }
    try {
      setharvestLoader(true);
      const contract = getContract(
        stakingConAddress1,
        stakingABI1,
        library,
        account
      );

      console.log("contract", contract);

      const Withdrawing = await contract.updateLockup(true, id);
      await Withdrawing.wait();
      toast.success("Update PoolLimit successfully ");
      setharvestLoader(false);
    } catch (error) {
      toast.error(error.data.message);
      setharvestLoader(false);
      console.log(error);
    }
    // } else {
    //   toast.error("Missing Argument !");
    // }
  };
  const updateLockup2 = async (id) => {
    console.log(id);

    try {
      if (
        0 >= Number(id?.duration) ||
        "" === id?.widthdrawFee ||
        "" === id?.depositFee ||
        0 >= Number(id?.rewardperBlock)
      ) {
        toast.error("Missing Argument !");
        return;
      }
      const duration = id.duration;
      setharvestLoader2(true);
      const contract = getContract(
        stakingConAddress2,
        stakingABI2,
        library,
        account
      );
      const erc20address = await contract.updateLockup(
        0,
        duration,
        id.depositFee * 100,
        id.widthdrawFee * 100,
        web3.utils.toWei(id.rewardperBlock.toString())
        // id.rewardperBlock
      );
      console.log(erc20address);
      await erc20address.wait();
      toast.success("Lockup Update successfully ");
      setharvestLoader2(false);
    } catch (error) {
      setharvestLoader2(false);
      toast.error(error.message);
      console.log(error);
    }
  };

  const updateLockup3 = async (id) => {
    if (
      0 >= Number(id?.duration) ||
      "" === id?.widthdrawFee ||
      "" === id?.depositFee ||
      0 >= Number(id?.rewardperBlock)
    ) {
      toast.error("Missing Argument !");
      return;
    }
    try {
      const duration = id.duration;
      setharvestLoader3(true);
      const contract = getContract(
        stakingConAddress2,
        stakingABI2,
        library,
        account
      );
      const erc20address = await contract.updateLockup(
        1,
        duration,
        id.depositFee * 100,
        id.widthdrawFee * 100,
        web3.utils.toWei(id.rewardperBlock.toString())
        // id.rewardperBlock
      );
      // console.log(erc20address);
      await erc20address.wait();
      toast.success("Lockup Update successfully ");
      setharvestLoader3(false);
    } catch (error) {
      setharvestLoader3(false);
      toast.error(error.message);
      console.log(error);
    }
  };

  const updateLockup4 = async (id) => {
    if (
      0 >= Number(id?.duration) ||
      "" === id?.widthdrawFee ||
      "" === id?.depositFee ||
      0 >= Number(id?.rewardperBlock)
    ) {
      toast.error("Missing Argument !");
      return;
    }
    try {
      const duration = id.duration;
      setharvestLoader4(true);
      const contract = getContract(
        stakingConAddress2,
        stakingABI2,
        library,
        account
      );
      const erc20address = await contract.updateLockup(
        0,
        duration,
        id.depositFee * 100,
        id.widthdrawFee * 100,
        web3.utils.toWei(id.rewardperBlock.toString())
        // id.rewardperBlock
      );
      // console.log(erc20address);
      await erc20address.wait();
      toast.success("Lockup Update successfully ");
      setharvestLoader4(false);
    } catch (error) {
      setharvestLoader4(false);
      toast.error(error.message);
      console.log(error);
    }
  };

  const updateWalletA = async (id) => {
    const isValidAddress = await web3.utils.isAddress(id);
    console.log(isValidAddress, "_________*********");
    if (!isValidAddress) {
      toast.error("Enter a valid address");
      return;
    }
    try {
      setupdateWalletALoader(true);
      const contract = getContract(
        stakingConAddress1,
        stakingABI1,
        library,
        account
      );

      console.log("contract", contract);

      const Withdrawing = await contract.updateWalletA(id);
      await Withdrawing.wait();
      toast.success(" WalletA successfully ");
      setupdateWalletALoader(false);
    } catch (error) {
      toast.error(error.message);
      setupdateWalletALoader(false);
      console.log(error);
    }
    // } else {
    //   toast.error("Reward Per Block Updated more than staked !");
    // }
  };
  const updateWalletA2 = async (id) => {
    const isValidAddress = await web3.utils.isAddress(id);
    console.log(isValidAddress, "_________*********");
    if (!isValidAddress) {
      toast.error("Enter a valid address");
      return;
    }
    try {
      setupdateWalletALoader2(true);
      const contract = getContract(
        stakingConAddress2,
        stakingABI2,
        library,
        account
      );

      console.log("contract", contract);

      const erc20address = await contract.updateWalletA(id);
      // console.log(erc20address);
      await erc20address.wait();
      toast.success(" WalletA successfully ");
      setupdateWalletALoader2(false);
    } catch (error) {
      toast.error(error.message);
      setupdateWalletALoader2(false);
      console.log(error);
    }
  };

  const updateWalletA3 = async (id) => {
    const isValidAddress = await web3.utils.isAddress(id);
    console.log(isValidAddress, "_________*********");
    if (!isValidAddress) {
      toast.error("Enter a valid address");
      return;
    }
    try {
      setupdateWalletALoader3(true);
      const contract = getContract(
        stakingConAddress3,
        stakingABI3,
        library,
        account
      );

      console.log("contract", contract);

      const erc20address = await contract.updateWalletA(id);
      // console.log(erc20address);
      await erc20address.wait();
      toast.success("updateWalletA successfully completed");
      setupdateWalletALoader3(false);
    } catch (error) {
      toast.error(error.message);
      setupdateWalletALoader3(false);
      console.log(error);
    }
  };

  const updateWalletA4 = async (id) => {
    const isValidAddress = await web3.utils.isAddress(id);
    console.log(isValidAddress, "_________*********");
    if (!isValidAddress) {
      toast.error("Enter a valid address");
      return;
    }
    try {
      setupdateWalletALoader4(true);
      const contract = getContract(
        stakingConAddress4,
        stakingABI4,
        library,
        account
      );

      // console.log("contract", contract);

      const erc20address = await contract.updateWalletA(id);
      await erc20address.wait();
      toast.success(" WalletA successfully ");
      setupdateWalletALoader4(false);
    } catch (error) {
      toast.error(error.message);
      setupdateWalletALoader4(false);
      console.log(error);
    }
  };
  return (
    <Box className={classes.mainBox}>
      <Box pt={2} className={classes.heading}>
        <Typography variant="h1"> Admin Control</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item lg={3} sm={6} md={6} xs={12}>
          <FormsCards
            updateLockup={""}
            updatePoolLimit={updatePoolLimit}
            updateWalletA={updateWalletA}
            updateWalletALoader={updateWalletALoader}
            harvestLoader={harvestLoader}
            harvest1={harvest}
            stakingConAddress={stakingConAddress1}
            UpdateRewardPerBlock={UpdateRewardPerBlock}
            buttonLoader={buttonLoader}
            totalStaked={totalStaked}
            reflected={reflected1}
            earned={earned1}
            rewardPerBlock={rewardPerBlock}
            depositFee={depositFee}
            widthdrawFee={widthdrawFee}
            stakeName={stakeName}
            getOwner={getOwner}
            account={account}
            user={""}
            type={"NotLockup"}
            data={""}
          />
        </Grid>
        <Grid item lg={3} sm={6} md={6} xs={12}>
          <FormsCards
            updateLockup={updateLockup2}
            updatePoolLimit={updatePoolLimit2}
            updateWalletA={updateWalletA2}
            updateWalletALoader={updateWalletALoader2}
            harvestLoader={harvestLoader2}
            harvest1={harvest2}
            stakingConAddress={stakingConAddress2}
            UpdateRewardPerBlock={""}
            buttonLoader={buttonLoader}
            totalStaked={totalStaked1}
            reflected={reflected1}
            earned={earned1}
            rewardPerBlock={rewardPerBlock1}
            depositFee={depositFee1}
            widthdrawFee={widthdrawFee1}
            stakeName={stakeName2}
            getOwner={getOwner1}
            account={account}
            user={""}
            type={"Lockup"}
            data={""}
          />
        </Grid>
        <Grid item lg={3} sm={6} md={6} xs={12}>
          <FormsCards
            updateLockup={updateLockup3}
            updatePoolLimit={updatePoolLimit3}
            updateWalletA={updateWalletA3}
            updateWalletALoader={updateWalletALoader3}
            harvestLoader={harvestLoader3}
            harvest1={harvest3}
            stakingConAddress={stakingConAddress3}
            UpdateRewardPerBlock={""}
            buttonLoader={buttonLoader}
            totalStaked={totalStaked3}
            reflected={reflected3}
            earned={earned3}
            rewardPerBlock={rewardPerBlock3}
            depositFee={depositFee3}
            widthdrawFee={widthdrawFee3}
            stakeName={stakeName3}
            getOwner={getOwner3}
            account={account}
            user={""}
            type={"Lockup"}
            data={""}
          />
        </Grid>
        <Grid item lg={3} sm={6} md={6} xs={12}>
          <FormsCards
            updateLockup={updateLockup4}
            updatePoolLimit={updatePoolLimit4}
            updateWalletA={updateWalletA4}
            updateWalletALoader={updateWalletALoader4}
            harvestLoader={harvestLoader4}
            harvest1={harvest4}
            stakingConAddress={stakingConAddress4}
            UpdateRewardPerBlock={""}
            buttonLoader={buttonLoader}
            totalStaked={totalStaked4}
            reflected={reflected4}
            earned={earned4}
            rewardPerBlock={rewardPerBlock4}
            depositFee={depositFee4}
            widthdrawFee={widthdrawFee4}
            stakeName={stakeName4}
            getOwner={getOwner4}
            account={account}
            user={""}
            type={"Lockup"}
            data={""}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
