import React, { createContext, useEffect, useState } from "react";
import { injected, SUPPORTED_WALLETS } from "../connectors";
import { useWeb3React } from "@web3-react/core";
import { ACTIVE_NETWORK, NetworkDetails } from "../constants/";
import { getContract } from "../utils";
import {
  stakingConAddress1,
  stakingConAddress2,
  stakingConAddress3,
  stakingConAddress4,
} from "../constants/index";
import stakingABI1 from "../abis/StakingABI1.json";
import stakingABI2 from "../abis/StakingABI2.json";
import stakingABI3 from "../abis/StakingABI3.json";
import stakingABI4 from "../abis/StakingABI4.json";
import axios from "axios";
import { toast } from "react-toastify";
// import apiConfig from "../config/ApiConfig";
import Web3 from "web3";
import Web3Modal from "web3modal";

export const UserContext = createContext();
// const { activate, deactivate, account, library, chainId } = useWeb3React();
const setSession = async (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};

export default function AuthProvider(props) {
  const { activate, deactivate, account, library, chainId } = useWeb3React();
  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg] = useState("");
  const [successMSG, setSuccessMSG] = useState("");
  const [userData, setUserData] = useState();
  const [yourWalletBalance, setYourWalletBalance] = useState(0);
  const [adminAddress, setAdminAddress] = useState({
    contract1: "",
    contrsct2: "",
    contract3: "",
    contract4: "",
  });

  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        window.scrollTo(0, 0);
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.warn(error.message);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };

  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
    }
  };

  const GetOwnerWallet = async (id) => {
    try {
      const contract1 = getContract(
        stakingConAddress1,
        stakingABI1,
        library,
        account
      );
      const contract2 = getContract(
        stakingConAddress2,
        stakingABI2,
        library,
        account
      );

      const contract3 = getContract(
        stakingConAddress3,
        stakingABI3,
        library,
        account
      );
      const contract4 = getContract(
        stakingConAddress4,
        stakingABI4,
        library,
        account
      );
      const contractOwner1 = await contract1.owner();
      const contractOwner2 = await contract2.owner();
      const contractOwner3 = await contract3.owner();
      const contractOwner4 = await contract4.owner();

      setAdminAddress({
        contract1: contractOwner1,
        contrsct2: contractOwner2,
        contract3: contractOwner3,
        contract4: contractOwner4,
      });
    } catch (error) {
      // toast.error(error.message);
      // setharvestLoader(false);
      console.log(error);
    }
    // } else {
    //   toast.error("Reward Per Block Updated more than staked !");
    // }
  };
  useEffect(() => {
    GetOwnerWallet();
  }, [account]);

  let data = {
    isLogin,
    isLoading,
    errorMsg,
    successMSG,
    userData,
    yourWalletBalance,
    adminAddress,
    updateUser: (account) => {
      setSession(account);
    },

    connectWallet: (data) => connectToWallet(data),
    logoutHanlder: () => {
      setUserData();
      setIsLogin(false);
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("walletName");
      deactivate();
    },
  };

  const getUserbalce = async () => {
    var web3 = new Web3(library.provider);
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };
  useEffect(() => {
    if (account) {
      getUserbalce();
    }
  }, [account, library]);

  const connectToWallet = (data) => {
    if (data) {
      try {
        const connector = data.connector;

        if (connector && connector.walletConnectProvider?.wc?.uri) {
          connector.walletConnectProvider = undefined;
        }

        activate(connector, undefined, true).catch((error) => {
          if (error) {
            window.sessionStorage.removeItem("walletName");
            toast.error(JSON.stringify(error.message));
            window.sessionStorage.removeItem("walletName");
            activate(connector);
          }
        });
      } catch (error) {
        toast.error(JSON.stringify(error.message));
      }
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === window.sessionStorage.getItem("walletName")
      );
      if (selectectWalletDetails[0]) {
        connectToWallet(selectectWalletDetails[0].data);
      }
    }
  }, []);

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
