import React from "react";
import { makeStyles, Box, Typography, Paper } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  mainbox: {
    minHeight: "263px",
    background: "#262831",
    boxShadow: "7px 7px 4px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(42px)",
    borderRadius: "16px",
    padding: "20px",
    border: "1px solid #0938df26",
    // maxWidth: "295px",
    position: "relative",
    transition: "0.5s",
    "&:hover": {
      transform: "translateY(-10px)",
    },
    "& .imgbox": {
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
      "& img": {
        margin: "0 auto",
      },
    },
    "& .content": {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      "& h4": { color: "#EFAB00", padding: "10px" },
      "& p": { textAlign: "center", color: "#FFFFFF", fontSize: "15px" },
    },
  },
}));

function FeatureCard(props) {
  const classes = useStyles();
  const { data } = props;
  return (
    <>
      <Box className={classes.mainbox}>
        <Box className="imgbox">
          <img src={data.image} alt="Cardlogo" />
        </Box>
        <Box className="content">
          <Typography variant="h4">{data.name}</Typography>
          <Typography variant="body2">{data.discription}</Typography>
        </Box>
      </Box>
    </>
  );
}

export default FeatureCard;
