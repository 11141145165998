import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  TextField,
  CircularProgress,
  IconButton,
  InputAdornment,
  Hidden,
  Modal,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import { Link } from "react-router-dom";
import { MdTimer } from "react-icons/md";
// import { BiChevronDown } from "react-icons/bi";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
// import { GoVerified } from "react-icons/go";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import { HiOutlineExternalLink } from "react-icons/hi";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ConnectWallet from "../components/ConnectWalletPopUp/index";
import Tooltip from "@material-ui/core/Tooltip";
import { AiOutlineCalculator } from "react-icons/ai";
// import RefreshIcon from "@material-ui/icons/Refresh";
// import { Launch } from "@material-ui/icons";
// import CloseIcon from "@material-ui/icons/Close";
import RoiCalculator from "./RoiCalculator/RoiCalculator";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Web3 from "web3";
import { default_RPC_URL } from "../constants/index";
import { ExpandLess } from "@material-ui/icons";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   width: "100%",
  // },
  // heading: {
  //   fontSize: theme.typography.pxToRem(15),
  //   fontWeight: theme.typography.fontWeightRegular,
  // },
  mainBox: {
    borderRadius: "16px",
    minHeight: "93px",
    overflow: "hidden",
    position: "relative",
    transition: "0.5s",
    background: "#262831",
    boxShadow: "7px 7px 4px rgb(0 0 0 / 10%)",
    backdropFilter: "blur(42px)",
    "& .MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0px 0px",
    },
    // "& .MuiAccordionSummary-content.Mui-expanded": {
    //   margin: "0px 0px",
    // },
    "& .MuiAccordionDetails-root": {
      padding: "8px 16px",
    },
    "& label": {
      backgroundColor: "#E8424C",
      color: "#fff",
      padding: "2px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      fontSize: "9px",
      fontWeight: "800",
      width: "150px",
      fontFamily: "Roboto Slab",
      top: "14px",
      right: "-44px",
      transform: "rotate(40deg)",
    },
    "&:hover": {
      transform: "translateY(-10px)",
    },
  },
  nameBoxList: {
    display: "flex",
    // justifyContent: "space-between",
    // padding: "20px 20px 0px",
    padding: "0",
    alignItems: "center",
    whiteSpace: "nowrap",
    "& .userIcon": {
      position: "relative !important",
    },
    "& h4": {
      fontSize: "16px",
      fontWeight: "700",
      color: "#fff",
      marginBottom: "5px",
    },
    "& p": {
      fontSize: "12px",
      color: "#b5afaf",
    },
    "& img": {
      width: "50px",
      // height: "40px",
      borderRadius: "50%",
    },
  },
  nameBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 20px 0px",
    alignItems: "center",

    "& h4": {
      fontSize: "16px",
      fontWeight: "700",
      color: "#fff",
      marginBottom: "5px",
      textAlign: " right",
    },
    "& p": {
      fontSize: "12px",
      color: "#fff",
    },
    "& img": {
      width: "50px",
      // height: "40px",
      borderRadius: "50%",
    },
  },
  apyBox: {
    color: "#fff",
    padding: "20px",
    "& h4": {
      fontSize: "12px",
      fontWeight: "700",
      color: "#fff",
      "& small": {
        fontSize: "12px",
        fontWeight: "700",
        color: "#efab00",
      },
    },
    "& span": {
      display: "flex",
      fontSize: "12px",
      fontWeight: "700",
      color: "#fff",
      alignItems: "center",

      "& img": {
        margin: "0 5px",
        width: "17px",
      },
    },
    "& input": {
      background: " rgba(141, 137, 137, 0.2)",
      color: "#ffffff54",
      maxWidth: "100px",
      border: "none",
      width: "100%",
      height: "35px",
      paddingLeft: "10px",
      borderRadius: "7px",
      "&:focus-visible": {
        outline: "none",
      },
    },
  },
  apyBox1: {
    color: "#fff",
    // padding: "20px",
    "& h4": {
      fontSize: "12px",
      fontWeight: "700",
      color: "#fff",
      "& small": {
        fontSize: "12px",
        fontWeight: "700",
        color: "#efab00",
      },
    },
    "& span": {
      display: "flex",
      fontSize: "12px",
      fontWeight: "700",
      color: "#fff",
      alignItems: "center",

      "& img": {
        margin: "0 5px",
        width: "17px",
      },
    },
    "& input": {
      background: " rgba(141, 137, 137, 0.2)",
      color: "#ffffff54",
      maxWidth: "100px",
      border: "none",
      width: "100%",
      height: "35px",
      paddingLeft: "10px",
      borderRadius: "7px",
      "&:focus-visible": {
        outline: "none",
      },
    },
  },
  manualBtn: {
    border: "0.5px solid #FFFFFF",
    boxSizing: "border-box",
    borderRadius: "3px",
    fontSize: "14px",
    color: "#fff",
    "& svg": {
      color: "#FFFFFF",
    },
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  viewLink: {
    paddingTop: "10px",
    textDecoration: "underline",
    color: "#f6a52d",
    alignItems: "left",
    fontSize: "14px",
    "& link": {
      marginLeft: "2px",
    },
  },
  iconBox: {
    top: "-5px",
    left: "-5px",
    width: "20px !important",
    border: "1px solid #1d1e20",
    height: "20px",
    padding: "2px",
    position: "absolute",
    background: "#1d1e20",
    borderRadius: " 50% !important",
  },
  customWidth: {
    maxWidth: 230,
    zIndex: "1000",
  },

  marginRemove: {
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "64px",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0px 0px",
    },
    "& .MuiAccordionDetails-root": {
      display: "block",
      padding: "8px 16px",
    },
  },
  accordion: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "20px 20px ",
  },
  accordionSummary: {
    // background: "#f1faff",
    color: "#fff",
    padding: "0px",
    width: "100%",
    display: "block",
  },
  Dflex: {
    display: "flex",
    "& svg": {
      fontSize: "17px",
    },
  },
  maindialogbox: {
    "& .heading": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  compoundBtn: {
    backgroundColor: "#505255",
    padding: "0 10px",
    color: "#323232 !important",
    "&:hover": {
      backgroundColor: "#efab00",
    },
    "& span": {
      color: "#262626 !important",
      textTransform: "capitalize",
    },
  },
  btnBox: {
    paddingRight: "50px",
    "@media (max-width: 800px)": {
      paddingRight: "0px",
    },
  },
  modalContant: {
    padding: "20px",
  },
  loader: {
    position: "absolute",
    top: "50%",
    right: "50%",
    opacity: "4.5",
  },
}));
export default function TokenCard({
  pageView2,
  loader,
  stakeTokenAddress,
  rewardTokenAddress,
  bonusEndBlock,
  type,
  account,
  user,
  StakingHarvest,
  CompoundEarned,
  CompoundDivident,
  StakingDeposit,
  pendingReward,
  pendingDividends,
  StakingWidthdraw,
  stakeName,
  earned,
  reflected,
  APY,
  rewardPerBlockBigNumber,
  stakingConAddress,
  buttonLoader,
  stakeAmount,
  totalStaked1,
  depositFee,
  widthdrawFee,
  harvestclaimDividend,
  allowanceAmmount,
  EnableButtonMaxApprove,
  EnableButtonLoader,
  balanceOf,
  duration,
  latestBlock,
}) {
  const classes = useStyles();

  // console.log("stakeTokenAddress", APY);

  useEffect(() => {
    if (rewardTokenAddress !== "") {
      myBNBpriceUSD(rewardTokenAddress);
    }
  }, [rewardTokenAddress]);
  const [stakeTokenPrice, setstakeTokenPrice] = useState(0);
  const myBNBpriceUSD = async (stakeTokenAddressId) => {
    axios({
      method: "GET",
      url: `https://api.pancakeswap.info/api/v2/tokens/${stakeTokenAddressId}`,
    })
      .then(async (res) => {
        console.log("stakeTokenPrice", res);
        if (res.status === 200) {
          // console.log("stakingList", res.data.data.);
          setstakeTokenPrice(res.data.data.price);

          // setState(1);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    if (stakeTokenAddress !== "") {
      myRewardTokenPrice(stakeTokenAddress);
    }
  }, [stakeTokenAddress]);

  const [rewardTokenPrice, setrewardTokenPrice] = useState(0);
  const myRewardTokenPrice = async (rewardTokenAddressId) => {
    axios({
      method: "GET",
      url: `https://api.pancakeswap.info/api/v2/tokens/${rewardTokenAddressId}`,
    })
      .then(async (res) => {
        console.log("rewardTokenPrice", res);
        if (res.status === 200) {
          // console.log("stakingList", res.data.data.);
          setrewardTokenPrice(res.data.data.price);

          // setState(1);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const httpProvider = new Web3.providers.HttpProvider(default_RPC_URL);
  const web3 = new Web3(httpProvider);
  // console.log(
  //   "balanceOfbalanceOfbalanceOf---------308------------",
  //   web3.utils.fromWei(balanceOf.toString())
  // );

  const [open, setOpen] = useState(false);
  const [openId, setOpenId] = useState("");
  const [amount, setAmount] = useState("");
  const [expanded, setIsExpanded] = useState("");
  // console.log(amount);
  const handleClickOpen = (id) => {
    setOpenId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setAmount("");
    setOpen(false);
  };

  React.useEffect(() => {
    if (!buttonLoader) {
      setAmount("");
      setOpen(buttonLoader);
    }
  }, [buttonLoader]);

  const [openWalletConnect, setOpenWalletConnect] = React.useState(false);
  const [roiCalculator, setRoiCalculator] = useState(false);
  const HandleRoiCalculator = () => {
    setRoiCalculator(false);
  };

  function convertToInternationalCurrencySystem(labelValue) {
    // twelve Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e12
      ? (Math.abs(Number(labelValue)) / 1.0e12).toFixed(2) + "T"
      : // Nine Zeroes for Billions
      Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
      : Math.abs(Number(labelValue));
  }
  return (
    <Box className={classes.mainBox}>
      {openWalletConnect && (
        <ConnectWallet
          open={openWalletConnect}
          handleClose={() => setOpenWalletConnect(false)}
        />
      )}
      <Box className={classes.marginRemove} style={{ textDecoration: "none" }}>
        <Box className={classes.mainBoxShade}></Box>

        {pageView2 === "list" && (
          <>
            <Accordion>
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}

                onClick={() => setIsExpanded((prev) => !prev)}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionSummary}
              >
                <Box className={classes.accordion}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={3} md={4} sm={4}>
                      <Grid
                        container
                        spacing={2}
                        className={classes.nameBoxList}
                      >
                        <Box
                          className="userIcon"
                          style={{ position: "relative" }}
                        >
                          <img
                            src="images/gold.png"
                            alt=""
                            style={{ width: "18px" }}
                            className={classes.iconBox}
                          />
                          <img src="images/logo.png" alt="" />
                        </Box>

                        <Grid item lg={7} sm={6} md={7} xs={8}>
                          <Typography
                            variant="h4"
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            Earn {stakeName ? stakeName : "-- "}
                          </Typography>
                          <Typography variant="body1">
                            Stake {stakeName}
                          </Typography>
                          <Typography variant="body1">
                            Reflection {reflected}
                          </Typography>
                          {duration !== 0 && (
                            <Typography
                              variant="body1"
                              style={{ fontSize: "11px", color: "#efab00" }}
                            >
                              Lock Duration: {"  "}
                              {duration} Days
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Hidden xsDown>
                      <Grid item xs={4} lg={3} md={2} sm={3}>
                        <Typography variant="body1">
                          {" "}
                          {earned} EARNED{" "}
                        </Typography>

                        <Typography
                          variant="body2"
                          style={{
                            fontSize: "16px",
                            color: "#b5afaf",
                            fontWeight: 600,
                          }}
                        >
                          {" "}
                          {Number(pendingReward) === 0
                            ? 0.0
                            : convertToInternationalCurrencySystem(
                                parseFloat(pendingReward).toFixed(8)
                              )}{" "}
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{
                            fontSize: "16px",
                            color: "#b5afaf",
                            fontWeight: 600,
                          }}
                        >
                          {" "}
                          {/* {"0 "} BUSD */}
                        </Typography>
                      </Grid>

                      <Grid item xs={5} lg={2} md={1} sm={2}>
                        <Tooltip
                          classes={{ tooltip: classes.customWidth }}
                          title="This pool’s rewards aren’t compounded automatically, so we show APY"
                        >
                          <Typography
                            variant="body1"
                            style={{ fontSize: "11px" }}
                          >
                            APR
                          </Typography>
                        </Tooltip>
                        <Typography
                          variant="body2"
                          style={{
                            fontSize: "16px",
                            color: "#b5afaf",
                            fontWeight: 600,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {Math.round(APY)}%{" "}
                          <span style={{ color: "#fff", padding: " 0 0 0 0" }}>
                            <AiOutlineCalculator
                              onClick={(event) => {
                                event.stopPropagation();
                                setRoiCalculator(true);
                              }}
                            />
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={5} lg={3} md={3} sm={3}>
                        <Typography variant="body1">Total Staked </Typography>
                        <Typography variant="body1" style={{}}>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "#b5afaf",
                            }}
                          >
                            {" "}
                            <img
                              src="images/logo.png"
                              alt="BNB"
                              style={{ marginRight: "5px", maxWidth: "20px" }}
                            />
                            {convertToInternationalCurrencySystem(
                              Number(totalStaked1) === 0
                                ? 0.0
                                : parseFloat(totalStaked1)
                            )}{" "}
                            {stakeName}
                          </span>
                        </Typography>
                      </Grid>
                    </Hidden>
                    <Hidden smDown>
                      <Grid
                        item
                        xs={6}
                        lg={1}
                        md={2}
                        sm={4}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        {expanded ? (
                          <>
                            Hide <ExpandLess />
                          </>
                        ) : (
                          <>
                            Details
                            <ExpandMoreIcon />
                          </>
                        )}
                      </Grid>{" "}
                    </Hidden>
                  </Grid>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.apyBox1}>
                  <Hidden smDown>
                    <Typography variant="h6">
                      End In :
                      {/* <a
                      href={`https://bscscan.com/block/countdown/${
                        bonusEndBlock + latestBlock
                      }`}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    > */}
                      <small
                        style={{ color: "#aea8a9" }}
                        // href={`https://bscscan.com/block/countdown/${24168944}`}
                      >
                        {bonusEndBlock} blocks{" "}
                        <MdTimer
                          style={{
                            fontSize: "13px",
                            color: "#efab00",
                            // fontWeight: 600,
                          }}
                        />
                      </small>{" "}
                      {/* </a>{" "} */}
                      {/* <span
                      style={{
                        fontSize: "13px",
                        color: "#efab00",
                        // fontWeight: 600,
                      }}
                    > */}
                      {/* </span>{" "} */}
                    </Typography>
                  </Hidden>
                  <Grid container spacing={1} alignItems="center">
                    <Hidden smUp>
                      {" "}
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          {" "}
                          {earned} EARNED{" "}
                        </Typography>

                        {/* <Typography
                          variant="body2"
                          style={{
                            fontSize: "16px",
                            color: "#b5afaf",
                            fontWeight: 600,
                          }}
                        >
                          {" "}
                          {Number(pendingReward) === 0
                            ? 0.0
                            : parseFloat(pendingReward).toFixed(8)}{" "}
                        </Typography> */}
                      </Grid>
                      <Grid item xs={6}>
                        {/* <Typography variant="body1">
                          {" "}
                          {earned} EARNED{" "}
                        </Typography> */}

                        <Typography
                          variant="body2"
                          style={{
                            fontSize: "16px",
                            color: "#b5afaf",
                            fontWeight: 600,
                          }}
                        >
                          {" "}
                          {Number(pendingReward) === 0
                            ? 0.0
                            : convertToInternationalCurrencySystem(
                                parseFloat(pendingReward).toFixed(8)
                              )}{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">Total Staked </Typography>
                        {/* <Typography variant="body1" style={{}}>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "#b5afaf",
                            }}
                          >
                            {" "}
                            <img
                              src="images/logo.png"
                              alt="BNB"
                              style={{ marginRight: "5px" }}
                            />
                            {Number(totalStaked1) === 0
                              ? 0.0
                              : parseFloat(totalStaked1).toFixed(2)}{" "}
                            BNB
                          </span>
                        </Typography> */}
                      </Grid>
                      <Grid item xs={6}>
                        {/* <Typography variant="body1">Total Staked </Typography> */}
                        <Typography variant="body1" style={{}}>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "#b5afaf",
                            }}
                          >
                            {" "}
                            <img
                              src="images/logo.png"
                              alt="BNB"
                              style={{ marginRight: "5px", maxWidth: "20px" }}
                            />
                            {convertToInternationalCurrencySystem(
                              Number(totalStaked1) === 0
                                ? 0.0
                                : parseFloat(totalStaked1)
                            )}{" "}
                            {stakeName}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        style={{ textAlign: "start", width: "100%" }}
                        item
                        xs={6}
                      >
                        <Tooltip
                          classes={{ tooltip: classes.customWidth }}
                          title="This pool’s rewards aren’t compounded automatically, so we show APY"
                        >
                          <Typography
                            variant="body1"
                            style={{ fontSize: "11px" }}
                          >
                            APR
                          </Typography>
                        </Tooltip>
                        {/* <Typography
                          variant="body2"
                          style={{
                            fontSize: "16px",
                            color: "#b5afaf",
                            fontWeight: 600,
                          }}
                        >
                          {Math.round(APY)}%{" "}
                          <span
                            style={{ color: "#fff", padding: " 0 0 0 25px" }}
                          >
                          
                          </span>
                        </Typography> */}
                      </Grid>
                      <Grid
                        style={{ textAlign: "end", width: "100%" }}
                        item
                        xs={6}
                      >
                        {/* <Tooltip
                          classes={{ tooltip: classes.customWidth }}
                          title="This pool’s rewards aren’t compounded automatically, so we show APY"
                        >
                          <Typography
                            variant="body1"
                            style={{ fontSize: "11px" }}
                          >
                            APR
                          </Typography>
                        </Tooltip> */}
                        <Typography
                          variant="body2"
                          style={{
                            fontSize: "16px",
                            color: "#b5afaf",
                            fontWeight: 600,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {Math.round(APY)}%{" "}
                          <span
                            style={{ color: "#fff", padding: " 0 0 0 25px" }}
                          >
                            {/* <AiOutlineCalculator
                          // onClick={() => { setRoiCalculator(true) }}
                          /> */}
                          </span>
                        </Typography>
                      </Grid>
                    </Hidden>
                    <Grid item lg={4} md={3} xs={12}>
                      {/* <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                          <Box className={classes.Dflex}>
                            <Typography variant="h4">See Token Info</Typography>
                            <Launch />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box className={classes.Dflex}>
                            <Typography variant="body1">
                              View Project Site
                            </Typography>
                            <Launch />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            Add Tto Metamask
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Box className={classes.Dflex}>
                            <RefreshIcon />
                            <Typography variant="body1"> Manual</Typography>
                          </Box>
                        </Grid>
                      </Grid> */}
                    </Grid>
                    <Grid item lg={4} md={5} xs={12}>
                      <Box className={classes.btnBox}>
                        <Grid container alignItems="center">
                          <Grid item xs={12}>
                            <Typography variant="h4">
                              {" "}
                              <small> {earned}</small> EARNED{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography
                              variant="body1"
                              style={{ fontSize: "16px", color: "#b5afaf" }}
                            >
                              {" "}
                              {Number(pendingReward) === 0
                                ? 0.0
                                : convertToInternationalCurrencySystem(
                                    parseFloat(pendingReward).toFixed(8)
                                  )}{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={7} align="right">
                            <Button
                              className={classes.compoundBtn}
                              onClick={() => CompoundEarned()}
                              disabled={
                                Number(pendingReward) === 0
                                  ? true
                                  : false || loader
                              }
                            >
                              Compound
                            </Button>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography variant="body1">
                              {/* 0 USD */}
                            </Typography>
                          </Grid>
                          {type === "manual" && (
                            <Grid item xs={7} align="right">
                              <Button
                                onClick={() => StakingHarvest()}
                                className={classes.compoundBtn}
                                disabled={
                                  Number(pendingReward) === 0
                                    ? true
                                    : false || loader
                                }
                              >
                                Harvest
                              </Button>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Typography variant="h4">
                              {" "}
                              <small> {reflected}</small> REFLECTED{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography
                              variant="body1"
                              style={{ fontSize: "16px", color: "#b5afaf" }}
                            >
                              {" "}
                              {Number(pendingDividends) === 0
                                ? 0.0
                                : convertToInternationalCurrencySystem(
                                    parseFloat(pendingDividends).toFixed(8)
                                  )}
                            </Typography>
                          </Grid>
                          <Grid item xs={7} align="right">
                            <Button
                              className={classes.compoundBtn}
                              onClick={() => CompoundDivident()}
                              disabled={
                                Number(pendingDividends) === 0
                                  ? true
                                  : false || loader
                              }
                            >
                              Compound
                            </Button>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography variant="body1">
                              {/* 0 USD */}
                            </Typography>
                          </Grid>
                          <Grid item xs={7} align="right">
                            <Button
                              className={classes.compoundBtn}
                              onClick={() => harvestclaimDividend()}
                              disabled={
                                Number(pendingDividends) === 0
                                  ? true
                                  : false || loader
                              }
                            >
                              Harvest
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Grid container alignItems="center">
                        {duration !== 0 && (
                          <Grid item xs={7}>
                            <Typography
                              variant="h4"
                              style={{ fontSize: "11px", color: "#efab00" }}
                            >
                              Lock Duration:
                            </Typography>
                          </Grid>
                        )}

                        {duration !== 0 && (
                          <Grid item xs={5} align="right">
                            {/* <input type="text" /> */}
                            <Typography
                              variant="body2"
                              style={{ fontSize: "16px", color: "#b5afaf" }}
                            >
                              {duration} Days
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={7}>
                          <Typography
                            variant="h4"
                            style={{ fontSize: "11px", color: "#efab00" }}
                          >
                            Deposit Fee:
                          </Typography>
                        </Grid>
                        <Grid item xs={5} align="right">
                          {/* <input type="text" /> */}
                          <Typography
                            variant="body2"
                            style={{ fontSize: "13px", color: "#b5afaf" }}
                          >
                            {depositFee} %
                          </Typography>
                        </Grid>

                        <Grid item xs={7}>
                          <Typography
                            variant="h4"
                            style={{ fontSize: "11px", color: "#efab00" }}
                          >
                            Widthdraw Fee:
                          </Typography>
                        </Grid>

                        <Grid item xs={5} align="right">
                          {/* <input type="text" /> */}
                          <Typography
                            variant="body2"
                            style={{ fontSize: "13px", color: "#b5afaf" }}
                          >
                            {widthdrawFee} %
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          // style={{ borderBottom: "1px solid #8080808c" }}
                        >
                          {account ? (
                            <>
                              {allowanceAmmount === "0" ? (
                                <Grid container spacing={1}>
                                  <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box mt={3}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={EnableButtonLoader || loader}
                                        onClick={() => EnableButtonMaxApprove()}
                                        //   onClick={StakingDeposit}
                                        fullWidth
                                        style={{ marginTop: "20px" }}
                                      >
                                        <span
                                          style={{
                                            paddingRight: "13px",
                                          }}
                                        >
                                          Enable
                                        </span>

                                        {EnableButtonLoader && (
                                          <CircularProgress
                                            style={{
                                              width: "32px",
                                              height: "32px",
                                              color: "#efab00",
                                            }}
                                          />
                                        )}
                                      </Button>
                                    </Box>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Grid container spacing={1}>
                                  <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Box>
                                      <Typography variant="body1">
                                        {stakeAmount === "0"
                                          ? 0
                                          : parseFloat(stakeAmount).toFixed(4)}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item lg={6} md={6} sm={6} xs={6}>
                                    {stakeAmount === "0" ? (
                                      <Grid container spacing={1}>
                                        <Grid
                                          item
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          xs={12}
                                        >
                                          <Box
                                            mt={3}
                                            style={{
                                              width: "100%",
                                              textAlign: "end",
                                            }}
                                          >
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              onClick={() =>
                                                handleClickOpen("Deposit")
                                              }
                                              disabled={loader}
                                              //   onClick={StakingDeposit}
                                              fullWidth
                                              style={{ marginTop: "20px" }}
                                            >
                                              Stake
                                            </Button>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    ) : (
                                      <Grid container spacing={1}>
                                        <Grid
                                          item
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          xs={12}
                                        >
                                          <Box
                                            mt={3}
                                            style={{
                                              width: "100%",
                                              textAlign: "end",
                                            }}
                                          >
                                            <RemoveIcon
                                              style={
                                                loader
                                                  ? {
                                                      // cursor: "pointer",
                                                      border:
                                                        "1px solid #262626",
                                                      borderRadius: "11px",
                                                      background: "#505255",
                                                      color: "#262626",
                                                      width: "35px",
                                                      height: "35px",
                                                    }
                                                  : {
                                                      cursor: "pointer",
                                                      border:
                                                        "1px solid #F6A52D",
                                                      borderRadius: "11px",
                                                      background: "#262831",
                                                      color: "#f6a52d",
                                                      width: "35px",
                                                      height: "35px",
                                                    }
                                              }
                                              // style={{
                                              //   cursor: "pointer",
                                              //   border: "1px solid #F6A52D",
                                              //   borderRadius: "11px",
                                              //   background: "#262831",
                                              //   color: "#f6a52d",
                                              //   width: "35px",
                                              //   height: "35px",
                                              // }}
                                              disabled={loader}
                                              onClick={() => {
                                                if (!loader) {
                                                  handleClickOpen("Widthdraw");
                                                }
                                              }}
                                            />

                                            <AddIcon
                                              style={
                                                loader
                                                  ? {
                                                      // cursor: "pointer",
                                                      border:
                                                        "1px solid #262626",
                                                      borderRadius: "11px",
                                                      background: "#505255",
                                                      color: "#262626",
                                                      width: "35px",
                                                      height: "35px",
                                                    }
                                                  : {
                                                      cursor: "pointer",
                                                      border:
                                                        "1px solid #F6A52D",
                                                      borderRadius: "11px",
                                                      background: "#262831",
                                                      color: "#f6a52d",
                                                      width: "35px",
                                                      height: "35px",
                                                    }
                                              }
                                              disabled={loader}
                                              onClick={() => {
                                                if (!loader) {
                                                  handleClickOpen("Deposit");
                                                }
                                              }}
                                            />
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                              )}
                            </>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginTop: "20px" }}
                              fullWidth
                              onClick={() => setOpenWalletConnect(true)}
                            >
                              Connect wallet
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} align="left">
                    <Box align="left">
                      <Hidden smUp>
                        <Typography variant="body1">
                          End In :
                          {/* <a
                      href={`https://bscscan.com/block/countdown/${
                        bonusEndBlock + latestBlock
                      }`}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    > */}
                          <small
                            style={{
                              fontSize: "13px",
                              color: "#aea8a9",
                            }}
                            // href={`https://bscscan.com/block/countdown/${24168944}`}
                          >
                            {bonusEndBlock} blocks{" "}
                            <MdTimer
                              style={{
                                fontSize: "13px",
                                color: "#efab00",
                                // fontWeight: 600,
                              }}
                            />
                          </small>{" "}
                          {/* </a>{" "} */}
                          {/* <span
                      style={{
                        fontSize: "13px",
                        color: "#efab00",
                        // fontWeight: 600,
                      }}
                    > */}
                          {/* </span>{" "} */}
                        </Typography>
                      </Hidden>{" "}
                      <Link
                        href={`https://bscscan\.com/address/${stakingConAddress}`}
                        // href="https://bscscan.com/"
                        target={"_blank"}
                        className={classes.viewLink}
                      >
                        View Contract{" "}
                      </Link>
                    </Box>
                  </Grid>
                </Box>{" "}
              </AccordionDetails>
            </Accordion>
          </>
        )}
        {pageView2 !== "list" && (
          <>
            <Box className={classes.nameBox}>
              <Box className="userIcon" style={{ position: "relative" }}>
                <img
                  src="images/gold.png"
                  alt=""
                  style={{ width: "18px" }}
                  className={classes.iconBox}
                />
                <img src="images/logo.png" alt="" />
              </Box>

              {/* <img src="images/logo.png" alt="" />
              <Box className={classes.iconBox}>
                <img src="images/gold.png" alt="" style={{ width: "18px" }} />
              </Box> */}
              <Box>
                <Typography variant="h4">
                  Earn {stakeName ? stakeName : "MetaReflect"}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "12px",
                    color: "#b5afaf",
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                  }}
                >
                  Stake {stakeName}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "12px",
                    color: "#b5afaf",
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                  }}
                >
                  Reflection {reflected}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.apyBox}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={5}>
                  {/* This pool’s rewards aren’t compounded automatically, so we show APR */}
                  <Tooltip
                    classes={{ tooltip: classes.customWidth }}
                    title="This pool’s rewards aren’t compounded automatically, so we show APY"
                  >
                    <Typography variant="h4">APR :</Typography>
                  </Tooltip>
                </Grid>
                <Grid item xs={7} align="right">
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "16px",
                      color: "#b5afaf",
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {Math.round(APY)}%
                    <IconButton>
                      <AiOutlineCalculator
                        onClick={() => {
                          setRoiCalculator(true);
                        }}
                      />
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>

              {account && (
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="h4">
                      {" "}
                      <small> {earned}</small> EARNED{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "16px",
                        color: "#b5afaf",
                        fontWeight: 600,
                      }}
                    >
                      {" "}
                      {Number(pendingReward) === 0
                        ? 0.0
                        : convertToInternationalCurrencySystem(
                            parseFloat(pendingReward).toFixed(8)
                          )}
                    </Typography>
                  </Grid>
                  <Grid item xs={7} align="right">
                    <Button
                      className={classes.compoundBtn}
                      onClick={() => CompoundEarned()}
                      disabled={
                        Number(pendingReward) === 0 ? true : false || loader
                      }
                    >
                      Compound
                    </Button>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body1">{/* 0 USD */}</Typography>
                  </Grid>
                  <Grid item xs={7} align="right">
                    <Box style={{ height: "25px" }}>
                      {type === "manual" && (
                        <Button
                          className={classes.compoundBtn}
                          onClick={() => StakingHarvest()}
                          disabled={
                            Number(pendingReward) === 0 ? true : false || loader
                          }
                        >
                          Harvest
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h4">
                      {" "}
                      <small> {reflected}</small> REFLECTED{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "16px",
                        color: "#b5afaf",
                        fontWeight: 600,
                      }}
                    >
                      {" "}
                      {Number(pendingDividends) === 0
                        ? 0.0
                        : convertToInternationalCurrencySystem(
                            parseFloat(pendingDividends).toFixed(8)
                          )}
                    </Typography>
                  </Grid>
                  <Grid item xs={7} align="right">
                    <Button
                      className={classes.compoundBtn}
                      onClick={() => CompoundDivident()}
                      disabled={
                        Number(pendingDividends) === 0 ? true : false || loader
                      }
                    >
                      Compound
                    </Button>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body1">{/* 0 USD */}</Typography>
                  </Grid>
                  <Grid item xs={7} align="right">
                    <Button
                      className={classes.compoundBtn}
                      onClick={() => harvestclaimDividend()}
                      disabled={
                        Number(pendingDividends) === 0 ? true : false || loader
                      }
                    >
                      Harvest
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={7}>
                  <Typography
                    variant="h4"
                    style={{ color: "#FFFFFF", fontSize: "13px" }}
                  >
                    Deposit Fee:
                  </Typography>
                </Grid>
                <Grid item xs={5} align="right">
                  {/* <input type="text" /> */}
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: "13px",
                      color: "#b5afaf",
                      fontWeight: 600,
                    }}
                  >
                    {depositFee} %
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography
                    variant="h4"
                    style={{ color: "#FFFFFF", fontSize: "13px" }}
                  >
                    Widthdraw Fee:
                  </Typography>
                </Grid>

                <Grid item xs={5} align="right">
                  {/* <input type="text" /> */}
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: "13px",
                      color: "#b5afaf",
                      fontWeight: 600,
                    }}
                  >
                    {widthdrawFee} %
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ borderBottom: "1px solid #8080808c" }}
                >
                  <Box mb={2} mt={1}>
                    {account ? (
                      <>
                        {allowanceAmmount === "0" ? (
                          <Grid container spacing={1}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Box mt={3}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  // disabled={loader}
                                  disabled={EnableButtonLoader || loader}
                                  onClick={() => EnableButtonMaxApprove()}
                                  //   onClick={StakingDeposit}
                                  fullWidth
                                  style={{ marginTop: "20px" }}
                                >
                                  <span
                                    style={{
                                      paddingRight: "13px",
                                    }}
                                  >
                                    Enable
                                  </span>

                                  {EnableButtonLoader && (
                                    <CircularProgress
                                      style={{
                                        width: "32px",
                                        height: "32px",
                                        color: "#efab00",
                                      }}
                                    />
                                  )}
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container spacing={1}>
                            <Grid item lg={6} md={6} sm={6} xs={5}>
                              <Box>
                                <Typography variant="body1">
                                  {stakeAmount === "0"
                                    ? 0
                                    : parseFloat(stakeAmount).toFixed(4)}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={7}>
                              {stakeAmount === "0" ? (
                                <Grid container spacing={1}>
                                  <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box
                                      mt={3}
                                      style={{
                                        width: "100%",
                                        textAlign: "end",
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                          handleClickOpen("Deposit")
                                        }
                                        disabled={loader}
                                        //   onClick={StakingDeposit}
                                        fullWidth
                                        style={{ marginTop: "20px" }}
                                      >
                                        Stake
                                      </Button>
                                    </Box>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Grid container spacing={1}>
                                  <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box
                                      mt={3}
                                      style={{
                                        width: "100%",
                                        textAlign: "end",
                                      }}
                                    >
                                      <RemoveIcon
                                        style={
                                          loader
                                            ? {
                                                // cursor: "pointer",
                                                border: "1px solid #262626",
                                                borderRadius: "11px",
                                                background: "#505255",
                                                color: "#262626",
                                                width: "35px",
                                                height: "35px",
                                              }
                                            : {
                                                cursor: "pointer",
                                                border: "1px solid #F6A52D",
                                                borderRadius: "11px",
                                                background: "#262831",
                                                color: "#f6a52d",
                                                width: "35px",
                                                height: "35px",
                                              }
                                        }
                                        disabled={loader}
                                        onClick={() => {
                                          if (!loader) {
                                            handleClickOpen("Widthdraw");
                                          }
                                        }}
                                      />

                                      <AddIcon
                                        style={
                                          loader
                                            ? {
                                                // cursor: "pointer",
                                                border: "1px solid #262626",
                                                borderRadius: "11px",
                                                background: "#505255",
                                                color: "#262626",
                                                width: "35px",
                                                height: "35px",
                                              }
                                            : {
                                                cursor: "pointer",
                                                border: "1px solid #F6A52D",
                                                borderRadius: "11px",
                                                background: "#262831",
                                                color: "#f6a52d",
                                                width: "35px",
                                                height: "35px",
                                              }
                                        }
                                        disabled={loader}
                                        onClick={() => {
                                          if (!loader) {
                                            handleClickOpen("Deposit");
                                          }
                                        }}
                                      />
                                      {/* </Button> */}
                                    </Box>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: "20px" }}
                        fullWidth
                        onClick={() => setOpenWalletConnect(true)}
                      >
                        Connect wallet
                      </Button>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} align="right">
                  <div className={classes.root}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            onClick={() => setIsExpanded((prev) => !prev)}
                            style={{ color: "#fff" }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h4">
                          {/* {!expanded ? "Details" : "Hide"} */}
                          Details
                        </Typography>
                        {/* <Typography className={classes.heading}>Accordion 1</Typography> */}
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: "0" }}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item xs={5} align="left">
                            <Typography variant="h4">Total Staked :</Typography>
                          </Grid>
                          <Grid item xs={7} align="right">
                            {/* <input type="text" /> */}
                            <span
                              style={{
                                fontSize: "13px",
                                color: "#b5afaf",
                                fontWeight: 600,

                                whiteSpace: "nowrap",
                              }}
                            >
                              {" "}
                              <img
                                src="images/logo.png"
                                alt="BNB"
                                style={{ maxWidth: "20px" }}
                              />
                              {convertToInternationalCurrencySystem(
                                Number(totalStaked1) === 0
                                  ? 0.0
                                  : parseFloat(totalStaked1)
                              )}{" "}
                              {stakeName}
                            </span>
                          </Grid>
                          <Grid item xs={5} align="left">
                            <Typography variant="h4"> End In </Typography>
                          </Grid>
                          <Grid item xs={7} align="right">
                            {/* <input type="text" /> */}
                            {/* <a
                              href={`https://bscscan.com/block/countdown/${
                                bonusEndBlock + latestBlock
                              }`}
                              target="_blank"
                            > */}{" "}
                            {/* <img src="images/logo.png" alt="BNB" /> */}
                            <span
                              style={{
                                fontSize: "13px",
                                color: "#b5afaf",
                                fontWeight: 600,
                              }}
                            >
                              {bonusEndBlock} blocks{" "}
                              <span
                                style={{
                                  fontSize: "13px",
                                  color: "#efab00",
                                  // fontWeight: 600,
                                }}
                              >
                                <MdTimer />
                              </span>{" "}
                            </span>
                            {/* </a> */}
                          </Grid>
                          {duration !== 0 && (
                            <Grid item xs={5} align="left">
                              <Typography
                                variant="h4"
                                style={{ color: "#FFFFFF", fontSize: "13px" }}
                              >
                                Lock Duration:
                              </Typography>
                            </Grid>
                          )}

                          {duration !== 0 && (
                            <Grid item xs={7} align="right">
                              <span
                                style={{
                                  fontSize: "13px",
                                  color: "#b5afaf",
                                  fontWeight: 600,

                                  whiteSpace: "nowrap",
                                }}
                              >
                                {duration} Days
                              </span>
                            </Grid>
                          )}
                          {/* <Grid item xs={7} align="left">
                            <Typography variant="h4">
                              Metareflects Earned :
                            </Typography>
                          </Grid>
                          <Grid item xs={5} align="right">
                            <span>
                              {" "}
                              <img src="images/logo.png" alt="BNB" /> 0.21 BNB
                            </span>
                          </Grid>
                          <Grid item xs={7} align="left">
                            <Typography variant="h4">BUSD Earned :</Typography>
                          </Grid>
                          <Grid item xs={5} align="right">
                            <span>
                              {" "}
                              <img src="images/logo.png" alt="BNB" /> 0.13 BUSD
                            </span>
                          </Grid> */}
                          <Grid item xs={12} align="left">
                            <Box align="left">
                              {" "}
                              <Link
                                href={`https://bscscan.com/address/${stakingConAddress}`}
                                // href="https://bscscan.com/"
                                target={"_blank"}
                                className={classes.viewLink}
                              >
                                View Contract{" "}
                              </Link>
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Grid>
              </Grid>
            </Box>{" "}
          </>
        )}
      </Box>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <span style={{ color: "gray" }}> {`Enter ${openId} Amount?`}</span>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
          <Box className={classes.modalContant}>
            <Typography
              variant="h6"
              align="right"
              style={{ color: "#efab00", fontSize: " 16px" }}
            >
              {openId === "Widthdraw" ? (
                <>{stakeAmount} Token Available</>
              ) : (
                <>{web3.utils.fromWei(balanceOf.toString())} Token Available</>
              )}
            </Typography>
            <TextField
              disabled={buttonLoader}
              onKeyPress={(event) => {
                if (event?.key === "-" || event?.key === "+") {
                  event.preventDefault();
                }
              }}
              variant="outlined"
              onChange={(e) => setAmount(e.target.value)}
              fullWidth
              value={amount}
              placeholder="0"
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {openId === "Widthdraw" ? (
                      <Button
                        disabled={buttonLoader}
                        style={{
                          backgroundColor: "#676666",
                          padding: 0,
                          color: "#d6d1d1",
                        }}
                        onClick={() => setAmount(stakeAmount)}
                      >
                        MAX
                      </Button>
                    ) : (
                      <Button
                        disabled={buttonLoader}
                        style={{
                          backgroundColor: "#676666",
                          padding: 0,
                          color: "#d6d1d1",
                        }}
                        onClick={() =>
                          setAmount(web3.utils.fromWei(balanceOf.toString()))
                        }
                      >
                        MAX
                      </Button>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            style={{ color: "#ccc" }}
            disabled={buttonLoader}
          >
            Cancel
          </Button>
          {openId !== "Widthdraw" ? (
            <Button
              onClick={() => StakingDeposit(amount)}
              autoFocus
              style={{ color: "#efab00" }}
              disabled={buttonLoader}
            >
              {/* Confirm {buttonLoader && <CircularProgress />} */}
              <span
                style={{
                  paddingRight: "13px",
                }}
              >
                Confirm
              </span>

              {buttonLoader && (
                <CircularProgress
                  style={{
                    width: "32px",
                    height: "32px",
                    color: "#efab00",
                  }}
                />
              )}
            </Button>
          ) : (
            <Button
              onClick={() => StakingWidthdraw(amount)}
              color="primary"
              style={{ color: "#efab00" }}
              autoFocus
              disabled={buttonLoader}
            >
              <span
                style={{
                  paddingRight: "13px",
                }}
              >
                Confirm
              </span>

              {buttonLoader && (
                <CircularProgress
                  style={{
                    width: "32px",
                    height: "32px",
                    color: "#efab00",
                  }}
                />
              )}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {APY && (
        <Dialog open={roiCalculator} onClose={() => setRoiCalculator(false)}>
          <DialogContent>
            <RoiCalculator
              rewardTokenPrice={rewardTokenPrice}
              stakeTokenPrice={stakeTokenPrice}
              RoiCalculator={() => HandleRoiCalculator()}
              balanceOf={balanceOf}
              stakeName={stakeName}
              APY={rewardPerBlockBigNumber}
              APYpercentage={APY}
              earningTokenSymbol={earned}
            />
          </DialogContent>
        </Dialog>
      )}

      {/* modal */}
      {/* {loader && (
        <Dialog open={loader}>
          {" "}
          <DialogContent>
            <div
            // className={classes.loader}
            >
              <Typography variant="h6">
                Loading....{loader && <CircularProgress />}
              </Typography>
            </div>
          </DialogContent>
        </Dialog>
      )} */}
    </Box>
  );
}
