import React from "react";
import {
    Box,
    Container,
    Typography,
    makeStyles,
    Button,
    Grid,
} from "@material-ui/core";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    mainBox: {
  
        "& h1": {
            fontSize: "30px",
            fontWeight: "600",
            textDecoration: "underline",
            color: "#fff",
            marginBottom: "25px",
            [theme.breakpoints.down("md")]: {
                fontSize: "20px",
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: "20px",
            },
        },
    },
    dexBox: {
        width:"100%",
        // maxWidth:"450px",
        // margin:"auto",
        [theme.breakpoints.down("xs")]:{
            maxWidth:"100%", 
        },
        "& span": {
            color: "#000 !important",
            fontSize:"15px !important",
        },
        "& h3": {
            color: "#EFAB00",
            paddingBottom: "16px",
        }
    },
}));

export default function BestSeller() {
    const classes = useStyles();
    const options = {
        series: [{
            name: "Desktops",
            data: [10, 41, 35, 51, 61]
        }],
        options: {
          chart: {
            height: 350,
            foreColor: '#fff',
            type: 'line',
            zoom: {
              enabled: false
            },

            categories: {
                color: "#fff",
                fontSize:"15px !important",
            },
          },
   
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight'
          },
          title: {
            text: 'MRFBSC - USD',
            align: 'left',
        
          },
       
          xaxis: {
            categories: [],
          }
        },
      
      
   
    }

    return (
        <Box className={classes.mainBox}>
           
                <Box className={classes.dexBox} pt={1}>
                <Typography variant="h3">Latest Volume</Typography>
                    <Chart options={options.options} series={options.series}  type="line"/>
                </Box>
        </Box>
    );
}
