import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  makeStyles,
  Container,
  Typography,
  Button,
} from "@material-ui/core";
import { toast } from "react-toastify";
import axios from "axios";

import { UserContext } from "../../context/User";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { getContract } from "../../utils";
import { TokenBinance, TokenBinanceForOther } from "../../constants";
import TokenBinanceABI from "../../abis/TokenBinanceABI.json";
import TokenBinanceForOtherABI from "../../abis/TokenBinanceForOtherABI.json";
import ConnectWallet from "../../components/ConnectWalletPopUp";
import ButtonCircularProgress from "../../components/ButtonCircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "100px 0 0px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      padding: "20px 0px",
    },
    "@media(max-width:767px)": {
      padding: "170px 0 0px",
    },
    "& .wallettext": {
      marginTop: "-60px",
      "& h2": {
        color: "#ffffff",
        // padding: "15px 0px",
        marginBottom: "20px",
        "@media(max-width:767px)": {
          fontSize: "35px",
        },
      },
      "& p": {
        color: "#FFFFFF",
        "@media(max-width:767px)": {
          fontSize: "14px",
        },
      },
    },
  },
  basecontent: {
    padding: "50px 0px",
    "& .yourwallet": {
      width: "100%",
      backgroundColor: "#262831",
      boxShadow: "7px 7px 4px rgba(0, 0, 0, 0.1)",
      backdropFilter: "blur(42px)",
      borderRadius: "16px",
      border: "1px solid #0938df26",
      textAlign: "center",
      padding: "20px",
      "& h3": {
        color: "#EFAB00",
        // padding: "15px 0px 30px 0px",
      },
      "& h5": {
        color: "#EFAB00",
        fontSize: "15px",
      },
      "& .innercontent1": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // borderRight: "1px solid #575757",
        // padding: "10px 0px",
        [theme.breakpoints.down("xs")]: {
          borderRight: "0",
        },
        "& h6": {
          fontSize: "14px",
          color: "#FFFFFF",
        },
        "& p": {
          color: "#FFFFFF",
          fontSize: "11px",
        },
      },
      "& .innercontent2": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // padding: "10px 0px",
        // borderRight: "1px solid #575757",
        // [theme.breakpoints.down("xs")]: {
        //   borderRight: "0",
        // },
        "& h6": {
          fontSize: "14px",
          color: "#FFFFFF",
        },
        "& p": {
          color: "#FFFFFF",
          fontSize: "11px",
        },
      },
    },
    "& .claimed": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& .rewardcontent": {
        display: "flex",
        alignItems: "center",
        padding: "12px 0px",
        "& h6": {
          color: "#FFFFFF",
        },
        "& p": {
          color: "#FFFFFF",
        },
      },
      "& .buttonbox": {
        padding: "12px 0px",
      },
      "& h3": {
        color: "#EFAB00",
        padding: "12px 0px",
      },
    },
    "& .text": {
      padding: "10px",
      "& p": {
        color: "#FFFFFF",
        textAlign: "initial",
      },
    },
  },
  topimg: {
    "@media(max-width:767px)": {
      width: "100%",
    },
  },
  walletDetails: {
    borderRight: "1px solid #575757",
    "@media(max-width: 600px)": {
      borderRight: "none",
    },
  },
}));

function Wallet() {
  const classes = useStyles();
  // const user = useContext(UserContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const [displaydta, setData] = useState("");
  const [dataHolder, setDataHolder] = useState("");
  const [ClaimWait, setgetClaimWait] = useState("");
  const [withdrawbalance, setWithdraw] = useState("");
  const [totalbalance, setTotalvalue] = useState("");
  const web3 = (window.web3 = new Web3(window.ethereum));

  const { account, chainId, library } = useWeb3React();
  //displatdata
  const balanceOfDisplauhandler = async () => {
    try {
      if (account && library) {
        const objectBinanceToken = getContract(
          TokenBinance,
          TokenBinanceABI,
          library,
          account
        );
        //Your BabyTether Balance
        const displayData = await objectBinanceToken.balanceOf(account);
        const dataDisplayMRF = Web3.utils.fromWei(displayData.toString());
        // const dataDisplayMRF = Web3.utils.fromWei(displayData.toString());
        setData(dataDisplayMRF);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const dataDisplauhandler = async () => {
    try {
      if (account && library) {
        const objectBinanceToken = getContract(
          TokenBinanceForOther,
          TokenBinanceForOtherABI,
          library,
          account
        );

        const withdrawbalance = await objectBinanceToken.shares(account);

        const etherValue2 = Web3.utils.fromWei(
          withdrawbalance.totalRealised.toString()
        );
        // console.log("-------------------", etherValue2);
        setgetClaimWait(
          parseFloat(etherValue2).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })
        );

        // setgetClaimWait(
        //   parseFloat(
        //     etherValue12.totalRealised.toLocaleString(undefined, {
        //       maximumFractionDigits: 2,
        //     })
        //   )
        // );
        // console.log("-----", etherValue12);

        //Total rewards
        const getTotalDividendsDistributed =
          await objectBinanceToken.getUnpaidEarnings(account);
        setTotalvalue(
          Web3.utils.fromWei(getTotalDividendsDistributed.toString())
        );

        // Total numberOfDividentTokenHolders
        const numberOfDividentTokenHolders =
          await objectBinanceToken.totalDistributed();

        const etherValue = Web3.utils.fromWei(
          numberOfDividentTokenHolders.toString()
        );
        setDataHolder(parseFloat(etherValue).toFixed(4));

        //Total getClaimWait
        const ngetClaimWait = await objectBinanceToken.totalDistributed();
        const etherValue1 = Web3.utils.fromWei(ngetClaimWait.toString());
        setWithdraw(
          parseFloat(etherValue1).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    balanceOfDisplauhandler();
    dataDisplauhandler();
  }, [account, chainId, displaydta]);

  const claimtoken = async () => {
    try {
      setIsUpdating(true);
      if (totalbalance > 0) {
        if (account && library) {
          const objectBinanceToken = getContract(
            TokenBinanceForOther,
            TokenBinanceForOtherABI,
            library,
            account
          );

          const claimTokens = await objectBinanceToken.claimDividend();
          toast.success("Successful claim");
          setIsUpdating(false);
        }
      } else {
        setIsUpdating(false);
        toast.warn("No rewards to claimed.");
      }
    } catch (err) {
      setIsUpdating(false);
      console.log(err);
      if (
        err?.message ===
          "MetaMask Tx Signature: User denied transaction signature." ||
        err.message === 4001
      ) {
        toast.error("User denied transaction signature");
      } else {
        // toast.error("commission percentage  high");
      }
    }
    // }
  };
  // useEffect(() => {
  //   claimtoken();
  // }, []);

  const [usdprice, setUsdprice] = useState([]);
  const [bnbprice, setBnbprice] = useState([]);
  const MRFBalance = async () => {
    axios({
      url: "https://api.coingecko.com/api/v3/simple/price?ids=wbnb&vs_currencies=usd",
    })
      .then(async (res) => {
        if (res.status === 200) {
          setUsdprice(res.data.wbnb.usd);
          // setState(1);
          // console.log(usdprice);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const BnbPrice = async () => {
    axios({
      url: "https://api.pancakeswap.info/api/v2/tokens/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    })
      .then(async (res) => {
        // console.log("resss", res);
        if (res.status === 200) {
          setBnbprice(res.data.data.price);
          // console.log(bnbprice);
          // setState(1);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    BnbPrice();
    MRFBalance();
  }, []);

  const [openWalletConnect, setOpenWalletConnect] = useState(false);

  const checkValues = 1564776785645;

  function convertToInternationalCurrencySystem(labelValue) {
    // twelve Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e12
      ? (Math.abs(Number(labelValue)) / 1.0e12).toFixed(2) + "T"
      : // Nine Zeroes for Billions
      Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
      : Math.abs(Number(labelValue));
  }

  return (
    <>
      <Box className={classes.root}>
        {openWalletConnect && (
          <ConnectWallet
            open={openWalletConnect}
            handleClose={() => setOpenWalletConnect(false)}
          />
        )}
        <Container maxWidth="md">
          <Box className="imgbox">
            <img
              src="images/Wallet.png"
              alt="Wallet"
              className={classes.topimg}
            />
          </Box>
          <Box className="wallettext">
            <Typography variant="h2">Wallet Analytics</Typography>
            <Typography variant="body2">
              Here you’ll be able to view how much MRF you hold as well as how
              much BUSD you’ve received and the total 24 hour volume! Just
              connect your wallet and enjoy! Rewards are distributed
              automatically, hourly. If you want to claim manually you’re more
              then happy to do so by using the claim button! Hold and Enjoy
              Passive Income!
            </Typography>
          </Box>
        </Container>
        <Box className={classes.basecontent}>
          {account ? (
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box style={{ marginBottom: "10px" }} className="yourwallet">
                  <Typography variant="h3">Your Wallet</Typography>
                  <Box style={{ paddingBottom: "32px", paddingTop: "32px" }}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        // style={{ borderRight: "1px solid #575757" }}
                        className={classes.walletDetails}
                      >
                        <Typography variant="h5">MRF Holding</Typography>
                        <Box
                          style={{ paddingTop: "10px" }}
                          className="innercontent1"
                          mt={2}
                        >
                          <Box style={{ paddingRight: "7px" }}>
                            <img
                              // src="images/гура.png"
                              // alt="Rypa Image"
                              src="images/walletlogo.png"
                              alt="Wallet logo "
                              style={{ width: "35px" }}
                            />
                          </Box>
                          <Box style={{ paddingLeft: "3px" }}>
                            <Typography variant="h6">
                              {convertToInternationalCurrencySystem(displaydta)}{" "}
                              MRF
                              {/* {displaydta ? `${displaydta} B ` : 0} MRF */}
                            </Typography>
                            {/* <Typography variant="body1">($1,250.00)</Typography> */}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        // style={{ borderRight: "1px solid #575757" }}
                        className={classes.walletDetails}
                      >
                        <Typography variant="h5">
                          {" "}
                          Total BUSD Earnings
                        </Typography>
                        <Box
                          style={{ paddingTop: "10px" }}
                          className="innercontent1"
                          mt={2}
                        >
                          <Box style={{ paddingRight: "7px" }}>
                            <img
                              src="images/gold.png"
                              alt="Gold logo "
                              style={{ width: "27px" }}
                            />
                          </Box>
                          <Box style={{ paddingLeft: "3px" }}>
                            <Typography variant="h6">
                              {ClaimWait} BUSD
                            </Typography>
                            {/* <Typography variant="body1">($1,250.00)</Typography> */}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Typography variant="h5">24 hour Volume </Typography>
                        <Box
                          style={{ paddingTop: "10px" }}
                          className="innercontent2"
                          mt={2}
                        >
                          <Box style={{ paddingRight: "7px" }}>
                            <img
                              // src="images/walletlogo.png"
                              // alt="Wallet logo Image"
                              src="images/Dollor12.png"
                              alt="Gold logo "
                              style={{ width: "22px" }}
                            />
                          </Box>
                          <Box style={{ paddingLeft: "3px" }}>
                            <Typography variant="h6">0</Typography>
                            {/* <Typography variant="body1">($1,250.00)</Typography> */}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box className="yourwallet" mt={2}>
                  <Box style={{ paddingTop: "24px", paddingBottom: "16px" }}>
                    <Typography variant="h3">Total BUSD Distributed</Typography>
                  </Box>
                  <Box style={{ paddingBottom: "16px" }}>
                    <Box className="innercontent2">
                      <Box style={{ paddingRight: "7px" }}>
                        <img
                          // src="images/walletlogo.png"
                          // alt="Wallet logo Image"
                          src="images/gold.png"
                          alt="Gold logo "
                          style={{ width: "27px" }}
                        />
                      </Box>
                      <Box style={{ paddingLeft: "3px" }}>
                        <Typography variant="h6">
                          {withdrawbalance} BUSD
                        </Typography>
                        {/* <Typography variant="body1">($1,250.00)</Typography> */}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box className="claimed">
                  <Typography variant="h3">Rewards Not Claimed</Typography>
                  <Box className="rewardcontent">
                    <Box style={{ paddingRight: "7px" }}>
                      <img
                        // src="images/walletlogo.png"
                        // alt="Wallet logo Image"
                        src="images/gold.png"
                        alt="Gold logo "
                      />
                    </Box>
                    <Box style={{ paddingLeft: "3px" }}>
                      <Typography variant="h6">{totalbalance} BUSD</Typography>
                      {/* <Typography variant="body1">($1,250.00)</Typography> */}
                    </Box>
                  </Box>
                  <Box className="buttonbox">
                    <Button
                      variant="containedSizeLarge"
                      onClick={claimtoken}
                      disabled={isUpdating}
                    >
                      Claim Rewards
                      {isUpdating && <ButtonCircularProgress style />}
                    </Button>
                  </Box>
                  <Box className="text" mt={1}>
                    {/* <Typography variant="body2">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Rhoncus dolor egestas ac hendrerit. Posuere mi vitae
                      pretium pharetra. Vitae tincidunt sed ut nascetur id
                      gravida lobortis. Magnis ultricies at turpis sem nam
                      pulvinar.Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Rhoncus dolor egestas ac hendrerit.
                      Posuere mi vitae pretium pharetra. Vitae tincidunt sed ut
                      nascetur id gravida lobortis. Magnis ultricies at turpis
                      sem nam pulvinar.Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Rhoncus dolor egestas ac hendrerit.
                      Posuere mi vitae pretium pharetra. Vitae tincidunt sed ut
                      nascetur id gravida lobortis. Magnis ultricies at turpis
                      sem nam pulvinar.
                    </Typography> */}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Button
              variant="contained"
              color="primary"
              // fullWidth
              onClick={() => setOpenWalletConnect(true)}
              // onClick={user.connectWallet}
            >
              Connect wallet
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
}

export default Wallet;
