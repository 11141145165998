import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Grid,
  IconButton,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import axios from "axios";
import AppsIcon from "@material-ui/icons/Apps";
// import TextField from "@material-ui/core/TextField";
// import { Link } from "react-router-dom";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
import {
  farmConAddress,
  stakingConAddress1,
  stakingConAddress2,
  stakingConAddress3,
  stakingConAddress4,
  // stakingConAddress5,
} from "../../constants/index";
import IERC20ABI from "../../abis/IERC20ABI.json";
import stakingABI1 from "../../abis/StakingABI1.json";
import stakingABI2 from "../../abis/StakingABI2.json";
import stakingABI3 from "../../abis/StakingABI3.json";
import stakingABI4 from "../../abis/StakingABI4.json";
import FarmsABI from "../../abis/FarmsABI.json";
import MyStake from "./MyStake";
import { useWeb3React } from "@web3-react/core";
// import { stakingConAddress } from "../../constants/index";
// import StakingABI from "../../constants/StakingABI.json";
import Web3 from "web3";
// import LiveStake from "./LiveStake";
// import FormGroup from "@material-ui/core/FormGroup";
// import TokenABI from "src/";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Withdraw from "./Withdraw";
import { getContract } from "../../utils";
import KingstakeCard1 from "../../components/KingstakeCard1";
import { UserContext } from "../../context/User";
import { default_RPC_URL } from "../../constants/index";
import { toast } from "react-toastify";
import StakingCards from "../../components/StakingCards";
import NFTCard from "../../components/NFTCard";
import FarmsCards from "../../components/FarmsCards";

// import { ethers } from "ethers";
const useStyles = makeStyles((theme) => ({
  mainBox: {
    borderRadius: "10px",
    minHeight: "250px",
    overflow: "hidden",
    position: "relative",
    background:
      "linear-gradient( 152.97deg, rgba(255, 255, 255, 0.08) 0%, rgba(232, 66, 76, 0.062) 100%)",
    transition: "0.5s",
    backdropFilter: "blur(42px)",
    padding: "30px 0px 30px",
    "&:hover": {
      transform: "translateY(-10px)",
    },
  },
  apyBox: {
    padding: "20px",
    "& h4": {
      fontSize: "15px",
      fontWeight: "700",
      color: "#fff",
    },
    "& h5": {
      fontSize: "15px",
      fontWeight: "700",
      color: "#ffffffcf",
    },
  },

  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  inputBox: {
    border: "none",
    height: "30px",
    width: "100%",
    background: "rgba(141, 137, 137, 0.2)",
  },
  DialogBox: {
    backgroundColor: "#090c16 !important",
    transition: "0.5s",
    position: "relative",
    borderRadius: "10px",
    backdropFilter: "blur(4px)",

    "& h4": {
      fontSize: "15px",
      fontWeight: "700",
      color: "#ffffffab",
    },
    "& h1": {
      fontSize: "30px",
      fontWeight: "bold",
      lineHeight: "76px",
      color: "rgb(246, 165, 45)",
    },
    "& h5": {
      fontSize: "15px",
      fontWeight: "700",
      color: "#fff",
    },
  },
  CoinBox: {
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    "& h5": {
      fontSize: "25px",
      fontWeight: "700",
      color: "#fff",
    },
  },
  cardimg: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  bannerBox: {
    position: "relative",
    zIndex: " 1",
    padding: " 0px 0 70px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& h2": {
      color: "#EFAB00",
      padding: "100px 0px 24px",
      textAlign: "center",
    },
  },
  textbox: {
    "& h1": {
      fontSize: "40px",
      fontWeight: "bold",
      lineHeight: "76px",
      color: "#FABE25",
      "@media (max-width: 767px)": {
        fontSize: "30px",
      },
    },
    "& p": {
      fontSize: "18px",
      color: "#fff",
    },
  },
  Buttonbox: {
    "@media (max-width: 767px)": {
      textAlign: "center",
    },
  },
  rewardbutton: {
    "@media (max-width: 767px)": {
      minWidth: "120px",
    },
  },
}));

export default function Dashboard(props) {
  var classes = useStyles();
  const [pageView, setPageView] = useState("list");
  const [pageView2, setPageView2] = useState("list");

  const user = useContext(UserContext);
  const [tabview, setTabView] = useState("ACTIVE");
  const [tabview1, setTabView1] = useState("FARMS");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [harvestLoader, setharvestLoader] = useState(false);
  const [EnableButtonLoader, setEnableButtonLoader] = useState(false);
  const [EnableButtonLoader2, setEnableButtonLoader2] = useState(false);
  const [EnableButtonLoader3, setEnableButtonLoader3] = useState(false);
  const [EnableButtonLoader4, setEnableButtonLoader4] = useState(false);

  const [pendingReward, setPendingReward] = useState("0");
  const [pendingReward1, setPendingReward1] = useState("0");
  const [pendingReward3, setPendingReward3] = useState("0");
  const [pendingReward4, setPendingReward4] = useState("0");

  const [pendingDividends, setpendingDividends] = useState("0");
  const [pendingDividends1, setpendingDividends1] = useState("0");
  const [pendingDividends3, setpendingDividends3] = useState("0");
  const [pendingDividends4, setpendingDividends4] = useState("0");

  const [widthdrawFeeFarm, setwidthdrawFeeFarm] = useState("");
  const [depositFeeFarm, setdepositFeeFarm] = useState("");

  const [depositFee, setdepositFee] = useState("");
  const [depositFee1, setdepositFee1] = useState("");
  const [depositFee3, setdepositFee3] = useState("");
  const [depositFee4, setdepositFee4] = useState("");

  const [duration, setduration] = useState("");
  const [duration1, setduration1] = useState("");
  const [duration3, setduration3] = useState("");
  const [duration4, setduration4] = useState("");

  const [widthdrawFee, setwidthdrawFee] = useState("");
  const [widthdrawFee1, setwidthdrawFee1] = useState("");
  const [widthdrawFee3, setwidthdrawFee3] = useState("");
  const [widthdrawFee4, setwidthdrawFee4] = useState("");

  const [totalStaked, settotalStaked] = useState("");
  const [totalStaked1, settotalStaked1] = useState("");
  const [totalStaked3, settotalStaked3] = useState("");
  const [totalStaked4, settotalStaked4] = useState("");
  const [totalStaked5, settotalStaked5] = useState("");

  const [userInfoFarm, setuserInfoFarm] = useState("");

  const [userInfo, setuserInfo] = useState("");
  const [userInfo2, setuserInfo2] = useState("");
  const [userInfo3, setuserInfo3] = useState("");
  const [userInfo4, setuserInfo4] = useState("");

  const [stakeNameFarm, setStakeNameFarm] = useState("");

  const [stakeName, setStakeName] = useState("");
  const [stakeName2, setStakeName2] = useState("");
  const [stakeName3, setStakeName3] = useState("");
  const [stakeName4, setStakeName4] = useState("");

  const [earned, setEarned] = useState("");
  const [earned2, setEarned2] = useState("");
  const [earned3, setEarned3] = useState("");
  const [earned4, setEarned4] = useState("");

  const [getOwner, setgetOwner] = useState("");
  const [getOwner2, setgetOwner2] = useState("");
  const [getOwner3, setgetOwner3] = useState("");
  const [getOwner4, setgetOwner4] = useState("");
  // const [earned5, setEarned5] = useState("");

  const [reflected, setreflected] = useState("");
  const [reflected2, setreflected2] = useState("");
  const [reflected3, setreflected3] = useState("");
  const [reflected4, setreflected4] = useState("");

  const [stakeTokenAddress, setstakeTokenAddress] = useState("");
  const [stakeTokenAddress2, setstakeTokenAddress2] = useState("");
  const [stakeTokenAddress3, setstakeTokenAddress3] = useState("");
  const [stakeTokenAddress4, setstakeTokenAddress4] = useState("");

  const [rewardTokenAddress, setrewardTokenAddress] = useState("");
  const [rewardTokenAddress2, setrewardTokenAddress2] = useState("");
  const [rewardTokenAddress3, setrewardTokenAddress3] = useState("");
  const [rewardTokenAddress4, setrewardTokenAddress4] = useState("");
  //bonusEndBlock
  const [bonusEndBlockFarm, setbonusEndBlockFarm] = useState("");

  const [bonusEndBlock, setbonusEndBlock] = useState("");
  const [bonusEndBlock2, setbonusEndBlock2] = useState("");
  const [bonusEndBlock3, setbonusEndBlock3] = useState("");
  const [bonusEndBlock4, setbonusEndBlock4] = useState("");
  //allowanceAmmount
  const [allowanceAmmount, setAllowanceAmmount] = useState("");
  const [allowanceAmmount2, setAllowanceAmmount2] = useState("");
  const [allowanceAmmount3, setAllowanceAmmount3] = useState("");
  const [allowanceAmmount4, setAllowanceAmmount4] = useState("");
  //allowanceAmmount
  const [balanceOfFarm, setbalanceOfFarm] = useState("");
  const [balanceOfFarmbrews, setbalanceOfFarmbrews] = useState("");
  const [balanceOfFarmLpToken, setbalanceOfFarmLpToken] = useState("");
  //allowanceAmmount
  const [balanceOf, setbalanceOf] = useState("");
  const [balanceOf2, setbalanceOf2] = useState("");
  const [balanceOf3, setbalanceOf3] = useState("");
  const [balanceOf4, setbalanceOf4] = useState("");

  const [nextHarvestUntil, setnextHarvestUntil] = useState("");
  const [nextHarvestUntil3, setnextHarvestUntil3] = useState("");
  const [nextHarvestUntil4, setnextHarvestUntil4] = useState("");
  const [nextHarvestUntil5, setnextHarvestUntil5] = useState("");
  const [nextHarvestUntil1, setnextHarvestUntil1] = useState("");

  const [rewardPerBlockBigNumberFarm, setRewardPerBlockBigNumberFarm] =
    useState("");

  const [rewardPerBlockBigNumber, setRewardPerBlockBigNumber] = useState("");
  const [rewardPerBlockBigNumber1, setRewardPerBlockBigNumber1] = useState("");
  const [rewardPerBlockBigNumber3, setRewardPerBlockBigNumber3] = useState("");
  const [rewardPerBlockBigNumber4, setRewardPerBlockBigNumber4] = useState("");

  const [rewardPerBlockFarm, setRewardPerBlockFarm] = useState("");

  const [rewardPerBlock, setRewardPerBlock] = useState("");
  const [rewardPerBlock1, setRewardPerBlock1] = useState("");
  const [rewardPerBlock3, setRewardPerBlock3] = useState("");
  const [rewardPerBlock4, setRewardPerBlock4] = useState("");

  const [nextWithdrawUntil, setnextWithdrawUntil] = useState("");
  const [nextWithdrawUntil1, setnextWithdrawUntil1] = useState("");
  const [nextWithdrawUntil3, setnextWithdrawUntil3] = useState("");
  const [nextWithdrawUntil4, setnextWithdrawUntil4] = useState("");
  const [latestBlock, setLatestBlock] = useState("");

  const [loader, setLoader] = useState(false);
  const [isStake, setIsStake] = useState(false);
  const { chainId, library, account } = useWeb3React();

  // const httpProvider = new Web3.providers.HttpProvider(
  //   "https://data-seed-prebsc-1-s1.binance.org:8545"
  // );
  // console.log(default_RPC_URL, "default_RPC_URL");
  const httpProvider = new Web3.providers.HttpProvider(default_RPC_URL);
  const web3 = new Web3(httpProvider);
  const MINUTE_MS = 18000;
  const bonusEndBlockData = async () => {
    const web3 = (window.web3 = new Web3(window.ethereum));
    console.log("default_RPC_URL", web3);
    try {
      const bonusEndBlockdata = await web3.eth.getBlock("latest");
      setLatestBlock(bonusEndBlockdata.number);
      /*-----------------------------*/
      const stakingContract1 = new web3.eth.Contract(
        stakingABI1,
        stakingConAddress1
      );
      const bonusEndBlock1 = await stakingContract1.methods
        .bonusEndBlock()
        .call();
      setbonusEndBlock(
        Number(bonusEndBlock1.toString()) - bonusEndBlockdata.number
      );
      /*-----------------------------*/
      const stakingContract2 = new web3.eth.Contract(
        stakingABI2,
        stakingConAddress2
      );
      const bonusEndBlock2 = await stakingContract2.methods
        .bonusEndBlock()
        .call();
      setbonusEndBlock2(
        Number(bonusEndBlock2.toString()) - bonusEndBlockdata.number
      );
      /*-----------------------------*/
      const stakingContract3 = new web3.eth.Contract(
        stakingABI3,
        stakingConAddress3
      );
      const bonusEndBlock3 = await stakingContract3.methods
        .bonusEndBlock()
        .call();
      setbonusEndBlock3(
        Number(bonusEndBlock3.toString()) - bonusEndBlockdata.number
      );
      /*-----------------------------*/
      const stakingContract4 = new web3.eth.Contract(
        stakingABI4,
        stakingConAddress4
      );

      const bonusEndBlock4 = await stakingContract4.methods
        .bonusEndBlock()
        .call();
      setbonusEndBlock4(
        Number(bonusEndBlock4.toString()) - bonusEndBlockdata.number
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      bonusEndBlockData();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  // https://api.pancakeswap.info/api/v2/tokens/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c
  const [BNBpriceUSD, setBNBpriceUSD] = useState([]);
  const myBNBpriceUSD = async () => {
    axios({
      method: "GET",
      url: "https://api.pancakeswap.info/api/v2/tokens/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    })
      .then(async (res) => {
        console.log("res", res);
        if (res.status === 200) {
          // console.log("stakingList", res.data.data.);
          setBNBpriceUSD(res.data.data.price);

          // setState(1);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    myBNBpriceUSD();
  }, []);
  //***********************FARM */

  const FarmDeposit = async (id) => {
    const DepAmount = Number(web3.utils.toWei(id.toString()));
    const balanceOfAmount = Number(balanceOfFarmLpToken);

    if (balanceOfAmount >= DepAmount) {
      try {
        setbuttonLoader(true);
        const contract = getContract(
          farmConAddress,
          FarmsABI,
          library,
          account
        );
        console.log(contract);
        // const staked = await contract.stakingToken();
        // await staked;

        const ClaimHarvest = await contract.deposit(
          0,
          web3.utils.toWei(id.toString())
        );
        await ClaimHarvest.wait();
        console.log(ClaimHarvest);
        StakingFunction();
        setbuttonLoader(false);
      } catch (error) {
        toast.error(error.message);
        setbuttonLoader(false);
        console.log(error);
      }
    } else {
      toast.error("Not enough balance !");
    }
  };
  const FarmWidthdraw = async (id) => {
    const DepAmount = Number(web3.utils.toWei(id.toString()));
    const balanceOfAmount = Number(balanceOfFarmLpToken);

    // if (balanceOfAmount >= DepAmount) {
    try {
      setbuttonLoader(true);
      const contract = getContract(farmConAddress, FarmsABI, library, account);

      const ClaimHarvest = await contract.withdraw(
        web3.utils.toWei(id.toString()),
        0
        // 0
      );
      await ClaimHarvest.wait();
      StakingFunction();
      setbuttonLoader(false);
    } catch (error) {
      toast.error(error.message);
      setbuttonLoader(false);
      console.log(error);
    }
    // } else {
    //   toast.error("Not enough balance !");
    // }
  };

  const FarmConAddress1 = async () => {
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      const stakingContract = new web3.eth.Contract(FarmsABI, farmConAddress);
      // console.log("FarmsABI------------farmConAddress", stakingContract);

      // const UserInfo1 = await stakingContract.methods.userInfo(account, 0);
      // // setuserInfo(web3.utils.fromWei(UserInfo1?.amount?.toString()));
      // console.log("UserInfo1", UserInfo1);
      const PoolLength = await stakingContract.methods.poolLength().call();
      // console.log(
      //   "farmConAddress---farmConAddress-----------poolLength",
      //   PoolLength
      // );

      // /*-------------------------------rewardPerBlock  Symbol Success---------------------------- */
      const bonusEndBlockdata = await web3.eth.getBlock("latest");
      const rewardPerBlock = await stakingContract.methods
        .rewardPerBlock() //uint8 require
        .call();
      // console.log(farmConAddress);
      // console.log("rewardPerBlock", rewardPerBlock);
      setRewardPerBlockBigNumberFarm(rewardPerBlock);
      setRewardPerBlockFarm(
        (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      );
      // /*-------------------------------earnedToken---name Success---------------------------- */
      // /*-------------------------------totalStaked  Symbol Success---------------------------- */

      const TotalAllocPoint = await stakingContract.methods
        .totalAllocPoint()
        .call(); //uint8 require
      // console.log("accTokenNEw---------------totalAllocPoint", TotalAllocPoint);

      // settotalStaked(web3.utils.fromWei(totalStaked1.toString()));
      // /*-------------------------------totalStaked---name Success---------------------------- */
      // /*-------------------------------bonusEndBlock  Symbol Success---------------------------- */

      const PoolInfo = await stakingContract.methods.poolInfo(0).call();
      console.log("lpToken---------------+++++poolInfo", PoolInfo);
      setwidthdrawFeeFarm(PoolInfo.withdrawFee / 100);
      setdepositFeeFarm(PoolInfo.depositFee / 100);

      const stakingContractLpToken = new web3.eth.Contract(
        IERC20ABI,
        PoolInfo.lpToken
      );

      console.log("stakingContractLpToken", stakingContractLpToken);

      const reflectionToken = await stakingContract.methods
        .reflectionToken()
        .call();
      console.log(reflectionToken);

      const stakingContractStakeToken = new web3.eth.Contract(
        IERC20ABI,
        reflectionToken
      );
      const name12 = await stakingContractStakeToken.methods.name().call();
      setStakeNameFarm(name12);
      console.log(name12);
      const symbol1 = await stakingContractStakeToken.methods.symbol().call();
      // setStakeName(symbol1);
      console.log(symbol1);

      // const bonusEndBlocks = await stakingContract.methods
      //   .bonusEndBlock()
      //   .call();
      // setbonusEndBlock(
      //   Number(bonusEndBlocks.toString()) - bonusEndBlockdata.number
      // );
      // /*-------------------------------bonusEndBlock---name Success---------------------------- */
    } catch (err) {
      console.log("farmConAddress--------------FarmsABI", err);
    }
  };
  const stakingCardData1 = async () => {
    // const web3 = (window.web3 = new Web3(window.ethereum));
    // console.log("accTokenNEw-------------------1234", stakingConAddress1);
    try {
      const stakingContract = new web3.eth.Contract(
        stakingABI1,
        stakingConAddress1
      );
      console.log("compoundReward------------compoundReward", stakingContract);
      /*-------------------------------MetaReflect  name Success---------------------------- */
      const duration = await stakingContract.methods.duration().call();
      // console.log("duration", duration);
      setduration(duration);
      /*-------------------------------MetaReflect  name Success---------------------------- */
      /*-------------------------------MetaReflect  name Success---------------------------- */
      const staked = await stakingContract.methods.stakingToken().call();
      await staked;
      const stakingContractStakeToken = new web3.eth.Contract(
        IERC20ABI,
        staked
      );
      // const name12 = await stakingContractStakeToken.methods.name().call();
      // setStakeName(name12);
      console.log(staked);
      const symbol1 = await stakingContractStakeToken.methods.symbol().call();
      setStakeName(symbol1);
      console.log(symbol1);
      // /*-------------------------------MetaReflect  name Success---------------------------- */

      // /*-------------------------------MetaReflect  name Success---------------------------- */

      const depositFee1 = await stakingContract.methods.depositFee().call();
      setdepositFee(depositFee1.toString() / 100);
      const widthdrawFee1 = await stakingContract.methods.withdrawFee().call();
      setwidthdrawFee(widthdrawFee1.toString() / 100);
      // console.log("--------------------widthdrawFee1", widthdrawFee1);
      // /*-------------------------------MetaReflect  name Success---------------------------- */

      // /*-------------------------------reflected  Symbol Success---------------------------- */

      const dividendToken = await stakingContract.methods
        .dividendToken()
        .call();
      await dividendToken;
      const dividendTokenObj = new web3.eth.Contract(IERC20ABI, dividendToken);

      const dividendTokensymbol = await dividendTokenObj.methods
        .symbol()
        .call();
      setreflected(dividendTokensymbol);
      console.log("dividendToken", dividendToken);
      setstakeTokenAddress(dividendToken);

      // /*-------------------------------reflected  name Success---------------------------- */
      // /*-------------------------------earnedToken  Symbol Success---------------------------- */

      const earnedToken = await stakingContract.methods.earnedToken().call();
      await earnedToken;
      // console.log("accTokenNEw-----------------dividentToken", dividendToken);
      const earnedTokenObj = new web3.eth.Contract(IERC20ABI, earnedToken);

      const earnedTokensymbol = await earnedTokenObj.methods.symbol().call();
      // console.log("accTokenNEw--------------------", earnedTokensymbol);
      setEarned(earnedTokensymbol);
      setrewardTokenAddress(earnedToken);
      // /*-------------------------------earnedToken---name Success---------------------------- */
      const totalStaked1 = await stakingContract.methods.totalStaked().call(); //uint8 require
      // console.log("accTokenNEw---------------totalStaked1", totalStaked1);
      settotalStaked(web3.utils.fromWei(totalStaked1.toString()));
      // /*-------------------------------rewardPerBlock  Symbol Success---------------------------- */
      const rewardPerBlock = await stakingContract.methods
        .rewardPerBlock() //uint8 require
        .call();
      setRewardPerBlockBigNumber(rewardPerBlock);
      // setRewardPerBlock(
      //   (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      // );

      const calciAPY =
        (parseFloat(web3.utils.fromWei(rewardPerBlock.toString())) /
          parseFloat(web3.utils.fromWei(totalStaked1))) *
        1051200000;
      console.log("calciAPY---------", calciAPY);
      setRewardPerBlock(calciAPY);
      // /*-------------------------------earnedToken---name Success---------------------------- */
      // /*-------------------------------totalStaked  Symbol Success---------------------------- */

      // /*-------------------------------totalStaked---name Success---------------------------- */
      // /*-------------------------------bonusEndBlock  Symbol Success---------------------------- */

      // const calciAPY =
      //   (parseFloat(web3.utils.fromWei(rewardPerBlock.toString())) /
      //     parseFloat(web3.utils.fromWei(totalStaked1))) *
      //   10512000;
      // console.log("calciAPY---------", calciAPY);
      // setRewardPerBlock(calciAPY);
      const bonusEndBlock = await stakingContract.methods
        .bonusEndBlock()
        .call();
      const bonusEndBlockdata = await web3.eth.getBlock("latest");
      setbonusEndBlock(
        Number(bonusEndBlock.toString()) - bonusEndBlockdata.number
      );
      // /*-------------------------------bonusEndBlock---name Success---------------------------- */
    } catch (err) {
      console.log(err);
    }
  };
  const stakingCardData2 = async () => {
    // const web3 = (window.web3 = new Web3(window.ethereum));
    // console.log("accTokenNEw-------------------1234", stakingConAddress1);
    try {
      const stakingContract = new web3.eth.Contract(
        stakingABI2,
        stakingConAddress2
      );
      // console.log(
      //   "<====accTokenNEw********stakeContract****=======>",
      //   stakingContract
      // );
      /*-------------------------------MetaReflect  name Success---------------------------- */
      const staked = await stakingContract.methods.stakingToken().call();
      await staked;
      const stakingContractStakeToken = new web3.eth.Contract(
        IERC20ABI,
        staked
      );
      const name12 = await stakingContractStakeToken.methods.symbol().call();

      setStakeName2(name12);

      const dividendToken = await stakingContract.methods
        .dividendToken()
        .call();
      await dividendToken;
      // console.log("accTokenNEw-----------------dividentToken", dividendToken);
      const dividendTokenObj = new web3.eth.Contract(IERC20ABI, dividendToken);

      const dividendTokensymbol = await dividendTokenObj.methods
        .symbol()
        .call();
      setreflected2(dividendTokensymbol);
      setstakeTokenAddress2(dividendToken);

      // /*-------------------------------reflected  name Success---------------------------- */
      // /*-------------------------------earnedToken  Symbol Success---------------------------- */

      const earnedToken = await stakingContract.methods.earnedToken().call();
      await earnedToken;
      // console.log("accTokenNEw-----------------dividentToken", dividendToken);
      const earnedTokenObj = new web3.eth.Contract(IERC20ABI, earnedToken);

      const earnedTokensymbol = await earnedTokenObj.methods.symbol().call();
      // console.log("accTokenNEw--------------------", earnedTokensymbol);
      setEarned2(earnedTokensymbol);
      setrewardTokenAddress2(earnedToken);

      // /*-------------------------------earnedToken---name Success---------------------------- */
      // /*-------------------------------rewardPerBlock  Symbol Success---------------------------- */
      const rewardPerBlock = await stakingContract.methods
        .rewardPerBlock(0) //uint8 require
        .call();
      setRewardPerBlockBigNumber1(rewardPerBlock);
      // setRewardPerBlock1(
      //   (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      // );

      // /*-------------------------------earnedToken---name Success---------------------------- */

      // /*-------------------------------bonusEndBlock  Symbol Success---------------------------- */

      const bonusEndBlock = await stakingContract.methods
        .bonusEndBlock()
        .call();
      const bonusEndBlockdata = await web3.eth.getBlock("latest");
      setbonusEndBlock2(
        Number(bonusEndBlock.toString()) - bonusEndBlockdata.number
      );
      // /*-------------------------------bonusEndBlock---name Success---------------------------- */
      // /*-------------------------------bonusEndBlock  Symbol Success---------------------------- */

      const lockups = await stakingContract.methods.lockups(0).call(); //uint 8
      // console.log("compoundReward-----lockups-", lockups);
      const values12 = lockups.depositFee.toString() / 100;
      // console.log("compoundReward---------deposit fee----", values12);
      const totalStaked = lockups.totalStaked.toString();
      console.log("rewardPerBlock---asasasas--", rewardPerBlock);
      console.log("totalStaked===----==", web3.utils.fromWei(totalStaked));
      // const calciAPY =
      //   (parseFloat(web3.utils.fromWei(rewardPerBlock.toString())) /
      //     parseFloat(web3.utils.fromWei(totalStaked))) *
      //   10512000;
      // console.log("calciAPY---ascascascac------", calciAPY);

      // setRewardPerBlock1(calciAPY);

      // console.log("compoundReward---------deposit fee----", totalStaked);
      const calciAPY =
        (parseFloat(web3.utils.fromWei(rewardPerBlock.toString())) /
          parseFloat(web3.utils.fromWei(totalStaked))) *
        1051200000;
      console.log("calciAPY---------", calciAPY);
      setRewardPerBlock1(calciAPY);
      const withdrawFee = lockups.withdrawFee.toString() / 100;
      // console.log("compoundReward---------deposit fee----", withdrawFee);
      setdepositFee1(values12);
      const duration = lockups.duration.toString();
      setduration1(duration);
      setwidthdrawFee1(lockups.withdrawFee.toString() / 100);
      settotalStaked1(web3.utils.fromWei(lockups.totalStaked.toString()));
      // /*-------------------------------bonusEndBlock---name Success---------------------------- */
    } catch (err) {
      console.log(err);
    }
  };
  const stakingCardData3 = async () => {
    // const web3 = (window.web3 = new Web3(window.ethereum));
    // console.log("accTokenNEw-------------------1234", stakingConAddress1);
    try {
      const stakingContract = new web3.eth.Contract(
        stakingABI3,
        stakingConAddress3
      );
      // console.log(
      //   "<====accTokenNEw********stakeContract****=======>",
      //   stakingContract
      // );
      /*-------------------------------MetaReflect  name Success---------------------------- */
      const staked = await stakingContract.methods.stakingToken().call();
      await staked;
      const stakingContractStakeToken = new web3.eth.Contract(
        IERC20ABI,
        staked
      );
      const name12 = await stakingContractStakeToken.methods.symbol().call();
      // console.log(
      //   "accTokenNEw----------stakingContractStakeToken.methods-----1",
      //   stakingContractStakeToken
      // );
      setStakeName3(name12);

      const dividendToken = await stakingContract.methods
        .dividendToken()
        .call();
      await dividendToken;
      // console.log("accTokenNEw-----------------dividentToken", dividendToken);
      const dividendTokenObj = new web3.eth.Contract(IERC20ABI, dividendToken);

      const dividendTokensymbol = await dividendTokenObj.methods
        .symbol()
        .call();
      setreflected3(dividendTokensymbol);
      setstakeTokenAddress3(dividendToken);
      // /*-------------------------------reflected  name Success---------------------------- */
      // /*-------------------------------earnedToken  Symbol Success---------------------------- */

      const earnedToken = await stakingContract.methods.earnedToken().call();
      await earnedToken;
      // console.log("accTokenNEw-----------------dividentToken", dividendToken);
      const earnedTokenObj = new web3.eth.Contract(IERC20ABI, earnedToken);

      const earnedTokensymbol = await earnedTokenObj.methods.symbol().call();
      // console.log("accTokenNEw--------------------", earnedTokensymbol);
      setEarned3(earnedTokensymbol);
      setrewardTokenAddress3(earnedToken);

      // /*-------------------------------earnedToken---name Success---------------------------- */
      // /*-------------------------------rewardPerBlock  Symbol Success---------------------------- */
      const rewardPerBlock = await stakingContract.methods
        .rewardPerBlock(1) //uint8 require
        .call();
      setRewardPerBlockBigNumber3(rewardPerBlock);
      // setRewardPerBlock3(
      //   (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      // );
      // /*-------------------------------earnedToken---name Success---------------------------- */

      // /*-------------------------------bonusEndBlock  Symbol Success---------------------------- */

      const bonusEndBlock = await stakingContract.methods
        .bonusEndBlock()
        .call();
      const bonusEndBlockdata = await web3.eth.getBlock("latest");
      setbonusEndBlock3(
        Number(bonusEndBlock.toString()) - bonusEndBlockdata.number
      );
      // /*-------------------------------bonusEndBlock---name Success---------------------------- */
      // /*-------------------------------bonusEndBlock  Symbol Success---------------------------- */

      const lockups = await stakingContract.methods.lockups(1).call(); //uint 8
      // console.log("compoundReward-----lockups-", lockups);
      const values12 = lockups.depositFee.toString() / 100;
      // console.log("compoundReward---------deposit fee----", values12);
      const totalStaked = lockups.totalStaked.toString();

      const calciAPY =
        (parseFloat(web3.utils.fromWei(rewardPerBlock.toString())) /
          parseFloat(web3.utils.fromWei(totalStaked))) *
        1051200000;
      console.log("calciAPY---------", calciAPY);
      setRewardPerBlock3(calciAPY);
      // console.log("compoundReward---------deposit fee----", totalStaked);
      const withdrawFee = lockups.withdrawFee.toString() / 100;
      // console.log("compoundReward---------deposit fee----", withdrawFee);
      setdepositFee3(values12);
      const duration = lockups.duration.toString();
      setduration3(duration);
      setwidthdrawFee3(lockups.withdrawFee.toString() / 100);
      settotalStaked3(web3.utils.fromWei(lockups.totalStaked.toString()));
      // /*-------------------------------bonusEndBlock---name Success---------------------------- */
    } catch (err) {
      console.log(err);
    }
  };
  const stakingCardData4 = async () => {
    // const web3 = (window.web3 = new Web3(window.ethereum));
    // console.log("accTokenNEw-------------------1234", stakingConAddress1);
    try {
      const stakingContract = new web3.eth.Contract(
        stakingABI4,
        stakingConAddress4
      );
      // console.log(
      //   "<====accTokenNEw********stakeContract****=======>",
      //   stakingContract
      // );
      /*-------------------------------MetaReflect  name Success---------------------------- */
      const staked = await stakingContract.methods.stakingToken().call();
      await staked;
      const stakingContractStakeToken = new web3.eth.Contract(
        IERC20ABI,
        staked
      );
      const name12 = await stakingContractStakeToken.methods.symbol().call();
      // console.log(
      //   "accTokenNEw----------stakingContractStakeToken.methods-----1",
      //   stakingContractStakeToken
      // );
      setStakeName4(name12);

      const dividendToken = await stakingContract.methods
        .dividendToken()
        .call();
      await dividendToken;
      // console.log("accTokenNEw-----------------dividentToken", dividendToken);
      const dividendTokenObj = new web3.eth.Contract(IERC20ABI, dividendToken);

      const dividendTokensymbol = await dividendTokenObj.methods
        .symbol()
        .call();
      setreflected4(dividendTokensymbol);
      setstakeTokenAddress4(dividendTokensymbol);
      // /*-------------------------------reflected  name Success---------------------------- */
      // /*-------------------------------earnedToken  Symbol Success---------------------------- */

      const earnedToken = await stakingContract.methods.earnedToken().call();
      await earnedToken;
      // console.log("accTokenNEw-----------------dividentToken", dividendToken);
      const earnedTokenObj = new web3.eth.Contract(IERC20ABI, earnedToken);

      const earnedTokensymbol = await earnedTokenObj.methods.symbol().call();
      // console.log("accTokenNEw--------------------", earnedTokensymbol);
      setEarned4(earnedTokensymbol);
      setrewardTokenAddress4(earnedToken);
      console.log("tokenAddress 44444444444", earnedToken);

      // /*-------------------------------earnedToken---name Success---------------------------- */
      // /*-------------------------------rewardPerBlock  Symbol Success---------------------------- */
      const rewardPerBlock = await stakingContract.methods
        .rewardPerBlock(0) //uint8 require
        .call();
      setRewardPerBlockBigNumber4(rewardPerBlock);
      setRewardPerBlock4(
        (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      );
      // /*-------------------------------earnedToken---name Success---------------------------- */

      // /*-------------------------------bonusEndBlock  Symbol Success---------------------------- */

      const bonusEndBlock = await stakingContract.methods
        .bonusEndBlock()
        .call();
      const bonusEndBlockdata = await web3.eth.getBlock("latest");
      setbonusEndBlock4(
        Number(bonusEndBlock.toString()) - bonusEndBlockdata.number
      );
      // /*-------------------------------bonusEndBlock---name Success---------------------------- */
      // /*-------------------------------bonusEndBlock  Symbol Success---------------------------- */

      const lockups = await stakingContract.methods.lockups(0).call(); //uint 8
      // console.log("compoundReward-----lockups-", lockups);
      const values12 = lockups.depositFee.toString() / 100;
      // console.log("compoundReward---------deposit fee----", values12);
      // const totalStaked = lockups.totalStaked.toString();
      // // console.log("compoundReward---------deposit fee----", totalStaked);
      // const withdrawFee = lockups.withdrawFee.toString() / 100;
      // console.log("compoundReward---------deposit fee----", withdrawFee);
      setdepositFee4(values12);
      const duration = lockups.duration.toString();
      setduration4(duration);
      setwidthdrawFee4(lockups.withdrawFee.toString() / 100);
      settotalStaked4(web3.utils.fromWei(lockups.totalStaked.toString()));
      // /*-------------------------------bonusEndBlock---name Success---------------------------- */
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    FarmConAddress1();
    stakingCardData1();
    stakingCardData2();
    stakingCardData3();
    stakingCardData4();
  }, []);

  const FarmFunctions = async () => {
    try {
      const contractObj = getContract(
        farmConAddress,
        FarmsABI,
        library,
        account
      );

      console.log(
        "1---------------------------------------------------contractObj",
        contractObj
      );
      const UserInfo1 = await contractObj.userInfo(0, account);
      setuserInfoFarm(web3.utils.fromWei(UserInfo1?.amount?.toString()));
      const startBlock = await contractObj.startBlock();
      console.log(startBlock.toString());
      const brews = await contractObj.brews();
      const objbrewsToken = getContract(brews, IERC20ABI, library, account);

      const brewbalanceOf = await objbrewsToken.balanceOf(account);
      setbalanceOfFarmbrews(web3.utils.fromWei(brewbalanceOf));
      const reflectionToken = await contractObj.reflectionToken();
      console.log(reflectionToken);
      const stakingContractStakeToken = getContract(
        reflectionToken,
        IERC20ABI,
        library,
        account
      );
      console.log(stakingContractStakeToken);

      const BalanceOf = await stakingContractStakeToken.balanceOf(account);

      console.log(BalanceOf);
      setbalanceOfFarm(web3.utils.fromWei(BalanceOf));
      const allowanceallowance = await stakingContractStakeToken.allowance(
        account,
        stakingConAddress1
      );
      setAllowanceAmmount(allowanceallowance);

      const PoolInfo = await contractObj.poolInfo(0);
      console.log("lpToken------12---------+++++poolInfo", PoolInfo);
      setwidthdrawFeeFarm(PoolInfo.withdrawFee / 100);
      setdepositFeeFarm(PoolInfo.depositFee / 100);

      const stakingContractLpToken1 = getContract(
        PoolInfo.lpToken,
        IERC20ABI,
        library,
        account
      );
      const balanceOfLp = await stakingContractLpToken1.balanceOf(account);
      setbalanceOfFarmLpToken(web3.utils.fromWei(balanceOfLp));
      console.log(
        "lpToken------12----balanceOfLp-----+++++poolInfo",
        balanceOfLp
      );
    } catch (error) {
      console.log(error);
    }
  };

  const FarmFunction12 = async () => {
    try {
      const contractObj = getContract(
        stakingConAddress1,
        stakingABI1,
        library,
        account
      );
      const UserInfo1 = await contractObj.userInfo(account);
      setuserInfo(web3.utils.fromWei(UserInfo1?.amount?.toString()));
      // console.log(
      //   account,
      //   "1---------------------------------------------------",
      //   contractObj
      // );

      const bonusEndBlock = await contractObj.bonusEndBlock();
      const bonusEndBlockdata = await web3.eth.getBlock("latest");
      // setbonusEndBlock(
      //   Number(bonusEndBlock.toString()) - bonusEndBlockdata.number
      // );
      const totalStaked1 = await contractObj.totalStaked();
      settotalStaked(web3.utils.fromWei(totalStaked1.toString()));
      const rewardPerBlock = await contractObj.rewardPerBlock();
      // console.log(rewardPerBlock);
      // console.log(
      //   "+++++++++rewardPerBlock",
      //   (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      // );
      // setRewardPerBlock(
      //   (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      // );

      // console.log("earnedToken", rewardToken);

      const rewardToken = await contractObj.earnedToken();
      await rewardToken;
      const rewardTokenObj = getContract(
        rewardToken,
        IERC20ABI,
        library,
        account
      );
      // console.log("rewardTokenObjrewardTokenObj------------1", rewardTokenObj);
      const getOwner = await rewardTokenObj.getOwner();
      setgetOwner(getOwner);
      // console.log(
      //   "rewardTokenObjrewardTokenObj-----getOwner-------1",
      //   getOwner
      // );
      const symbol = await rewardTokenObj.symbol();
      // console.log("symbol", symbol);
      setEarned(symbol);
      setrewardTokenAddress(rewardToken);
      // console.log("tokenAddreearnedTokenss -----------------", );

      const dividendToken = await contractObj.dividendToken();
      await rewardToken;
      const dividendTokenObj = getContract(
        dividendToken,
        IERC20ABI,
        library,
        account
      );
      const dividendTokensymbol = await dividendTokenObj.symbol();
      // console.log("dividendToken------- 2 --------symbol", dividendTokensymbol);
      setreflected(dividendTokensymbol);
      setstakeTokenAddress(dividendToken);

      const staked = await contractObj.stakingToken();
      await staked;
      // console.log(staked, "stakingToken");
      const contractDeposit = getContract(staked, IERC20ABI, library, account);

      const allowanceallowance = await contractDeposit.allowance(
        account,
        stakingConAddress1
      );
      setAllowanceAmmount(allowanceallowance);

      const balanceOfdata = await contractDeposit.balanceOf(account);
      setbalanceOf(balanceOfdata);

      const name12 = await contractDeposit.symbol();
      // await name12.wait();
      // // console.log("contract----------name-----1", name12);
      setStakeName(name12);

      const depositFee1 = await contractObj.depositFee();
      setdepositFee(depositFee1.toString() / 100);
      // console.log(
      //   "depositFee1depositFee1--------------111111",
      //   depositFee1.toString() / 100
      // );

      const withdrawFee = await contractObj.withdrawFee();
      // console.log(
      //   "depositFee1depositFee1--------------111111",
      //   withdrawFee.toString() / 100
      // );
      setwidthdrawFee(withdrawFee.toString() / 100);

      // const totalStaked1 = await contractObj.totalStaked()
      // settotalStaked(web3.utils.fromWei(totalStaked1.toString()))
      // console.log("totalStaked--------------111111", totalStaked1);
      const PendingRewards1 = await contractObj.pendingReward(account);
      // console.log("PendingRewards1+++++++", PendingRewards1);
      setPendingReward(web3.utils.fromWei(PendingRewards1.toString()));

      const pendingDividends = await contractObj.pendingDividends(account);
      // console.log("pendingDividends+++++++", pendingDividends);
      setpendingDividends(web3.utils.fromWei(pendingDividends.toString()));

      // console.log(UserInfo);
      setnextHarvestUntil(userInfo.nextHarvestUntil?.toString());
      setnextWithdrawUntil(userInfo.nextWithdrawUntil?.toString());
    } catch (error) {
      console.log(error);
    }
  };

  const StakingFunction = async () => {
    try {
      const contractObj = getContract(
        stakingConAddress1,
        stakingABI1,
        library,
        account
      );
      const UserInfo1 = await contractObj.userInfo(account);
      setuserInfo(web3.utils.fromWei(UserInfo1?.amount?.toString()));
      // console.log(
      //   account,
      //   "1---------------------------------------------------",
      //   contractObj
      // );

      const bonusEndBlock = await contractObj.bonusEndBlock();
      const bonusEndBlockdata = await web3.eth.getBlock("latest");
      // setbonusEndBlock(
      //   Number(bonusEndBlock.toString()) - bonusEndBlockdata.number
      // );
      const totalStaked1 = await contractObj.totalStaked();
      settotalStaked(web3.utils.fromWei(totalStaked1.toString()));
      const rewardPerBlock = await contractObj.rewardPerBlock();
      // console.log(rewardPerBlock);
      // console.log(
      //   "+++++++++rewardPerBlock",
      //   (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      // );
      // setRewardPerBlock(
      //   (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      // );

      const calciAPY =
        (parseFloat(web3.utils.fromWei(rewardPerBlock.toString())) /
          parseFloat(web3.utils.fromWei(totalStaked1))) *
        1051200000;
      console.log("calciAPY---------", calciAPY);
      setRewardPerBlock(calciAPY);

      const rewardToken = await contractObj.earnedToken();
      // console.log("earnedToken", rewardToken);
      await rewardToken;
      const rewardTokenObj = getContract(
        rewardToken,
        IERC20ABI,
        library,
        account
      );
      // console.log("rewardTokenObjrewardTokenObj------------1", rewardTokenObj);
      const getOwner = await rewardTokenObj.getOwner();
      setgetOwner(getOwner);
      // console.log(
      //   "rewardTokenObjrewardTokenObj-----getOwner-------1",
      //   getOwner
      // );
      const symbol = await rewardTokenObj.symbol();
      // console.log("symbol", symbol);
      setEarned(symbol);
      setrewardTokenAddress(rewardToken);
      // console.log("tokenAddress --------earnedToken---------", );

      const dividendToken = await contractObj.dividendToken();
      await rewardToken;
      const dividendTokenObj = getContract(
        dividendToken,
        IERC20ABI,
        library,
        account
      );
      const dividendTokensymbol = await dividendTokenObj.symbol();
      // console.log("dividendToken------- 2 --------symbol", dividendTokensymbol);
      setreflected(dividendTokensymbol);
      setstakeTokenAddress(dividendToken);

      const staked = await contractObj.stakingToken();
      await staked;
      // console.log(staked, "stakingToken");
      const contractDeposit = getContract(staked, IERC20ABI, library, account);

      const allowanceallowance = await contractDeposit.allowance(
        account,
        stakingConAddress1
      );
      setAllowanceAmmount(allowanceallowance);

      const balanceOfdata = await contractDeposit.balanceOf(account);
      setbalanceOf(balanceOfdata);

      const name12 = await contractDeposit.symbol();
      // await name12.wait();
      // // console.log("contract----------name-----1", name12);
      setStakeName(name12);

      const depositFee1 = await contractObj.depositFee();
      setdepositFee(depositFee1.toString() / 100);
      // console.log(
      //   "depositFee1depositFee1--------------111111",
      //   depositFee1.toString() / 100
      // );

      const withdrawFee = await contractObj.withdrawFee();
      // console.log(
      //   "depositFee1depositFee1--------------111111",
      //   withdrawFee.toString() / 100
      // );
      setwidthdrawFee(withdrawFee.toString() / 100);

      // const totalStaked1 = await contractObj.totalStaked()
      // settotalStaked(web3.utils.fromWei(totalStaked1.toString()))
      // console.log("totalStaked--------------111111", totalStaked1);
      const PendingRewards1 = await contractObj.pendingReward(account);
      // console.log("PendingRewards1+++++++", PendingRewards1);
      setPendingReward(web3.utils.fromWei(PendingRewards1.toString()));

      const pendingDividends = await contractObj.pendingDividends(account);
      // console.log("pendingDividends+++++++", pendingDividends);
      setpendingDividends(web3.utils.fromWei(pendingDividends.toString()));

      // console.log(UserInfo);
      setnextHarvestUntil(userInfo.nextHarvestUntil?.toString());
      setnextWithdrawUntil(userInfo.nextWithdrawUntil?.toString());
    } catch (error) {
      console.log(error);
    }
  };
  const StakingFunction1 = async () => {
    try {
      const contractObj = getContract(
        stakingConAddress2,
        stakingABI2,
        library,
        account
      );

      const UserInfo1 = await contractObj.userInfo(0, account);
      // console.log(
      //   "userInfoData------2",
      //   web3.utils.fromWei(UserInfo1?.amount?.toString())
      // );
      setuserInfo2(web3.utils.fromWei(UserInfo1?.amount?.toString()));
      console.log(
        stakingConAddress2,
        "====compoundReward==-----=-=-2",
        contractObj
      );

      // const compoundReward1 = await contractObj.compoundReward(0);
      // console.log("compoundReward ------------11", compoundReward1);
      const lockups = await contractObj.lockups(0);
      // console.log("compoundReward-----lockups-", lockups);
      const values12 = lockups.depositFee.toString() / 100;
      // console.log("compoundReward---------deposit fee----", values12);
      setdepositFee1(values12);
      setwidthdrawFee1(lockups.withdrawFee.toString() / 100);
      settotalStaked1(web3.utils.fromWei(lockups.totalStaked.toString()));

      const rewardToken = await contractObj.earnedToken();
      await rewardToken;
      const rewardTokenObj = getContract(
        rewardToken,
        IERC20ABI,
        library,
        account
      );
      const symbol = await rewardTokenObj.symbol();
      setEarned2(symbol);
      setrewardTokenAddress(rewardToken);
      const name = await rewardTokenObj.symbol();
      setStakeName2(name);

      const dividendToken = await contractObj.dividendToken();
      await rewardToken;
      const dividendTokenObj = getContract(
        dividendToken,
        IERC20ABI,
        library,
        account
      );
      const dividendTokensymbol = await dividendTokenObj.symbol();
      // console.log("dividendToken------- 2 --------symbol", dividendTokensymbol);
      setreflected2(dividendTokensymbol);
      setstakeTokenAddress2(dividendToken);
      const name12 = await rewardTokenObj.symbol();
      // // console.log("rewardTokenObj---- 2 -----name", name);
      setStakeName2(name12);

      const staked = await contractObj.stakingToken();
      await staked;
      // console.log(staked, "stakingToken");
      const contractDeposit = getContract(staked, IERC20ABI, library, account);

      const getOwners = await contractDeposit.getOwner();
      setgetOwner2(getOwners);
      // console.log("rewardTokenObjrewardTokenObj------------1", getOwners);
      const allowanceallowance = await contractDeposit.allowance(
        account,
        stakingConAddress2
      );
      setAllowanceAmmount2(allowanceallowance);
      const balanceOfdata = await contractDeposit.balanceOf(account);
      setbalanceOf2(balanceOfdata);

      // console.log(
      //   contractDeposit,
      //   "contractDeposit-----------contractstakedApprove-------------------1",
      //   allowanceallowance.toString()
      // );
      // const totalStaked1 = await contractObj.totalStaked();
      // settotalStaked1(web3.utils.fromWei(totalStaked1.toString()));
      // const userStaked = await contractObj.userStaked(account);
      // console.log("======-----=-=-2", userStaked);
      // const userInfo = await contractObj.userInfo(0, account);
      // // console.log("======-----=-=-2", userInfo);

      // const bonusEndBlock = await contractObj.bonusEndBlock();
      // const bonusEndBlockdata = await web3.eth.getBlock("latest");
      // setbonusEndBlock2(
      //   Number(bonusEndBlock.toString()) - bonusEndBlockdata.number
      // );
      const rewardPerBlock = await contractObj.rewardPerBlock(0);
      // console.log(
      //   "2+++++++++rewardPerBlock",
      //   (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      // );
      // setRewardPerBlock1(
      //   (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      // );

      const PendingRewards1 = await contractObj.pendingReward(account, 0);
      // console.log(PendingRewards1);
      setPendingReward1(web3.utils.fromWei(PendingRewards1.toString()));

      const pendingDividends = await contractObj.pendingDividends(account, 0);
      // console.log("pendingDividends+++++++", pendingDividends);
      setpendingDividends1(web3.utils.fromWei(pendingDividends.toString()));
      const calciAPY =
        (parseFloat(web3.utils.fromWei(rewardPerBlock.toString())) /
          parseFloat(web3.utils.fromWei(lockups.totalStaked.toString()))) *
        1051200000;
      console.log("calciAPY---------", calciAPY);
      setRewardPerBlock1(calciAPY);
    } catch (error) {
      console.log(error);
    }
  };
  const StakingFunction3 = async () => {
    try {
      const contractObj = getContract(
        stakingConAddress3,
        stakingABI3,
        library,
        account
      );

      const UserInfo1 = await contractObj.userInfo(1, account);
      // console.log("userInfo------3", UserInfo1);
      setuserInfo3(web3.utils.fromWei(UserInfo1?.amount?.toString()));
      // console.log("======-----=-=-2", contractObj);

      const lockups = await contractObj.lockups(1);
      // console.log("------", lockups);
      const values12 = lockups.depositFee.toString() / 100;
      // console.log("depositFee12", values12);
      setdepositFee3(values12);
      setwidthdrawFee3(lockups.withdrawFee.toString() / 100);
      settotalStaked3(web3.utils.fromWei(lockups.totalStaked.toString()));

      const rewardToken = await contractObj.earnedToken();
      await rewardToken;
      const rewardTokenObj = getContract(
        rewardToken,
        IERC20ABI,
        library,
        account
      );
      const symbol = await rewardTokenObj.symbol();
      setEarned3(symbol);
      setrewardTokenAddress3(rewardToken);
      const name = await rewardTokenObj.symbol();
      setStakeName3(name);

      const dividendToken = await contractObj.dividendToken();
      await rewardToken;
      const dividendTokenObj = getContract(
        dividendToken,
        IERC20ABI,
        library,
        account
      );
      const dividendTokensymbol = await dividendTokenObj.symbol();
      // console.log("dividendToken------- 2 --------symbol", dividendTokensymbol);
      setreflected3(dividendTokensymbol);
      setstakeTokenAddress3(dividendToken);
      // const name = await rewardTokenObj.name();
      // // console.log("rewardTokenObj---- 2 -----name", name);
      // setStakeName2(name);

      const staked = await contractObj.stakingToken();
      await staked;
      // console.log(staked, "stakingToken");
      const contractDeposit = getContract(staked, IERC20ABI, library, account);

      const allowanceallowance = await contractDeposit.allowance(
        account,
        stakingConAddress3
      );
      setAllowanceAmmount3(allowanceallowance);

      const balanceOfdata = await contractDeposit.balanceOf(account);
      setbalanceOf3(balanceOfdata);
      // console.log(
      //   "contractstakedApprove-------------------3",
      //   allowanceallowance.toString()
      // );
      const totalStaked1 = await contractObj.totalStaked();
      settotalStaked3(web3.utils.fromWei(totalStaked1.toString()));
      const userStaked = await contractObj.userStaked(account);
      // // console.log("======-----=-=-2", userStaked);
      const userInfo = await contractObj.userInfo(1, account);
      // // console.log("======-----=-=-2", userInfo);

      // const bonusEndBlock = await contractObj.bonusEndBlock();
      // const bonusEndBlockdata = await web3.eth.getBlock("latest");
      // // setbonusEndBlock3(
      // //   Number(bonusEndBlock.toString()) - bonusEndBlockdata.number
      // // );
      // const rewardPerBlock = await contractObj.rewardPerBlock(1);
      // console.log(
      //   "2+++++++++rewardPerBlock",
      //   (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      // );

      // setRewardPerBlock3(
      //   (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      // );
      const calciAPY =
        (parseFloat(web3.utils.fromWei(rewardPerBlock.toString())) /
          parseFloat(web3.utils.fromWei(lockups.totalStaked.toString()))) *
        1051200000;
      console.log("calciAPY---------", calciAPY);
      setRewardPerBlock1(calciAPY);
      const PendingRewards1 = await contractObj.pendingReward(account, 1);
      // console.log(PendingRewards1);
      setPendingReward3(web3.utils.fromWei(PendingRewards1.toString()));

      const pendingDividends = await contractObj.pendingDividends(account, 1);
      // console.log("pendingDividends+++++++", pendingDividends);
      setpendingDividends3(web3.utils.fromWei(pendingDividends.toString()));
    } catch (error) {
      console.log(error);
    }
  };
  const StakingFunction4 = async () => {
    try {
      const contractObj = getContract(
        stakingConAddress4,
        stakingABI4,
        library,
        account
      );

      const UserInfo1 = await contractObj.userInfo(0, account);
      // console.log("userInfo------4", UserInfo1);
      setuserInfo4(web3.utils.fromWei(UserInfo1?.amount?.toString()));
      // console.log("StakingFunction4======-----=-=-4", contractObj);

      const lockups = await contractObj.lockups(0);
      // console.log("StakingFunction4-----4-", lockups);
      const values12 = lockups.depositFee.toString() / 100;
      // console.log("StakingFunction4----------------depositFee124", values12);
      setdepositFee4(values12);
      setwidthdrawFee4(lockups.withdrawFee.toString() / 100);
      settotalStaked4(web3.utils.fromWei(lockups.totalStaked.toString()));

      const rewardToken = await contractObj.earnedToken();
      await rewardToken;
      const rewardTokenObj = getContract(
        rewardToken,
        IERC20ABI,
        library,
        account
      );
      const symbol = await rewardTokenObj.symbol();
      setEarned4(symbol);
      setrewardTokenAddress4(rewardToken);
      console.log("tokenAddress 4444444444444444444444", rewardToken);
      const name = await rewardTokenObj.symbol();
      setStakeName4(name);

      const dividendToken = await contractObj.dividendToken();
      await rewardToken;
      const dividendTokenObj = getContract(
        dividendToken,
        IERC20ABI,
        library,
        account
      );
      const dividendTokensymbol = await dividendTokenObj.symbol();
      // console.log("dividendToken------- 2 --------symbol", dividendTokensymbol);
      setreflected4(dividendTokensymbol);
      setstakeTokenAddress4(dividendTokensymbol);
      // const name = await rewardTokenObj.name();
      // // console.log("rewardTokenObj---- 2 -----name", name);
      // setStakeName2(name);

      // const totalStaked1 = await contractObj.totalStaked();
      // settotalStaked4(web3.utils.fromWei(totalStaked1.toString()));
      const userStaked = await contractObj.userStaked(account);
      // console.log("======-----=-=-2", userStaked);
      const userInfo1 = await contractObj.userInfo(0, account);
      // console.log(
      //   "======--userInfouserInfouserInfouserInfouserInfo---=-=-2",
      //   userInfo1
      // );

      const bonusEndBlock = await contractObj.bonusEndBlock();
      const bonusEndBlockdata = await web3.eth.getBlock("latest");
      setbonusEndBlock4(
        Number(bonusEndBlock.toString()) - bonusEndBlockdata.number
      );

      const staked = await contractObj.stakingToken();
      await staked;
      // console.log(staked, "stakingToken");
      const contractDeposit = getContract(staked, IERC20ABI, library, account);

      const allowanceallowance = await contractDeposit.allowance(
        account,
        stakingConAddress4
      );
      setAllowanceAmmount4(allowanceallowance);

      const balanceOfdata = await contractDeposit.balanceOf(account);
      setbalanceOf4(balanceOfdata);
      // console.log(
      //   "contractstakedApprove-------------------4",
      //   allowanceallowance.toString()
      // );
      // const rewardPerBlock = await contractObj.rewardPerBlock(0);
      // console.log(
      //   "2+++++++++rewardPerBlock",
      //   (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      // );
      // setRewardPerBlock4(
      //   (web3.utils.fromWei(rewardPerBlock.toString()) * 1000000) / 9.6450617
      // );

      const PendingRewards1 = await contractObj.pendingReward(account, 0);
      // console.log(PendingRewards1);
      setPendingReward4(web3.utils.fromWei(PendingRewards1.toString()));

      const pendingDividends = await contractObj.pendingDividends(account, 0);
      // console.log("pendingDividends+++++++", pendingDividends);
      setpendingDividends4(web3.utils.fromWei(pendingDividends.toString()));
    } catch (error) {
      console.log(error);
    }
  };
  //----------------------staking Widthdraw
  const StakingWidthdraw = async (id) => {
    // console.log(id.toString());
    // console.log(userInfo?.toString());
    if (Number(userInfo?.toString()) >= Number(id?.toString())) {
      try {
        setbuttonLoader(true);
        const contract = getContract(
          stakingConAddress1,
          stakingABI1,
          library,
          account
        );
        const Withdrawing = await contract.withdraw(
          web3.utils.toWei(id.toString())
        );
        await Withdrawing.wait();
        toast.success("Withdrawing successfully");
        setbuttonLoader(false);
      } catch (error) {
        toast.error(error.message);
        setbuttonLoader(false);
        console.log(error);
      }
    } else {
      toast.error("Withdrawing more than staked !");
    }
  };
  const StakingWidthdraw1 = async (id) => {
    if (Number(userInfo2?.toString()) >= Number(id?.toString())) {
      try {
        setbuttonLoader(true);
        const contract = getContract(
          stakingConAddress2,
          stakingABI2,
          library,
          account
        );
        // console.log("contract", contract);

        const erc20address = await contract.withdraw(
          web3.utils.toWei(id.toString()),
          0
        );
        // console.log(erc20address);
        await erc20address.wait();
        toast.success("Withdrawing successfully");
        setbuttonLoader(false);
      } catch (error) {
        toast.error(error.message);
        setbuttonLoader(false);
        console.log(error);
      }
    } else {
      toast.error("Withdrawing more than staked !");
    }
  };
  const StakingWidthdraw3 = async (id) => {
    if (Number(userInfo3?.toString()) >= Number(id?.toString())) {
      try {
        setbuttonLoader(true);
        const contract = getContract(
          stakingConAddress3,
          stakingABI3,
          library,
          account
        );

        // console.log("contract", contract);

        const erc20address = await contract.withdraw(
          web3.utils.toWei(id.toString()),
          1
        );
        // console.log(erc20address);
        await erc20address.wait();
        toast.success("Withdrawing successfully");
        setbuttonLoader(false);
      } catch (error) {
        toast.error(error.message);
        setbuttonLoader(false);
        console.log(error);
      }
    } else {
      toast.error("Withdrawing more than staked !");
    }
  };
  const StakingWidthdraw4 = async (id) => {
    if (Number(userInfo4?.toString()) >= Number(id?.toString())) {
      try {
        setbuttonLoader(true);
        const contract = getContract(
          stakingConAddress4,
          stakingABI4,
          library,
          account
        );

        // console.log("contract", contract);

        const erc20address = await contract.withdraw(
          web3.utils.toWei(id.toString()),
          0
        );
        // console.log(erc20address);
        await erc20address.wait();
        toast.success("Withdrawing successfully");
        setbuttonLoader(false);
      } catch (error) {
        toast.error(error.message);
        setbuttonLoader(false);
        console.log(error);
      }
    } else {
      toast.error("Withdrawing more than staked !");
    }
  };
  //-------------------staking Harvest   Earned-----
  const StakingHarvestEarned = async () => {
    setLoader(true);
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      setharvestLoader(true);

      const contract = getContract(
        stakingConAddress1,
        stakingABI1,
        library,
        account
      );
      console.log("==============1543", contract);

      // *********************** Owner
      const AdminOwner = await contract.owner();
      await AdminOwner;
      // *********************** using the promise
      const PriceInBNB = (0.1 / BNBpriceUSD).toFixed(18);
      console.log("PriceInBNB", PriceInBNB);

      // const transfer = await web3.eth.sendTransaction(
      //   {
      //     from: account,
      //     to: AdminOwner,
      //     value: web3.utils.toWei(PriceInBNB.toString()),
      //   },
      //   function async(err, transactionHash) {
      //     if (err) {
      //       console.log("Payment failed Error", err);
      //       return;
      //     } else {
      //     }
      //   }
      // );
      const claimReward = await contract.claimReward();
      await claimReward.wait();
      toast.success("Harvest Done");

      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error(error.message);
      setharvestLoader(false);
      console.log(error);
    }
  };
  /**********************contract*harvest1************************ */

  const StakingHarvestEarned1 = async () => {
    try {
      setharvestLoader(true);

      const contract = getContract(
        stakingConAddress2,
        stakingABI2,
        library,
        account
      );
      const harvested = await contract.harvest();
      await harvested.wait();
      // console.log("StakingHarvest", harvested);
      // const CanHarvest = await contract.canHarvest(account);
      // if (CanHarvest) {
      // await contract.claim();
      const claimReward = await contract.claimReward(0);
      await claimReward.wait();
      toast.success("Harvest Done");
    } catch (error) {
      toast.error(error.message);
      setharvestLoader(false);
      console.log(error);
    }
  };
  const StakingHarvestEarned3 = async () => {
    try {
      setharvestLoader(true);

      const contract = getContract(
        stakingConAddress3,
        stakingABI3,
        library,
        account
      );
      // console.log("==============3", contract);
      const harvested = await contract.harvest();
      await harvested.wait();
      // console.log("StakingHarvest", harvested);
      // const CanHarvest = await contract.canHarvest(account);
      // if (CanHarvest) {
      // await contract.claim();
      const claimReward = await contract.claimReward(1);
      await claimReward.wait();
      toast.success("Harvest Done");
      // } else {
      //   toast.warn("Not enough claim time");
      // }
    } catch (error) {
      toast.error(error.message);
      setharvestLoader(false);
      console.log(error);
    }
  };
  const StakingHarvestEarned4 = async () => {
    try {
      setharvestLoader(true);

      const contract = getContract(
        stakingConAddress4,
        stakingABI4,
        library,
        account
      );
      // console.log("==============1", contract);
      const harvested = await contract.harvest(0);
      await harvested.wait();
      // console.log("StakingHarvest", harvested);
      // const CanHarvest = await contract.canHarvest(account);
      // if (CanHarvest) {
      // await contract.claim();
      const claimReward = await contract.claimReward(0);
      await claimReward.wait();
      toast.success("Harvest Done");
    } catch (error) {
      toast.error(error.message);
      setharvestLoader(false);
      console.log(error);
    }
  };
  //-------------------staking Harvest   Earned-----
  const HarvestDividend = async () => {
    try {
      setharvestLoader(true);

      const contract = getContract(
        stakingConAddress1,
        stakingABI1,
        library,
        account
      );
      // console.log("==============1", contract);
      // const CanHarvest = await contract.canHarvest(account);
      // if (CanHarvest) {
      // await contract.claim();
      const claimDividend = await contract.claimDividend();
      await claimDividend.wait();
      toast.success("Harvest Done");
    } catch (error) {
      toast.error(error.message);
      setharvestLoader(false);
      console.log(error);
    }
  };
  const StakingDeposit = async (id) => {
    const DepAmount = Number(web3.utils.toWei(id.toString()));
    const balanceOfAmount = Number(balanceOf);

    if (balanceOfAmount >= DepAmount) {
      try {
        setbuttonLoader(true);
        const contract = getContract(
          stakingConAddress1,
          stakingABI1,
          library,
          account
        );
        // console.log(contract);
        const staked = await contract.stakingToken();
        await staked;
        const contractDeposit = getContract(
          staked,
          IERC20ABI,
          library,
          account
        );
        // const aprovel = await contractDeposit.approve(
        //   stakingConAddress1,
        //   web3.utils.toWei(id.toString())
        // );
        // await aprovel.wait();
        const ClaimHarvest = await contract.deposit(
          web3.utils.toWei(id.toString())
          // 0
        );
        await ClaimHarvest.wait();
        StakingFunction();
        setbuttonLoader(false);
      } catch (error) {
        toast.error(error.message);
        setbuttonLoader(false);
        console.log(error);
      }
    } else {
      toast.error("Not enough balance !");
    }
  };
  //deposit 2
  const StakingDeposit1 = async (id) => {
    const DepAmount = Number(web3.utils.toWei(id.toString()));
    const balanceOfAmount = Number(balanceOf2);

    if (balanceOfAmount >= DepAmount) {
      try {
        setbuttonLoader(true);
        const contract = getContract(
          stakingConAddress2,
          stakingABI2,
          library,
          account
        );
        // console.log(contract);
        const staked = await contract.stakingToken();
        // console.log(staked);

        await staked;
        const contractDeposit = getContract(
          staked,
          IERC20ABI,
          library,
          account
        );
        // console.log(contractDeposit);
        // const aproved = await contractDeposit.approve(
        //   stakingConAddress2,
        //   web3.utils.toWei(id.toString())
        // );
        // await aproved.wait();
        // console.log(aproved);

        // await contract.approve(stakingABI1, amountToSend);
        // *need of uint 8 now for deposit

        const ClaimHarvest = await contract.deposit(
          web3.utils.toWei(id.toString()),
          0
        );
        await ClaimHarvest.wait();
        toast.success("Deposit Successful");
        // console.log("---------", ClaimHarvest);
        setbuttonLoader(false);
        StakingFunction1();
      } catch (error) {
        toast.error(error.message);
        setbuttonLoader(false);
        console.log(error);
      }
    } else {
      toast.error("Not enough balance !");
    }
  };
  //deposit 3
  const StakingDeposit3 = async (id) => {
    const DepAmount = Number(web3.utils.toWei(id.toString()));
    const balanceOfAmount = Number(balanceOf3);

    if (balanceOfAmount >= DepAmount) {
      try {
        setbuttonLoader(true);
        const contract = getContract(
          stakingConAddress3,
          stakingABI3,
          library,
          account
        );
        // console.log(contract);
        const staked = await contract.stakingToken();
        // console.log(staked);

        await staked;
        const contractDeposit = getContract(
          staked,
          IERC20ABI,
          library,
          account
        );
        // console.log(contractDeposit);
        // const aproved = await contractDeposit.approve(
        //   stakingConAddress3,
        //   web3.utils.toWei(id.toString())
        // );
        // await aproved.wait();
        // console.log(aproved);

        // await contract.approve(stakingABI1, amountToSend);
        // *need of uint 8 now for deposit

        const ClaimHarvest = await contract.deposit(
          web3.utils.toWei(id.toString()),
          1
        );
        await ClaimHarvest.wait();
        toast.success("Deposit Successful");
        // console.log("---------", ClaimHarvest);
        setbuttonLoader(false);
        StakingFunction3();
      } catch (error) {
        toast.error(error.message);
        setbuttonLoader(false);
        console.log(error);
      }
    } else {
      toast.error("Not enough balance !");
    }
  };
  //deposit 4
  const StakingDeposit4 = async (id) => {
    const DepAmount = Number(web3.utils.toWei(id.toString()));
    const balanceOfAmount = Number(balanceOf4);

    if (balanceOfAmount >= DepAmount) {
      try {
        setbuttonLoader(true);
        const contract = getContract(
          stakingConAddress4,
          stakingABI4,
          library,
          account
        );
        // console.log(contract);
        const staked = await contract.stakingToken();
        // console.log(staked);

        await staked;
        const contractDeposit = getContract(
          staked,
          IERC20ABI,
          library,
          account
        );
        // console.log(contractDeposit);
        // const aproved = await contractDeposit.approve(
        //   stakingConAddress4,
        //   web3.utils.toWei(id.toString())
        // );
        // await aproved.wait();
        // console.log(aproved);

        // await contract.approve(stakingABI1, amountToSend);
        // *need of uint 8 now for deposit

        const ClaimHarvest = await contract.deposit(
          web3.utils.toWei(id.toString()),
          0
        );
        await ClaimHarvest.wait();
        toast.success("Deposit Successful");
        // console.log("---------", ClaimHarvest);
        setbuttonLoader(false);
        StakingFunction4();
      } catch (error) {
        toast.error(error.message);
        setbuttonLoader(false);
        console.log(error);
      }
    } else {
      toast.error("Not enough balance !");
    }
  };
  /*---------------------------------------Max------Approve----------------------------------------*/
  const EnableButtonMaxApprove = async () => {
    setEnableButtonLoader(true);
    try {
      const contractObj = getContract(
        stakingConAddress1,
        stakingABI1,
        library,
        account
      );
      console.log("compoundReward------------compoundReward", contractObj);
      const staked = await contractObj.stakingToken();
      await staked;
      const contractDeposit = getContract(staked, IERC20ABI, library, account);
      const allowanceallowance = await contractDeposit.approve(
        stakingConAddress1,
        "115792089237316195423570985008687907853269984665640564039457584007913129639935"
      );
      await allowanceallowance.wait();
      StakingFunction();
      toast.success("Contract approve successfully");
      setEnableButtonLoader(false);
    } catch (error) {
      setEnableButtonLoader(false);
      console.log("error", error);
      toast.error(error.message);
    }
    //"115792089237316195423570985008687907853269984665640564039457584007913129639935"
  };
  const EnableButtonMaxApprove2 = async () => {
    setEnableButtonLoader2(true);
    try {
      const contractObj = getContract(
        stakingConAddress2,
        stakingABI2,
        library,
        account
      );

      const staked = await contractObj.stakingToken();
      await staked;
      const contractDeposit = getContract(staked, IERC20ABI, library, account);
      const allowanceallowance = await contractDeposit.approve(
        stakingConAddress2,
        "115792089237316195423570985008687907853269984665640564039457584007913129639935"
      );
      await allowanceallowance.wait();
      StakingFunction1();
      toast.success("Contract approve successfully");
      setEnableButtonLoader2(false);
    } catch (error) {
      console.log("error", error);
      setEnableButtonLoader2(false);
      toast.error(error.message);
    }
    //"115792089237316195423570985008687907853269984665640564039457584007913129639935"
  };
  const EnableButtonMaxApprove3 = async () => {
    setEnableButtonLoader3(true);
    try {
      const contractObj = getContract(
        stakingConAddress3,
        stakingABI3,
        library,
        account
      );

      const staked = await contractObj.stakingToken();
      await staked;
      const contractDeposit = getContract(staked, IERC20ABI, library, account);
      const allowanceallowance = await contractDeposit.approve(
        stakingConAddress3,
        "115792089237316195423570985008687907853269984665640564039457584007913129639935"
      );
      await allowanceallowance.wait();
      StakingFunction3();
      toast.success("Contract approve successfully");
      setEnableButtonLoader3(false);
    } catch (error) {
      console.log("error", error);
      setEnableButtonLoader3(false);
      toast.error(error.message);
    }
    //"115792089237316195423570985008687907853269984665640564039457584007913129639935"
  };
  const EnableButtonMaxApprove4 = async () => {
    setEnableButtonLoader4(true);
    try {
      const contractObj = getContract(
        stakingConAddress4,
        stakingABI4,
        library,
        account
      );

      const staked = await contractObj.stakingToken();
      await staked;
      const contractDeposit = getContract(staked, IERC20ABI, library, account);
      const allowanceallowance = await contractDeposit.approve(
        stakingConAddress4,
        "115792089237316195423570985008687907853269984665640564039457584007913129639935"
      );
      await allowanceallowance.wait();
      StakingFunction4();
      toast.success("Contract approve successfully");
      setEnableButtonLoader4(false);
    } catch (error) {
      console.log("error", error);
      toast.error(error.message);
      setEnableButtonLoader4(false);
    }
    //"115792089237316195423570985008687907853269984665640564039457584007913129639935"
  };
  //----------------------------end Deposit 5

  //---------------Compound earned
  // const [compLoader, setcompLoader] = useState(false)
  // const [compLoader2, setcompLoader2] = useState(false)
  // const [compLoader3, setcompLoader3] = useState(false)
  // const [compLoader4, setcompLoader4] = useState(false)
  // const [compLoaderA, setcompLoaderA] = useState(false)
  // const [compLoaderA2, setcompLoaderA2] = useState(false)
  // const [compLoaderA3, setcompLoaderA3] = useState(false)
  // const [compLoaderA4, setcompLoaderA4] = useState(false)
  const CompoundEarned = async () => {
    setLoader(true);
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      // var mySmartContract = await new web3.eth.Contract(
      //   stakingABI1,
      //   stakingConAddress1
      // );
      // const resGasMethod = await mySmartContract.methods
      //   .compoundReward()
      //   .estimateGas({ from: account });

      // const latestBlock = await web3.eth.getBlock("latest");
      // const blockGas = latestBlock.gasLimit;
      // const finalGas = blockGas * resGasMethod;
      // const finalGasInEther = web3.utils.fromWei(finalGas.toString());
      // const gasPriceToOwner = finalGasInEther;

      const contract = getContract(
        stakingConAddress1,
        stakingABI1,
        library,
        account
      );

      // *********************** Owner
      const AdminOwner = await contract.owner();
      await AdminOwner;
      // *********************** using the promise
      const PriceInBNB = (0.1 / BNBpriceUSD).toFixed(18);
      console.log("PriceInBNB", PriceInBNB);

      // const transfer = await web3.eth.sendTransaction(
      //   {
      //     from: account,
      //     to: AdminOwner,
      //     value: web3.utils.toWei(PriceInBNB.toString()),
      //   },
      //   function async(err, transactionHash) {
      //     if (err) {
      //       console.log("Payment failed Error", err);
      //       return;
      //     } else {
      //     }
      //   }
      // );
      //** important   uint 8 */
      const compoundReward = await contract.compoundReward();
      await compoundReward.wait();
      setLoader(false);
      toast.success("Compound earned successfully");
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };

  const CompoundEarned1 = async () => {
    setLoader(true);
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      const contract = getContract(
        stakingConAddress2,
        stakingABI2,
        library,
        account
      );

      // *********************** Owner
      const AdminOwner = await contract.owner();
      await AdminOwner;
      // *********************** using the promise
      const PriceInBNB = (0.1 / BNBpriceUSD).toFixed(18);
      console.log("PriceInBNB", PriceInBNB);

      // const transfer = await web3.eth.sendTransaction(
      //   {
      //     from: account,
      //     to: AdminOwner,
      //     value: web3.utils.toWei(PriceInBNB.toString()),
      //   },
      //   function async(err, transactionHash) {
      //     if (err) {
      //       console.log("Payment failed Error", err);
      //       return;
      //     } else {
      //     }
      //   }
      // );
      //** important   uint 8 */

      //** important   uint 8 */
      const compoundReward = await contract.compoundReward(0);
      await compoundReward.wait();
      setLoader(false);
      // console.log("compoundReward", compoundReward);
      toast.success("Compound earned successfully");
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };
  const CompoundEarned3 = async () => {
    setLoader(true);
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      const contract = getContract(
        stakingConAddress3,
        stakingABI3,
        library,
        account
      );

      // *********************** Owner
      const AdminOwner = await contract.owner();
      await AdminOwner;
      // *********************** using the promise
      const PriceInBNB = (0.1 / BNBpriceUSD).toFixed(18);
      console.log("PriceInBNB", PriceInBNB);

      // const transfer = await web3.eth.sendTransaction(
      //   {
      //     from: account,
      //     to: AdminOwner,
      //     value: web3.utils.toWei(PriceInBNB.toString()),
      //   },
      //   function async(err, transactionHash) {
      //     if (err) {
      //       console.log("Payment failed Error", err);
      //       return;
      //     } else {
      //     }
      //   }
      // );
      //** important   uint 8 */
      //** important   uint 8 */
      const compoundReward = await contract.compoundReward(1);
      await compoundReward.wait();
      // console.log("compoundReward", compoundReward);
      toast.success("Compound earned successfully");

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };
  const CompoundEarned4 = async () => {
    setLoader(true);
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      const contract = getContract(
        stakingConAddress4,
        stakingABI4,
        library,
        account
      );

      // *********************** Owner
      const AdminOwner = await contract.owner();
      await AdminOwner;
      // *********************** using the promise
      const PriceInBNB = (0.1 / BNBpriceUSD).toFixed(18);
      console.log("PriceInBNB", PriceInBNB);

      // const transfer = await web3.eth.sendTransaction(
      //   {
      //     from: account,
      //     to: AdminOwner,
      //     value: web3.utils.toWei(PriceInBNB.toString()),
      //   },
      //   function async(err, transactionHash) {
      //     if (err) {
      //       console.log("Payment failed Error", err);
      //       return;
      //     } else {
      //     }
      //   }
      // );
      //** important   uint 8 */
      //** important   uint 8 */
      const compoundReward = await contract.compoundReward(0);
      await compoundReward.wait();
      toast.success("Compound earned successfully");

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };

  const CompoundDivident = async () => {
    setLoader(true);
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      const contract = getContract(
        stakingConAddress1,
        stakingABI1,
        library,
        account
      );

      // *********************** Owner
      const AdminOwner = await contract.owner();
      await AdminOwner;
      // *********************** using the promise
      const PriceInBNB = (0.1 / BNBpriceUSD).toFixed(18);
      console.log("PriceInBNB", PriceInBNB);

      // const transfer = await web3.eth.sendTransaction(
      //   {
      //     from: account,
      //     to: AdminOwner,
      //     value: web3.utils.toWei(PriceInBNB.toString()),
      //   },
      //   function async(err, transactionHash) {
      //     if (err) {
      //       console.log("Payment failed Error", err);
      //       return;
      //     } else {
      //     }
      //   }
      // );
      //** important   uint 8 */
      const compoundReward = await contract.compoundDividend();
      await compoundReward.wait();
      toast.success("Compound earned successfully");

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };

  const CompoundDivident1 = async () => {
    setLoader(true);
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      const contract = getContract(
        stakingConAddress2,
        stakingABI2,
        library,
        account
      );

      // *********************** Owner
      const AdminOwner = await contract.owner();
      await AdminOwner;
      // *********************** using the promise
      const PriceInBNB = (0.1 / BNBpriceUSD).toFixed(18);
      console.log("PriceInBNB", PriceInBNB);

      // const transfer = await web3.eth.sendTransaction(
      //   {
      //     from: account,
      //     to: AdminOwner,
      //     value: web3.utils.toWei(PriceInBNB.toString()),
      //   },
      //   function async(err, transactionHash) {
      //     if (err) {
      //       console.log("Payment failed Error", err);
      //       return;
      //     } else {
      //     }
      //   }
      // );
      //** important   uint 8 */
      const compoundReward = await contract.compoundDividend(0);
      await compoundReward.wait();
      toast.success("Compound earned successfully");

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };
  const CompoundDivident3 = async () => {
    setLoader(true);
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      const contract = getContract(
        stakingConAddress3,
        stakingABI3,
        library,
        account
      );

      // *********************** Owner
      const AdminOwner = await contract.owner();
      await AdminOwner;
      // *********************** using the promise
      const PriceInBNB = (0.1 / BNBpriceUSD).toFixed(18);
      console.log("PriceInBNB", PriceInBNB);

      // const transfer = await web3.eth.sendTransaction(
      //   {
      //     from: account,
      //     to: AdminOwner,
      //     value: web3.utils.toWei(PriceInBNB.toString()),
      //   },
      //   function async(err, transactionHash) {
      //     if (err) {
      //       console.log("Payment failed Error", err);
      //       return;
      //     } else {
      //     }
      //   }
      // );
      //** important   uint 8 */
      const compoundReward = await contract.compoundDividend(1);
      await compoundReward.wait();
      toast.success("Compound earned successfully");

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };
  const CompoundDivident4 = async () => {
    setLoader(true);
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      const contract = getContract(
        stakingConAddress4,
        stakingABI4,
        library,
        account
      );

      // *********************** Owner
      const AdminOwner = await contract.owner();
      await AdminOwner;
      // *********************** using the promise
      const PriceInBNB = (0.1 / BNBpriceUSD).toFixed(18);
      console.log("PriceInBNB", PriceInBNB);

      // const transfer = await web3.eth.sendTransaction(
      //   {
      //     from: account,
      //     to: AdminOwner,
      //     value: web3.utils.toWei(PriceInBNB.toString()),
      //   },
      //   function async(err, transactionHash) {
      //     if (err) {
      //       console.log("Payment failed Error", err);
      //       return;
      //     } else {
      //     }
      //   }
      // );
      //** important   uint 8 */
      const compoundReward = await contract.compoundReward(2);
      await compoundReward.wait();
      // console.log("compoundReward", compoundReward);
      toast.success("Compound earned successfully");

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };

  const harvestclaimDividend = async () => {
    setLoader(true);
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      const contract = getContract(
        stakingConAddress1,
        stakingABI1,
        library,
        account
      );
      console.log(contract);
      // *********************** Owner
      const AdminOwner = await contract.owner();
      await AdminOwner;
      // *********************** using the promise
      const PriceInBNB = (0.1 / BNBpriceUSD).toFixed(18);
      console.log("PriceInBNB", PriceInBNB);

      // const transfer = await web3.eth.sendTransaction(
      //   {
      //     from: account,
      //     to: AdminOwner,
      //     value: web3.utils.toWei(PriceInBNB.toString()),
      //   },
      //   function async(err, transactionHash) {
      //     if (err) {
      //       console.log("Payment failed Error", err);
      //       return;
      //     } else {
      //     }
      //   }
      // );
      //** important   uint 8 */

      const claim = await contract.claimDividend();
      await claim.wait();
      toast.success("Harvest claim successfully");
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };

  const harvestclaimDividend2 = async () => {
    setLoader(true);
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      const contract = getContract(
        stakingConAddress2,
        stakingABI2,
        library,
        account
      );
      console.log(contract);
      // *********************** Owner
      const AdminOwner = await contract.owner();
      await AdminOwner;
      // *********************** using the promise
      const PriceInBNB = (0.1 / BNBpriceUSD).toFixed(18);
      console.log("PriceInBNB", PriceInBNB);

      // const transfer = await web3.eth.sendTransaction(
      //   {
      //     from: account,
      //     to: AdminOwner,
      //     value: web3.utils.toWei(PriceInBNB.toString()),
      //   },
      //   function async(err, transactionHash) {
      //     if (err) {
      //       console.log("Payment failed Error", err);
      //       return;
      //     } else {
      //     }
      //   }
      // );
      //** important   uint 8 */

      const claim = await contract.claimDividend(0);
      await claim.wait();
      toast.success("Harvest claim successfully");
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };

  const harvestclaimDividend3 = async () => {
    setLoader(true);
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      const contract = getContract(
        stakingConAddress3,
        stakingABI3,
        library,
        account
      );
      console.log(contract);
      // *********************** Owner
      const AdminOwner = await contract.owner();
      await AdminOwner;
      // *********************** using the promise
      const PriceInBNB = (0.1 / BNBpriceUSD).toFixed(18);
      console.log("PriceInBNB", PriceInBNB);

      // const transfer = await web3.eth.sendTransaction(
      //   {
      //     from: account,
      //     to: AdminOwner,
      //     value: web3.utils.toWei(PriceInBNB.toString()),
      //   },
      //   function async(err, transactionHash) {
      //     if (err) {
      //       console.log("Payment failed Error", err);
      //       return;
      //     } else {
      //     }
      //   }
      // );
      //** important   uint 8 */

      const claim = await contract.claimDividend(1);
      await claim.wait();
      toast.success("Harvest claim successfully");
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };

  const harvestclaimDividend4 = async () => {
    setLoader(true);
    const web3 = (window.web3 = new Web3(window.ethereum));
    try {
      const contract = getContract(
        stakingConAddress4,
        stakingABI4,
        library,
        account
      );
      console.log("===============4", contract);
      // *********************** Owner
      const AdminOwner = await contract.owner();
      await AdminOwner;
      // *********************** using the promise
      const PriceInBNB = (0.1 / BNBpriceUSD).toFixed(18);
      console.log("PriceInBNB", PriceInBNB);

      // const transfer = await web3.eth.sendTransaction(
      //   {
      //     from: account,
      //     to: AdminOwner,
      //     value: web3.utils.toWei(PriceInBNB.toString()),
      //   },
      //   function async(err, transactionHash) {
      //     if (err) {
      //       console.log("Payment failed Error", err);
      //       return;
      //     } else {
      //     }
      //   }
      // );
      // ** important   uint 8 */

      const claim = await contract.claimDividend(0);
      await claim.wait();
      toast.success("Harvest claim successfully");
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };

  // third staking

  useEffect(() => {
    if (chainId === 56) {
      FarmFunctions();
      StakingFunction();
      StakingFunction1();
      StakingFunction3();
      StakingFunction4();
    }
  }, [account]);

  useEffect(() => {
    if (chainId === 97) {
      FarmFunctions();
    }
  }, [account]);
  return (
    <>
      <Box className={classes.bannerBox}>
        <Box>
          <Typography variant="h2">
            {/* {tabview} */}
            POOLS
          </Typography>
          <Container
            maxWidth="lg"
            // align="left"
          >
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={4} lg={5}>
                <Box className="TabButtonsBox">
                  <Button
                    className={tabview === "ACTIVE" ? "active" : " "}
                    onClick={() => setTabView("ACTIVE")}
                  >
                    Active
                  </Button>
                  {/* <Button
                    className={tabview === "FARMS" ? "active" : " "}
                    onClick={() => setTabView("FARMS")}
                  >
                    Farms
                  </Button> */}
                  <Button
                    className={tabview === "INACTIVE" ? "active" : " "}
                    onClick={() => setTabView("INACTIVE")}
                  >
                    InActive
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={8} lg={7}>
                {/* <ToogleSwitch lable="Stake Only"/> */}
                <Box align="right">
                  {tabview !== "INACTIVE" && (
                    <FormControlLabel
                      style={{ color: "white" }}
                      control={
                        <Switch
                          checked={isStake}
                          onChange={(e) => setIsStake(e.target.checked)}
                          name="MyStake"
                          color="default"
                          style={{ color: "#EFAB00" }}
                        />
                      }
                      label="Staked Only"
                    />
                  )}
                  <IconButton
                    color="secondary"
                    aria-label="add an alarm"
                    onClick={() => setPageView2("list")}
                    className={classes.inputnew}
                  >
                    <MenuIcon
                      className={
                        pageView === "list"
                          ? `${classes.iconButton} active`
                          : classes.iconButton
                      }
                    />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    aria-label="add an alarm"
                    onClick={() => setPageView2("grid")}
                    className={classes.inputnew}
                  >
                    <AppsIcon
                      className={
                        pageView === "grid"
                          ? `${classes.iconButton} active`
                          : classes.iconButton
                      }
                    />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box className="TabButtonsContant">
                  {tabview === "INACTIVE" ? (
                    <Grid container spacing={2}>
                      {/*  ***************************  Demo -2  *************************** INACTIVE  *************************** */}
                      {bonusEndBlock === 0 && (
                        <Grid
                          item
                          xs={12}
                          md={pageView2 === "list" ? 12 : 6}
                          sm={pageView2 === "list" ? 12 : 6}
                          lg={pageView2 === "list" ? 12 : 3}
                          // key={i}
                          className={
                            pageView2 === "list" ? "listView" : "gridview"
                          }
                        >
                          {/* {console.log("pageView2", pageView2)} */}
                          <StakingCards
                            stakeTokenAddress={stakeTokenAddress}
                            rewardTokenAddress={rewardTokenAddress}
                            loader={loader}
                            pageView2={pageView2}
                            // <KingstakeCard1
                            latestBlock={latestBlock}
                            stakeAmount={userInfo}
                            duration={0}
                            balanceOf={balanceOf}
                            allowanceAmmount={
                              allowanceAmmount
                                ? allowanceAmmount.toString()
                                : "0"
                            }
                            getOwner={getOwner}
                            EnableButtonMaxApprove={EnableButtonMaxApprove}
                            EnableButtonLoader={EnableButtonLoader}
                            bonusEndBlock={bonusEndBlock}
                            harvestclaimDividend={harvestclaimDividend}
                            StakingHarvest={StakingHarvestEarned}
                            HarvestDividend={HarvestDividend}
                            CompoundEarned={CompoundEarned}
                            CompoundDivident={CompoundDivident}
                            nextHarvestUntil={nextHarvestUntil}
                            nextWithdrawUntil={nextWithdrawUntil}
                            account={chainId === 56 ? account : null}
                            user={user}
                            StakingDeposit={StakingDeposit}
                            StakingWidthdraw={StakingWidthdraw}
                            totalStaked1={totalStaked}
                            pendingReward={pendingReward}
                            pendingDividends={pendingDividends}
                            stakeName={stakeName}
                            earned={earned ? earned : "--"}
                            reflected={reflected ? reflected : "--"}
                            APY={rewardPerBlock}
                            rewardPerBlockBigNumber={rewardPerBlockBigNumber}
                            stakingConAddress={stakingConAddress1}
                            buttonLoader={buttonLoader}
                            harvestLoader={harvestLoader}
                            depositFee={depositFee}
                            widthdrawFee={widthdrawFee}
                            type="manual"
                          />
                        </Grid>
                      )}
                      {/*  ***************************  Demo -2  *************************** INACTIVE  *************************** */}
                      {bonusEndBlock2 === 0 && (
                        <Grid
                          item
                          xs={12}
                          md={pageView2 === "list" ? 12 : 6}
                          sm={pageView2 === "list" ? 12 : 6}
                          lg={pageView2 === "list" ? 12 : 3}
                          // key={i}
                          className={
                            pageView2 === "list" ? "listView" : "gridview"
                          }
                        >
                          {/* {console.log("pageView2", pageView2)} */}
                          <StakingCards
                            stakeTokenAddress={stakeTokenAddress2}
                            rewardTokenAddress={rewardTokenAddress2}
                            loader={loader}
                            pageView2={pageView2}
                            // <KingstakeCard1
                            latestBlock={latestBlock}
                            stakeAmount={userInfo2}
                            duration={duration1}
                            balanceOf={balanceOf2}
                            allowanceAmmount={
                              allowanceAmmount2
                                ? allowanceAmmount2.toString()
                                : "0"
                            }
                            getOwner={getOwner2}
                            EnableButtonLoader={EnableButtonLoader2}
                            EnableButtonMaxApprove={EnableButtonMaxApprove2}
                            bonusEndBlock={bonusEndBlock2}
                            harvestclaimDividend={harvestclaimDividend2}
                            StakingHarvest={StakingHarvestEarned1}
                            // HarvestEarned={HarvestEarned}
                            CompoundEarned={CompoundEarned1}
                            CompoundDivident={CompoundDivident1}
                            nextHarvestUntil={nextHarvestUntil1}
                            nextWithdrawUntil={nextWithdrawUntil1}
                            account={chainId === 56 ? account : null}
                            user={user}
                            StakingDeposit={StakingDeposit1}
                            StakingWidthdraw={StakingWidthdraw1}
                            totalStaked1={totalStaked1}
                            pendingReward={pendingReward1}
                            pendingDividends={pendingDividends1}
                            stakeName={stakeName2}
                            earned={earned2 ? earned2 : "--"}
                            reflected={reflected2 ? reflected2 : "--"}
                            APY={rewardPerBlock1}
                            rewardPerBlockBigNumber={rewardPerBlockBigNumber1}
                            stakingConAddress={stakingConAddress2}
                            buttonLoader={buttonLoader}
                            harvestLoader={harvestLoader}
                            depositFee={depositFee1}
                            widthdrawFee={widthdrawFee1}
                            type="lockup"
                          />
                        </Grid>
                      )}
                      {/*  ***************************  Demo -2  *************************** INACTIVE  *************************** */}
                      {bonusEndBlock3 === 0 && (
                        <Grid
                          item
                          xs={12}
                          md={pageView2 === "list" ? 12 : 6}
                          sm={pageView2 === "list" ? 12 : 6}
                          lg={pageView2 === "list" ? 12 : 3}
                          // key={i}
                          className={
                            pageView2 === "list" ? "listView" : "gridview"
                          }
                        >
                          {/* {console.log("pageView2", pageView2)} */}
                          <StakingCards
                            stakeTokenAddress={stakeTokenAddress3}
                            rewardTokenAddress={rewardTokenAddress3}
                            loader={loader}
                            pageView2={pageView2}
                            // <KingstakeCard1
                            latestBlock={latestBlock}
                            stakeAmount={userInfo3}
                            duration={duration3}
                            balanceOf={balanceOf3}
                            allowanceAmmount={
                              allowanceAmmount3
                                ? allowanceAmmount3.toString()
                                : "0"
                            }
                            getOwner={getOwner3}
                            EnableButtonLoader={EnableButtonLoader3}
                            EnableButtonMaxApprove={EnableButtonMaxApprove3}
                            bonusEndBlock={bonusEndBlock3}
                            harvestclaimDividend={harvestclaimDividend3}
                            StakingHarvest={StakingHarvestEarned3}
                            // HarvestEarned={HarvestEarned}
                            CompoundEarned={CompoundEarned3}
                            CompoundDivident={CompoundDivident3}
                            nextHarvestUntil={nextHarvestUntil3}
                            nextWithdrawUntil={nextWithdrawUntil3}
                            account={chainId === 56 ? account : null}
                            user={user}
                            StakingDeposit={StakingDeposit3}
                            StakingWidthdraw={StakingWidthdraw3}
                            totalStaked1={totalStaked3}
                            pendingReward={pendingReward3}
                            pendingDividends={pendingDividends3}
                            stakeName={stakeName3}
                            earned={earned3 ? earned3 : "--"}
                            reflected={reflected3 ? reflected3 : "--"}
                            APY={rewardPerBlock3}
                            rewardPerBlockBigNumber={rewardPerBlockBigNumber3}
                            stakingConAddress={stakingConAddress3}
                            buttonLoader={buttonLoader}
                            harvestLoader={harvestLoader}
                            depositFee={depositFee3}
                            widthdrawFee={widthdrawFee3}
                            type="lockup"
                          />
                        </Grid>
                      )}
                      {/*  ***************************  Demo -2  *************************** INACTIVE  *************************** */}
                      {bonusEndBlock4 === 0 && (
                        <Grid
                          item
                          xs={12}
                          md={pageView2 === "list" ? 12 : 6}
                          sm={pageView2 === "list" ? 12 : 6}
                          lg={pageView2 === "list" ? 12 : 3}
                          // key={i}
                          className={
                            pageView2 === "list" ? "listView" : "gridview"
                          }
                        >
                          {/* {console.log("pageView2", pageView2)}****************** userInfo4 ********* */}
                          <StakingCards
                            stakeTokenAddress={stakeTokenAddress4}
                            rewardTokenAddress={rewardTokenAddress4}
                            loader={loader}
                            pageView2={pageView2}
                            // <KingstakeCard1
                            stakeAmount={userInfo4}
                            duration={duration4}
                            balanceOf={balanceOf4}
                            allowanceAmmount={
                              allowanceAmmount4
                                ? allowanceAmmount4.toString()
                                : "0"
                            }
                            latestBlock={latestBlock}
                            getOwner={getOwner4}
                            EnableButtonLoader={EnableButtonLoader4}
                            EnableButtonMaxApprove={EnableButtonMaxApprove4}
                            bonusEndBlock={bonusEndBlock4}
                            harvestclaimDividend={harvestclaimDividend4}
                            StakingHarvest={StakingHarvestEarned4}
                            // HarvestEarned={HarvestEarned}
                            CompoundEarned={CompoundEarned4}
                            CompoundDivident={CompoundDivident4}
                            nextHarvestUntil={nextHarvestUntil4}
                            nextWithdrawUntil={nextWithdrawUntil4}
                            account={chainId === 56 ? account : null}
                            user={user}
                            StakingDeposit={StakingDeposit4}
                            StakingWidthdraw={StakingWidthdraw4}
                            totalStaked1={totalStaked4}
                            pendingReward={pendingReward4}
                            pendingDividends={pendingDividends4}
                            stakeName={stakeName4}
                            earned={earned4 ? earned4 : "--"}
                            reflected={reflected4 ? reflected4 : "--"}
                            APY={rewardPerBlock4}
                            rewardPerBlockBigNumber={rewardPerBlockBigNumber4}
                            stakingConAddress={stakingConAddress4}
                            buttonLoader={buttonLoader}
                            harvestLoader={harvestLoader}
                            depositFee={depositFee4}
                            widthdrawFee={widthdrawFee4}
                            type="lockup"
                          />
                        </Grid>
                      )}
                      {bonusEndBlock !== 0 &&
                        bonusEndBlock2 !== 0 &&
                        bonusEndBlock3 !== 0 &&
                        bonusEndBlock4 !== 0 && (
                          <Box
                            style={{
                              textAlign: "center",
                              marginTop: "30px",
                              width: "100%",
                            }}
                          >
                            <Typography variant="h4" style={{ color: "#fff" }}>
                              {" "}
                              No Pools Inactive
                            </Typography>
                          </Box>
                        )}
                    </Grid>
                  ) : (
                    ""
                  )}
                  {tabview === "ACTIVE" ? (
                    <Grid container spacing={2}>
                      {/* -----Testing --------------Purpose----------------Demo -----------For Grid---------------------- */}

                      {/* ---------------------Demo 1-----------For Grid---------------------- */}
                      {!isStake && (
                        <Grid
                          item
                          xs={12}
                          md={pageView2 === "list" ? 12 : 6}
                          sm={pageView2 === "list" ? 12 : 6}
                          lg={pageView2 === "list" ? 12 : 3}
                          // key={i}
                          className={
                            pageView2 === "list" ? "listView" : "gridview"
                          }
                        >
                          {/* {console.log("pageView2", pageView2)} */}
                          <StakingCards
                            stakeTokenAddress={stakeTokenAddress}
                            rewardTokenAddress={rewardTokenAddress}
                            loader={loader}
                            pageView2={pageView2}
                            // <KingstakeCard1
                            latestBlock={latestBlock}
                            stakeAmount={userInfo}
                            duration={0}
                            balanceOf={balanceOf}
                            allowanceAmmount={
                              allowanceAmmount
                                ? allowanceAmmount.toString()
                                : "0"
                            }
                            getOwner={getOwner}
                            EnableButtonMaxApprove={EnableButtonMaxApprove}
                            EnableButtonLoader={EnableButtonLoader}
                            bonusEndBlock={bonusEndBlock}
                            harvestclaimDividend={harvestclaimDividend}
                            StakingHarvest={StakingHarvestEarned}
                            HarvestDividend={HarvestDividend}
                            CompoundEarned={CompoundEarned}
                            CompoundDivident={CompoundDivident}
                            nextHarvestUntil={nextHarvestUntil}
                            nextWithdrawUntil={nextWithdrawUntil}
                            account={chainId === 56 ? account : null}
                            user={user}
                            StakingDeposit={StakingDeposit}
                            StakingWidthdraw={StakingWidthdraw}
                            totalStaked1={totalStaked}
                            pendingReward={pendingReward}
                            pendingDividends={pendingDividends}
                            stakeName={stakeName}
                            earned={earned ? earned : "--"}
                            reflected={reflected ? reflected : "--"}
                            APY={rewardPerBlock}
                            rewardPerBlockBigNumber={rewardPerBlockBigNumber}
                            stakingConAddress={stakingConAddress1}
                            buttonLoader={buttonLoader}
                            harvestLoader={harvestLoader}
                            depositFee={depositFee}
                            widthdrawFee={widthdrawFee}
                            type="manual"
                          />
                        </Grid>
                      )}
                      {isStake && userInfo > 0 && (
                        <Grid
                          item
                          xs={12}
                          md={pageView2 === "list" ? 12 : 6}
                          sm={pageView2 === "list" ? 12 : 6}
                          lg={pageView2 === "list" ? 12 : 3}
                          // key={i}
                          className={
                            pageView2 === "list" ? "listView" : "gridview"
                          }
                        >
                          {/* {console.log("pageView2", pageView2)} */}
                          <StakingCards
                            stakeTokenAddress={stakeTokenAddress}
                            rewardTokenAddress={rewardTokenAddress}
                            loader={loader}
                            pageView2={pageView2}
                            // <KingstakeCard1
                            latestBlock={latestBlock}
                            stakeAmount={userInfo}
                            duration={0}
                            balanceOf={balanceOf}
                            allowanceAmmount={
                              allowanceAmmount
                                ? allowanceAmmount.toString()
                                : "0"
                            }
                            getOwner={getOwner}
                            EnableButtonMaxApprove={EnableButtonMaxApprove}
                            EnableButtonLoader={EnableButtonLoader}
                            bonusEndBlock={bonusEndBlock}
                            harvestclaimDividend={harvestclaimDividend}
                            StakingHarvest={StakingHarvestEarned}
                            HarvestDividend={HarvestDividend}
                            CompoundEarned={CompoundEarned}
                            CompoundDivident={CompoundDivident}
                            nextHarvestUntil={nextHarvestUntil}
                            nextWithdrawUntil={nextWithdrawUntil}
                            account={chainId === 56 ? account : null}
                            user={user}
                            StakingDeposit={StakingDeposit}
                            StakingWidthdraw={StakingWidthdraw}
                            totalStaked1={totalStaked}
                            pendingReward={pendingReward}
                            pendingDividends={pendingDividends}
                            stakeName={stakeName}
                            earned={earned ? earned : "--"}
                            reflected={reflected ? reflected : "--"}
                            APY={rewardPerBlock}
                            rewardPerBlockBigNumber={rewardPerBlockBigNumber}
                            stakingConAddress={stakingConAddress1}
                            buttonLoader={buttonLoader}
                            harvestLoader={harvestLoader}
                            depositFee={depositFee}
                            widthdrawFee={widthdrawFee}
                            type="manual"
                          />
                        </Grid>
                      )}

                      {/* ---------------------Demo -2----------For Grid---------------------- */}

                      {isStake && userInfo2 > 0 && (
                        <Grid
                          item
                          xs={12}
                          md={pageView2 === "list" ? 12 : 6}
                          sm={pageView2 === "list" ? 12 : 6}
                          lg={pageView2 === "list" ? 12 : 3}
                          // key={i}
                          className={
                            pageView2 === "list" ? "listView" : "gridview"
                          }
                        >
                          {/* {console.log("pageView2", pageView2)} */}
                          <StakingCards
                            stakeTokenAddress={stakeTokenAddress2}
                            rewardTokenAddress={rewardTokenAddress2}
                            loader={loader}
                            pageView2={pageView2}
                            // <KingstakeCard1
                            latestBlock={latestBlock}
                            stakeAmount={userInfo2}
                            duration={duration1}
                            balanceOf={balanceOf2}
                            allowanceAmmount={
                              allowanceAmmount2
                                ? allowanceAmmount2.toString()
                                : "0"
                            }
                            getOwner={getOwner2}
                            EnableButtonLoader={EnableButtonLoader2}
                            EnableButtonMaxApprove={EnableButtonMaxApprove2}
                            bonusEndBlock={bonusEndBlock2}
                            harvestclaimDividend={harvestclaimDividend2}
                            StakingHarvest={StakingHarvestEarned1}
                            // HarvestEarned={HarvestEarned}
                            CompoundEarned={CompoundEarned1}
                            CompoundDivident={CompoundDivident1}
                            nextHarvestUntil={nextHarvestUntil1}
                            nextWithdrawUntil={nextWithdrawUntil1}
                            account={chainId === 56 ? account : null}
                            user={user}
                            StakingDeposit={StakingDeposit1}
                            StakingWidthdraw={StakingWidthdraw1}
                            totalStaked1={totalStaked1}
                            pendingReward={pendingReward1}
                            pendingDividends={pendingDividends1}
                            stakeName={stakeName2}
                            earned={earned2 ? earned2 : "--"}
                            reflected={reflected2 ? reflected2 : "--"}
                            APY={rewardPerBlock1}
                            rewardPerBlockBigNumber={rewardPerBlockBigNumber1}
                            stakingConAddress={stakingConAddress2}
                            buttonLoader={buttonLoader}
                            harvestLoader={harvestLoader}
                            depositFee={depositFee1}
                            widthdrawFee={widthdrawFee1}
                            type="lockup"
                          />
                        </Grid>
                      )}

                      {!isStake && (
                        <Grid
                          item
                          xs={12}
                          md={pageView2 === "list" ? 12 : 6}
                          sm={pageView2 === "list" ? 12 : 6}
                          lg={pageView2 === "list" ? 12 : 3}
                          // key={i}
                          className={
                            pageView2 === "list" ? "listView" : "gridview"
                          }
                        >
                          {/* {console.log("pageView2", pageView2)} */}
                          <StakingCards
                            stakeTokenAddress={stakeTokenAddress2}
                            rewardTokenAddress={rewardTokenAddress2}
                            loader={loader}
                            pageView2={pageView2}
                            // <KingstakeCard1
                            latestBlock={latestBlock}
                            stakeAmount={userInfo2}
                            duration={duration1}
                            balanceOf={balanceOf2}
                            allowanceAmmount={
                              allowanceAmmount2
                                ? allowanceAmmount2.toString()
                                : "0"
                            }
                            getOwner={getOwner2}
                            EnableButtonLoader={EnableButtonLoader2}
                            EnableButtonMaxApprove={EnableButtonMaxApprove2}
                            bonusEndBlock={bonusEndBlock2}
                            harvestclaimDividend={harvestclaimDividend2}
                            StakingHarvest={StakingHarvestEarned1}
                            // HarvestEarned={HarvestEarned}
                            CompoundEarned={CompoundEarned1}
                            CompoundDivident={CompoundDivident1}
                            nextHarvestUntil={nextHarvestUntil1}
                            nextWithdrawUntil={nextWithdrawUntil1}
                            account={chainId === 56 ? account : null}
                            user={user}
                            StakingDeposit={StakingDeposit1}
                            StakingWidthdraw={StakingWidthdraw1}
                            totalStaked1={totalStaked1}
                            pendingReward={pendingReward1}
                            pendingDividends={pendingDividends1}
                            stakeName={stakeName2}
                            earned={earned2 ? earned2 : "--"}
                            reflected={reflected2 ? reflected2 : "--"}
                            APY={rewardPerBlock1}
                            rewardPerBlockBigNumber={rewardPerBlockBigNumber1}
                            stakingConAddress={stakingConAddress2}
                            buttonLoader={buttonLoader}
                            harvestLoader={harvestLoader}
                            depositFee={depositFee1}
                            widthdrawFee={widthdrawFee1}
                            type="lockup"
                          />
                        </Grid>
                      )}
                      {/* ---------------------Demo ----3-------For Grid---------------------- */}

                      {isStake && Number(userInfo3) > 0 && (
                        <Grid
                          item
                          xs={12}
                          md={pageView2 === "list" ? 12 : 6}
                          sm={pageView2 === "list" ? 12 : 6}
                          lg={pageView2 === "list" ? 12 : 3}
                          // key={i}
                          className={
                            pageView2 === "list" ? "listView" : "gridview"
                          }
                        >
                          {/* {console.log("pageView2", pageView2)} */}
                          <StakingCards
                            stakeTokenAddress={stakeTokenAddress3}
                            rewardTokenAddress={rewardTokenAddress3}
                            loader={loader}
                            pageView2={pageView2}
                            // <KingstakeCard1
                            latestBlock={latestBlock}
                            stakeAmount={userInfo3}
                            duration={duration3}
                            balanceOf={balanceOf3}
                            allowanceAmmount={
                              allowanceAmmount3
                                ? allowanceAmmount3.toString()
                                : "0"
                            }
                            getOwner={getOwner3}
                            EnableButtonLoader={EnableButtonLoader3}
                            EnableButtonMaxApprove={EnableButtonMaxApprove3}
                            bonusEndBlock={bonusEndBlock3}
                            harvestclaimDividend={harvestclaimDividend3}
                            StakingHarvest={StakingHarvestEarned3}
                            // HarvestEarned={HarvestEarned}
                            CompoundEarned={CompoundEarned3}
                            CompoundDivident={CompoundDivident3}
                            nextHarvestUntil={nextHarvestUntil3}
                            nextWithdrawUntil={nextWithdrawUntil3}
                            account={chainId === 56 ? account : null}
                            user={user}
                            StakingDeposit={StakingDeposit3}
                            StakingWidthdraw={StakingWidthdraw3}
                            totalStaked1={totalStaked3}
                            pendingReward={pendingReward3}
                            pendingDividends={pendingDividends3}
                            stakeName={stakeName3}
                            earned={earned3 ? earned3 : "--"}
                            reflected={reflected3 ? reflected3 : "--"}
                            APY={rewardPerBlock3}
                            rewardPerBlockBigNumber={rewardPerBlockBigNumber3}
                            stakingConAddress={stakingConAddress3}
                            buttonLoader={buttonLoader}
                            harvestLoader={harvestLoader}
                            depositFee={depositFee3}
                            widthdrawFee={widthdrawFee3}
                            type="lockup"
                          />
                        </Grid>
                      )}

                      {!isStake && (
                        <Grid
                          item
                          xs={12}
                          md={pageView2 === "list" ? 12 : 6}
                          sm={pageView2 === "list" ? 12 : 6}
                          lg={pageView2 === "list" ? 12 : 3}
                          // key={i}
                          className={
                            pageView2 === "list" ? "listView" : "gridview"
                          }
                        >
                          {/* {console.log("pageView2", pageView2)} */}
                          <StakingCards
                            stakeTokenAddress={stakeTokenAddress3}
                            rewardTokenAddress={rewardTokenAddress3}
                            loader={loader}
                            pageView2={pageView2}
                            // <KingstakeCard1
                            latestBlock={latestBlock}
                            stakeAmount={userInfo3}
                            duration={duration3}
                            balanceOf={balanceOf3}
                            allowanceAmmount={
                              allowanceAmmount3
                                ? allowanceAmmount3.toString()
                                : "0"
                            }
                            getOwner={getOwner3}
                            EnableButtonLoader={EnableButtonLoader3}
                            EnableButtonMaxApprove={EnableButtonMaxApprove3}
                            bonusEndBlock={bonusEndBlock3}
                            harvestclaimDividend={harvestclaimDividend3}
                            StakingHarvest={StakingHarvestEarned3}
                            // HarvestEarned={HarvestEarned}
                            CompoundEarned={CompoundEarned3}
                            CompoundDivident={CompoundDivident3}
                            nextHarvestUntil={nextHarvestUntil3}
                            nextWithdrawUntil={nextWithdrawUntil3}
                            account={chainId === 56 ? account : null}
                            user={user}
                            StakingDeposit={StakingDeposit3}
                            StakingWidthdraw={StakingWidthdraw3}
                            totalStaked1={totalStaked3}
                            pendingReward={pendingReward3}
                            pendingDividends={pendingDividends3}
                            stakeName={stakeName3}
                            earned={earned3 ? earned3 : "--"}
                            reflected={reflected3 ? reflected3 : "--"}
                            APY={rewardPerBlock3}
                            rewardPerBlockBigNumber={rewardPerBlockBigNumber3}
                            stakingConAddress={stakingConAddress3}
                            buttonLoader={buttonLoader}
                            harvestLoader={harvestLoader}
                            depositFee={depositFee3}
                            widthdrawFee={widthdrawFee3}
                            type="lockup"
                          />
                        </Grid>
                      )}
                      {/* ---------------------Demo ----4-------For Grid---------------------- */}
                      {/* {!isStake && (
                        <Grid
                          item
                          xs={12}
                          md={pageView2 === "list" ? 12 : 6}
                          sm={pageView2 === "list" ? 12 : 6}
                          lg={pageView2 === "list" ? 12 : 3}
                          // key={i}
                          className={
                            pageView2 === "list" ? "listView" : "gridview"
                          }
                        >
                          <StakingCards
                            stakeTokenAddress={stakeTokenAddress4}
                            rewardTokenAddress={rewardTokenAddress4}
                            loader={loader}
                            pageView2={pageView2}
                            // <KingstakeCard1
                            stakeAmount={userInfo4}
                            duration={duration4}
                            balanceOf={balanceOf4}
                            allowanceAmmount={
                              allowanceAmmount4
                                ? allowanceAmmount4.toString()
                                : "0"
                            }
                            latestBlock={latestBlock}
                            getOwner={getOwner4}
                            EnableButtonLoader={EnableButtonLoader4}
                            EnableButtonMaxApprove={EnableButtonMaxApprove4}
                            bonusEndBlock={bonusEndBlock4}
                            harvestclaimDividend={harvestclaimDividend4}
                            StakingHarvest={StakingHarvestEarned4}
                            // HarvestEarned={HarvestEarned}
                            CompoundEarned={CompoundEarned4}
                            CompoundDivident={CompoundDivident4}
                            nextHarvestUntil={nextHarvestUntil4}
                            nextWithdrawUntil={nextWithdrawUntil4}
                            account={chainId === 56 ? account : null}
                            user={user}
                            StakingDeposit={StakingDeposit4}
                            StakingWidthdraw={StakingWidthdraw4}
                            totalStaked1={totalStaked4}
                            pendingReward={pendingReward4}
                            pendingDividends={pendingDividends4}
                            stakeName={stakeName4}
                            earned={earned4 ? earned4 : "--"}
                            reflected={reflected4 ? reflected4 : "--"}
                            APY={rewardPerBlock4}
                            rewardPerBlockBigNumber={rewardPerBlockBigNumber4}
                            stakingConAddress={stakingConAddress4}
                            buttonLoader={buttonLoader}
                            harvestLoader={harvestLoader}
                            depositFee={depositFee4}
                            widthdrawFee={widthdrawFee4}
                            type="lockup"
                          />
                        </Grid>
                      )} */}
                      {/* {isStake && Number(userInfo4) > 0 && (
                        <Grid
                          item
                          xs={12}
                          md={pageView2 === "list" ? 12 : 6}
                          sm={pageView2 === "list" ? 12 : 6}
                          lg={pageView2 === "list" ? 12 : 3}
                          // key={i}
                          className={
                            pageView2 === "list" ? "listView" : "gridview"
                          }
                        >
                          <StakingCards
                            stakeTokenAddress={stakeTokenAddress4}
                            rewardTokenAddress={rewardTokenAddress4}
                            loader={loader}
                            pageView2={pageView2}
                            // <KingstakeCard1
                            stakeAmount={userInfo4}
                            duration={duration4}
                            balanceOf={balanceOf4}
                            allowanceAmmount={
                              allowanceAmmount4
                                ? allowanceAmmount4.toString()
                                : "0"
                            }
                            latestBlock={latestBlock}
                            getOwner={getOwner4}
                            EnableButtonLoader={EnableButtonLoader4}
                            EnableButtonMaxApprove={EnableButtonMaxApprove4}
                            bonusEndBlock={bonusEndBlock4}
                            harvestclaimDividend={harvestclaimDividend4}
                            StakingHarvest={StakingHarvestEarned4}
                            // HarvestEarned={HarvestEarned}
                            CompoundEarned={CompoundEarned4}
                            CompoundDivident={CompoundDivident4}
                            nextHarvestUntil={nextHarvestUntil4}
                            nextWithdrawUntil={nextWithdrawUntil4}
                            account={chainId === 56 ? account : null}
                            user={user}
                            StakingDeposit={StakingDeposit4}
                            StakingWidthdraw={StakingWidthdraw4}
                            totalStaked1={totalStaked4}
                            pendingReward={pendingReward4}
                            pendingDividends={pendingDividends4}
                            stakeName={stakeName4}
                            earned={earned4 ? earned4 : "--"}
                            reflected={reflected4 ? reflected4 : "--"}
                            APY={rewardPerBlock4}
                            rewardPerBlockBigNumber={rewardPerBlockBigNumber4}
                            stakingConAddress={stakingConAddress4}
                            buttonLoader={buttonLoader}
                            harvestLoader={harvestLoader}
                            depositFee={depositFee4}
                            widthdrawFee={widthdrawFee4}
                            type="lockup"
                          />
                        </Grid>
                      )} */}

                      {isStake &&
                        Number(userInfo) === 0 &&
                        Number(userInfo2) === 0 &&
                        Number(userInfo3) === 0 &&
                        Number(userInfo4) === 0 && (
                          <Box
                            style={{
                              textAlign: "center",
                              marginTop: "30px",
                              width: "100%",
                            }}
                          >
                            <Typography variant="h4" style={{ color: "#fff" }}>
                              {" "}
                              No Pools
                            </Typography>
                          </Box>
                        )}
                    </Grid>
                  ) : (
                    ""
                  )}
                  {tabview === "WithDraw" ? <Withdraw isStake={isStake} /> : ""}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box>
          <Typography variant="h2">Farms</Typography>
          <Container maxWidth="lg" align="left">
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={12}>
                <Box className="TabButtonsBox">
                  <Button
                    className={tabview1 === "FARMS" ? "active" : " "}
                    onClick={() => setTabView1("FARMS")}
                  >
                    Active
                  </Button>
                  <Button
                    className={tabview1 === "INACTIVEFARMS" ? "active" : " "}
                    onClick={() => setTabView1("INACTIVEFARMS")}
                  >
                    InActive
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box className="TabButtonsContant">
                  {/* {tabview === "My" ? <MyStake /> : ""}
                  {tabview === "WithDraw" ? <Withdraw /> : ""} */}

                  {tabview1 === "FARMS" ? (
                    <Grid container spacing={2}>
                      {/* ------------------********----Form----********------------------ */}

                      <Grid
                        item
                        xs={12}
                        md={pageView2 === "list" ? 12 : 6}
                        sm={pageView2 === "list" ? 12 : 6}
                        lg={pageView2 === "list" ? 12 : 3}
                        // key={i}
                        className={
                          pageView2 === "list" ? "listView" : "gridview"
                        }
                      >
                        <FarmsCards
                          pageView2={pageView2}
                          latestBlock={latestBlock}
                          stakeAmount={userInfoFarm}
                          duration={0}
                          balanceOf={balanceOfFarm}
                          balanceOfFarmLpToken={balanceOfFarmLpToken}
                          allowanceAmmount={
                            allowanceAmmount ? allowanceAmmount.toString() : "0"
                          }
                          getOwner={getOwner}
                          EnableButtonMaxApprove={EnableButtonMaxApprove}
                          EnableButtonLoader={EnableButtonLoader}
                          bonusEndBlock={bonusEndBlock}
                          harvestclaimDividend={harvestclaimDividend}
                          StakingHarvest={StakingHarvestEarned}
                          HarvestDividend={HarvestDividend}
                          CompoundEarned={CompoundEarned}
                          CompoundDivident={CompoundDivident}
                          nextHarvestUntil={nextHarvestUntil}
                          nextWithdrawUntil={nextWithdrawUntil}
                          account={chainId === 97 ? account : null} //******************8888 removeChaim id for mainnet  */
                          user={user}
                          StakingDeposit={FarmDeposit}
                          StakingWidthdraw={FarmWidthdraw}
                          totalStaked1={totalStaked}
                          pendingReward={balanceOfFarmbrews}
                          pendingDividends={balanceOfFarm}
                          stakeName={stakeNameFarm}
                          earned={earned ? earned : "--"}
                          reflected={reflected ? reflected : "--"}
                          APY={rewardPerBlockFarm}
                          rewardPerBlockBigNumber={rewardPerBlockBigNumberFarm}
                          stakingConAddress={stakingConAddress1}
                          buttonLoader={buttonLoader}
                          harvestLoader={harvestLoader}
                          depositFee={depositFeeFarm}
                          widthdrawFee={widthdrawFeeFarm}
                          type="manual"
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}
