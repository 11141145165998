import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "100px 0px 70px",
    "& .headbox": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& h2": {
        color: "#EFAB00",
       
      },
      "& p": {
        color: "#FFFFFF",
        "@media(max-width:767px)": {
          fontSize: "14px",
        },
        "@media(max-width:767px)": {
          fontSize: "14px",
        },
      },
    },
    "& .mainbox": {
      "& .text": {
        padding: "15px 0px",
        "& h4": {
          textAlign: "inherit",
          color: "#FFFFFF",
        },
        "& p": {
          color: "#FFFFFF",
          "@media(max-width:767px)": {
            fontSize: "14px",
          },
        },
      },
    },
  },
}));

function Terms() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.root}>
        <Box className="headbox">
          <Typography variant="h2">Terms and Conditions</Typography>
          <Box mt={2}>
          <Typography variant="body2">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s. Lorem Ipsum is simply dummy text of the
            printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s.Lorem Ipsum is
            simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy text ever since the
            1500s.
          </Typography>
          </Box>

        </Box>
        <Box className="mainbox">
          <Box className="text">
            <Typography variant="h4">Lorem Dummy Text </Typography>
            <Typography variant="body2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s.
            </Typography>
          </Box>
          <Box className="text">
            <Typography variant="h4">Lorem Dummy Text </Typography>
            <Typography variant="body2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s.Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s.
            </Typography>
          </Box>
          <Box className="text">
            <Typography variant="h4">Lorem Dummy Text </Typography>
            <Typography variant="body2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s.
            </Typography>
          </Box>
          <Box className="text">
            <Typography variant="h4">Lorem Dummy Text </Typography>
            <Typography variant="body2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s.Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s.
            </Typography>
          </Box>
          <Box className="text">
            <Typography variant="h4">Lorem Dummy Text </Typography>
            <Typography variant="body2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. Lorem Ipsum is
              simply dummy text of the printing and typesetting industry. Lorem
              Ipsum has been the industry's standard dummy text ever since the
              1500s.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Terms;
