import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import MetricsChart from "../../components/MetricsChart";
import ApexChart from "../../components/ApexChart";
import MetricsCard from "../../components/MetricsCard";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { stakingConAddress, TokenBinance } from "../../constants";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import MyStake from "./MyStake";
import LiveStake from "./LiveStake";
import Receivers from "./Receivers";
import Senders from "./Senders";
import Withdraw from "./Withdraw";
import { CircularProgressbar } from "react-circular-progressbar";
// import TradingViewGraph from "./TradingViewGraph";
import TradeChart from "../../components/TradeChart";
const useStyles = makeStyles((theme) => ({
  CircularProgressbar: {
    height: "100px",
    width: "100px",
    stroke: "#fff",
    position: "relative",

    "& .CircularProgressbar-path": {
      stroke: "#f6a52d",
      strokeLinecap: "round",
      transition: "stroke-dashoffset 0.5s ease 0s",
    },

    "&.CircularProgressbar-text": {
      fill: "#ffffff",
      fontSize: "20px",
      dominantBaseline: "middle",
      textAnchor: "middle",
    },

    "& svg": {
      display: "flex",
      alignItems: "center",
      justyfycontent: "center",
    },
    "& .CircularProgressbar-text": {
      alignItems: "center",
      position: "absolute",
      transform: "translate(0% , 4%)",
      fill: "#ffffff",
    },
  },

  graphboxtext: {
    // display: "flex",
    // justifyContent: "space-between",
    // display: "flex",
    // justifyContent: "space-between",
    // alignItems: "center",
    // padding: "20px",
    paddingBottom: 0,
    "& h2": {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "58px",
      color: "#FFFFFF",
    },
    "& h3": {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "30px",
      color: "#FFFFFF",
      "@media(max-width:420px)": {
        fontSize: "20px",
      },
    },
    "& h4": {
      paddingTop: "1rem",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "30px",
      color: "#0DD0FF",
    },
  },
  textBox: {
    width: "100%",
    boxShadow: "7px 7px 4px rgb(0 0 0 / 10%)",
    textAlign: "left",
    borderRadius: "16px",
    backdropFilter: "blur(42px)",
    backgroundColor: "#262831",
    padding: "20px",
    height: "100%",
    "& h2": {
      fontSize: "25px",
      fontWeight: "400",
      color: "#fff",
    },
    "& h6": {
      fontSize: "15px",
      fontWeight: "400",
      color: "#fff",
      lineHeight: "35px",
    },
  },
  mainBox: {
    borderRadius: "10px",
    minHeight: "250px",
    overflow: "hidden",
    position: "relative",
    background:
      "linear-gradient( 152.97deg, rgba(255, 255, 255, 0.08) 0%, rgba(232, 66, 76, 0.062) 100%)",
    transition: "0.5s",
    backdropFilter: "blur(42px)",
    padding: "30px 0px 30px",
    "&:hover": {
      transform: "translateY(-10px)",
    },
  },

  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  inputBox: {
    border: "none",
    height: "30px",
    width: "100%",
    background: "rgba(141, 137, 137, 0.2)",
  },

  CoinBox: {
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    "& h5": {
      fontSize: "25px",
      fontWeight: "700",
      color: "#fff",
    },
  },
  cardimg: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  bannerBox: {
    position: "relative",
    padding: " 10px 0px",
    zIndex: " 1",
    padding: " 100px 0 70px",
  },
  textbox: {
    "& h1": {
      fontSize: "40px",
      fontWeight: "bold",
      lineHeight: "76px",
      color: "#FABE25",
      "@media (max-width: 767px)": {
        fontSize: "30px",
      },
    },
    "& p": {
      fontSize: "18px",
      color: "#fff",
    },
  },
  Buttonbox: {
    "@media (max-width: 767px)": {
      textAlign: "center",
    },
  },
}));

export default function Dashboard(props) {
  var classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [tabview, setTabView] = useState("My");
  const usdData = window.sessionStorage.getItem("usd");

  const [market_cap, setUsdprice] = React.useState([]);
  const [volumPrice, setVolumeprice] = React.useState([]);
  const [totalholders, setTotalholders] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { account, library, chainId } = useWeb3React();
  const [dataToDisPlay, setDataToDisplay] = useState({});
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const percentage = 66;

  const marketCapApi = async () => {
    axios({
      method: "GET",
      url: "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=1&sparkline=false&price_change_percentage=1h%2C%2024h",
    })
      .then(async (res) => {
        // console.log("ressssss", res);

        if (res.status === 200) {
          console.log("balance123", res.data);
          setVolumeprice(res.data[0].total_volume);
          // console.log("marketcap111", res.data[0].total_volume);

          // console.log("marketcap", res.data[0].market_cap);
          const searchKey = "BNB";
          const filterFunc = res.data.filter((data) => {
            return data.name.toLowerCase() === searchKey.toLowerCase();
          });
          setUsdprice(filterFunc[0]?.market_cap);
          // console.log("filteredData", filterFunc[0]?.market_cap);
          window.sessionStorage.setItem("marketcap", filterFunc[0]?.market_cap);
          setVolumeprice(filterFunc[0]?.total_volume);
          // window.sessionStorage.setItem(
          //   "volumprice",
          //   filterFunc[0]?.total_volume
          // );

          // const filterdata=res.data.filter((data)=>{
          //   return data?.
          // })
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const TotalHoldersApi = async () => {
    try {
      setLoading(true);
      var page = 1;
      for (let index = 0; index <= page; index++) {
        const datas = await axios(
          `https://api.bscscan.com/api?module=token&action=tokenholderlist&contractaddress=${TokenBinance}&page=${page}&offset=10000000&apikey=MSQGYEXNG19DG6DF6R6KXNJFQ6S2GEFFPW`
        );

        if (datas.data.result.length != 10000) {
          var holders = datas.data.result.length + (page - 1) * 10000;
          // console.log("holders=====>+++", holders);

          setLoading(false);
          setTotalholders(holders);
          window.sessionStorage.setItem("totalholders", holders);

          return holders;
        }
        // console.log("page", page);
        page++;

        // }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    marketCapApi();
    TotalHoldersApi();
  }, []);
  return (
    <>
      <Box className={classes.bannerBox}>
        <Grid container spacing={2} style={{ alignItems: "center" }}>
          {/* <Grid item xs={12} sm={12} lg={12}>
            <Box className={classes.textBox} mt={2}>
              <TradingViewGraph />
            </Box>
          </Grid> */}
          <Grid item xs={12} sm={12} lg={12}>
            <Box className={classes.textBox} mt={2}>
              {/* <MetricsChart /> */}
              <Box className={classes.graphboxtext}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h2">BNB - USDT</Typography>
                  {/* </Box>
                <Box> */}
                  <Typography variant="h3"> $ {usdData}</Typography>
                </Box>
              </Box>
              <TradeChart />
            </Box>
          </Grid>

          {/* <Grid item xs={12} sm={5} lg={4}>
            <Box className={classes.textBox} mt={2}>
              <MetricsCard />
            </Box>
          </Grid> */}
        </Grid>
        <Box mt={2}>
          <Grid container spacing={2} style={{ alignItems: "center" }}>
            <Grid item xs={12} sm={12} lg={12}>
              <Box className={classes.textBox} mt={2}>
                <Typography variant="h6" style={{ color: "#EFAB00" }}>
                  MRF BSC MARKET CAP
                </Typography>
                <Typography variant="h2">${market_cap}</Typography>
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={6} lg={6}>
              <Box className={classes.textBox} mt={2}>
                <Typography variant="h6" style={{ color: "#EFAB00" }}>
                  MRF BSC COST PER TRILLION
                </Typography>
                <Typography variant="h2">$217,407,399.06</Typography>
              </Box>
            </Grid> */}
          </Grid>
        </Box>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={12}>
              <Box className={classes.textBox}>
                <Grid container>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography variant="h6" style={{ color: "#EFAB00" }}>
                      {/* TOTAL VALUE BURNED */}
                      TOTAL HOLDER
                    </Typography>
                    <Typography variant="h2">
                      {loading ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          // ml={2}
                          justifyContent="center"
                        >
                          <small style={{ fontSize: "12px" }}>
                            Calculating....
                          </small>{" "}
                          {/* <ButtonCircularProgre /> */}
                        </Box>
                      ) : (
                        <> {totalholders}</>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography variant="h6" style={{ color: "#EFAB00" }}>
                      {/* TOTAL VALUE STAKED V2 */}
                      TOTAL VOLUME
                    </Typography>
                    <Typography variant="h2">$ {volumPrice}</Typography>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography variant="h6" style={{ color: "#efab00" }}>
                      REWARDS DISTRIBUTION ROUND
                    </Typography>
                    <Grid container>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography variant="h6">fBNB</Typography>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography variant="h6">fUSDT</Typography>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography variant="h6">fBTC</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={6} lg={6} alignItems="center"> */}
            {/* <Box className={classes.textBox} style={{ display:"flex",alignItems: "center",justifyContent:'center' }}>
                <ApexChart />
              </Box> */}
            {/* </Grid> */}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
