import React from "react";
import { Box, makeStyles, Grid } from "@material-ui/core";
// import TextField from "@material-ui/core/TextField";
// import { Link } from "react-router-dom";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import MyStake from "./MyStake";
// import LiveStake from "./LiveStake";
// import Withdraw from "./Withdraw";
import KingstakeCard from "../../components/KingstakeCard";
const useStyles = makeStyles((theme) => ({
  mainBox: {
    borderRadius: "10px",
    minHeight: "250px",
    overflow: "hidden",
    position: "relative",
    background:
      "linear-gradient( 152.97deg, rgba(255, 255, 255, 0.08) 0%, rgba(232, 66, 76, 0.062) 100%)",
    transition: "0.5s",
    backdropFilter: "blur(42px)",
    padding: "30px 0px 30px",
    "&:hover": {
      transform: "translateY(-10px)",
    },
  },
  apyBox: {
    padding: "20px",
    "& h4": {
      fontSize: "15px",
      fontWeight: "700",
      color: "#fff",
    },
    "& h5": {
      fontSize: "15px",
      fontWeight: "700",
      color: "#ffffffcf",
    },
  },

  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  inputBox: {
    border: "none",
    height: "30px",
    width: "100%",
    background: "rgba(141, 137, 137, 0.2)",
  },
  DialogBox: {
    backgroundColor: "#090c16 !important",
    transition: "0.5s",
    position: "relative",
    borderRadius: "10px",
    backdropFilter: "blur(4px)",

    "& h4": {
      fontSize: "15px",
      fontWeight: "700",
      color: "#ffffffab",
    },
    "& h1": {
      fontSize: "30px",
      fontWeight: "bold",
      lineHeight: "76px",
      color: "rgb(246, 165, 45)",
    },
    "& h5": {
      fontSize: "15px",
      fontWeight: "700",
      color: "#fff",
    },
  },
  CoinBox: {
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    "& h5": {
      fontSize: "25px",
      fontWeight: "700",
      color: "#fff",
    },
  },
  cardimg: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  bannerBox: {
    position: "relative",
    zIndex: " 1",
  },
  textbox: {
    "& h1": {
      fontSize: "40px",
      fontWeight: "bold",
      lineHeight: "76px",
      color: "#FABE25",
      "@media (max-width: 767px)": {
        fontSize: "30px",
      },
    },
    "& p": {
      fontSize: "18px",
      color: "#fff",
    },
  },
  Buttonbox: {
    "@media (max-width: 767px)": {
      textAlign: "center",
    },
  },
  rewardbutton: {
    "@media (max-width: 767px)": {
      minWidth: "120px",
    },
  },
}));

const Token = [
  {
    icon: "images/icon.png",
    name: "Fees",
    token: "meta",
    discription:
      "$300 USD in King Shiba token to deploy pre sale contract 0% Token tax to ensure no malicious token dumping like Pink Sale and Dx-Sale   3% Contribution token Fee on each pre sale",
  },
  {
    icon: "images/icons/1.svg",
    name: "King Swap",
    token: "busd",
    discription:
      "Instant access to claim and swap your tokens conveniently through our pre sale page",
  },
  {
    icon: "images/icons/1.svg",
    name: "Token distribution",
    token: "meta",
    discription: "Token distribution checker",
  },
  {
    icon: "images/icons/1.svg",
    name: "Community",
    token: "busd",
    discription: "Community vote on project safety",
  },

  {
    icon: "images/icon.png",
    name: "Fees",
    token: "meta",
    discription:
      "$300 USD in King Shiba token to deploy pre sale contract 0% Token tax to ensure no malicious token dumping like Pink Sale and Dx-Sale   3% Contribution token Fee on each pre sale",
  },
  {
    icon: "images/icons/1.svg",
    name: "King Swap",
    token: "busd",
    discription:
      "Instant access to claim and swap your tokens conveniently through our pre sale page",
  },
  {
    icon: "images/icons/1.svg",
    name: "Token distribution",
    token: "meta",
    discription: "Token distribution checker",
  },
  {
    icon: "images/icons/1.svg",
    name: "Community",
    token: "busd",
    discription: "Community vote on project safety",
  },
];
export default function Dashboard({ isStake }) {
  var classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  // const [tabview, setTabView] = useState("My");
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  return (
    <>
      <Box className={classes.bannerBox}>
        <Box mt={2}>
          <Grid container spacing={2} justifyContent="center">
            {isStake ? (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={3}
                style={{ color: "white", fontSize: "20px" }}
              >
                {"No Data Found"}
              </Grid>
            ) : (
              <>
                {Token.map((data, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={3}
                      key={i}
                      className="walletSet"
                    >
                      <KingstakeCard data={data} type="card" index={i} />
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
