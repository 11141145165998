import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Drawer, IconButton, List, makeStyles, Box } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";
import { GiToken } from "react-icons/gi";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { Navigation } from "react-minimal-side-navigation";
import { IoMdSwap } from "react-icons/io";
import { FaCoins } from "react-icons/fa";
import { AiOutlineSwap } from "react-icons/ai";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
// import { BsFillHandbagFill } from "react-icons/bs";
import { RiAdminFill, RiLock2Fill } from "react-icons/ri";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { GoVerified } from "react-icons/go";
import { AiFillRocket } from "react-icons/ai";
// import { FiTrendingUp } from "react-icons/fi";
import { MdGeneratingTokens } from "react-icons/md";
import { MdDashboard } from "react-icons/md";
import { FaTelegramPlane } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { ImDatabase } from "react-icons/im";
import { AiOutlineAudit } from "react-icons/ai";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { UserContext } from "../../context/User";
import { useWeb3React } from "@web3-react/core";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    paddingLeft: "20px",
    height: "100vh",
    position: "fixed",
    background: "#262831",
    zIndex: "9999",
    // borderRight: "1px solid #1D1D1D",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "&::-webkit-scrollbar": {
      width: " 3px",
    },
    "&::-webkit-scrollbar-track ": {
      background: " #f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: " #888",
    },
    "&::-webkit-scrollbar-thumb:hover ": {
      background: "#555",
    },
  },
  drawerClose: {
    borderRight: "0",
    background: "#262831",
    paddingLeft: "8px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 20,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
    },
    "&::-webkit-scrollbar": {
      width: " 3px",
    },
    "& .side-navigation-panel": {
      pointerEvents: "none",
      [theme.breakpoints.down("sm")]: {
        pointerEvents: "auto",
      },
    },
    "&::-webkit-scrollbar-track ": {
      background: " #f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: " #888",
    },
    "&::-webkit-scrollbar-thumb:hover ": {
      background: "#555",
    },
    "& .side-navigation-panel-select-option-wrap .side-navigation-panel-select-option-text":
      {
        display: "none",
        [theme.breakpoints.down("sm")]: {
          display: "block",
        },
      },
    "& .side-navigation-panel ul ul": {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  /* sidebarList: {
    marginTop: theme.spacing(6),
  }, */
  mobileBackButton: {
    display: " flex",
    position: "relative",
    marginTop: "18px",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "24px",
    height: "35px",
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },
    // [theme.breakpoints.up("md")]: {
    //   display: "none",
    // },
    "& button": {
      color: "#FFFFFF",
    },
  },
}));
function Sidebar({ location }) {
  const classes = useStyles();
  var theme = useTheme();
  const history = useHistory();
  const { account, library } = useWeb3React();
  const auth = useContext(UserContext);
  // const location = useLocation();
  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      onBackdropClick={() => toggleSidebar(layoutDispatch)}
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={!isSidebarOpened}
    >
      {/* <div className={classes.toolbar} /> */}
      <Box className={classes.mobileBackButton} mt={1} mb={1}>
        {isSidebarOpened ? (
          <Link to="/app/dashboard">
            <img
              src="images/logo.png"
              style={{ maxWidth: "65px" }}
              alt="logo"
            />
          </Link>
        ) : (
          <img src="images/logo.png" style={{ maxWidth: "36px" }} alt="logo" />
        )}

        <IconButton
          className="arrowButton"
          onClick={() => toggleSidebar(layoutDispatch)}
          size="small"
        >
          {isSidebarOpened ? (
            <FiArrowLeft
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          ) : (
            <FiArrowRight
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          )}
        </IconButton>
      </Box>
      <Navigation
        // you can use your own router's api to get pathname
        activeItemId={location.pathname}
        onSelect={({ itemId }) => {
          history.push(itemId);
        }}
        items={[
          {
            title: "Home",
            itemId: "/app/dashboard",
            // you can use your own custom Icon component as well
            // icon is optional
            elemBefore: () => <MdDashboard />,
          },
          {
            title: "Stake",
            itemId: "/app/staking",
            elemBefore: () => <FaCoins />,
          },
          // {
          //   title: "Analytics",
          //   itemId: "/app/analytics",
          //   elemBefore: () => <IoMdSwap />,
          // },

          {
            title: "Swap",
            itemId: "/app/swap",
            elemBefore: () => <AiOutlineSwap />,
          },
          // {
          //   title: "Admin Control",
          //   itemId: "/app/admin-control",
          //   elemBefore: () => <RiAdminFill />,
          // },
        ]}
      />

      {auth.adminAddress.contract1 === account && (
        <Navigation
          // you can use your own router's api to get pathname
          activeItemId={location.pathname}
          onSelect={({ itemId }) => {
            history.push(itemId);
          }}
          items={[
            {
              title: "Admin Control",
              itemId: "/app/admin-control",
              elemBefore: () => <RiAdminFill />,
            },
          ]}
        />
      )}
      <Navigation
        activeItemId={location.pathname}
        onSelect={({ itemId }) => {
          window.open(itemId, "_blank");
        }}
        items={[
          {
            title: "About",
            itemId: "https://www.metareflect.io/",
            elemBefore: () => <AiOutlineInfoCircle />,
          },
          {
            title: "Telegram",
            itemId: "https://t.me/metareflect",
            elemBefore: () => <FaTelegramPlane />,
          },
          {
            title: "Twitter",
            itemId: "https://twitter.com/metareflect",
            elemBefore: () => <FaTwitter />,
          },
        ]}
      />
    </Drawer>
  );
  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
