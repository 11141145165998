import React, { useState } from "react";
import BigNumber from "bignumber.js";
import CloseIcon from "@material-ui/icons/Close";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import EditIcon from "@material-ui/icons/Edit";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  makeStyles,
  IconButton,
  Typography,
  Paper,
  Box,
  Checkbox,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  ListItem,
  List,
  InputBase,
} from "@material-ui/core";

import RoiCalculatorModal from "../RoiCalculatorModal";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { light } from "@pancakeswap/uikit";
// import { light } from "@pancakeswap/uikit";
import { ThemeProvider } from "styled-components";

const StyledThemeProvider = (props) => {
  return <ThemeProvider theme={light} {...props} />;
};
const useStyles = makeStyles((theme) => ({
  root: {
    "& .heading": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#000000",
      padding: "15px",
      "& h4": {
        color: "#FFFFFF",
      },
    },
  },
  maincontent: {
    padding: "5px 15px",
    "& .brewlabs": {
      "& p": {
        paddingBottom: "5px",
        color: "#FFFFFF",
      },
      "& .brewlabsbox": {
        border: "1px solid #FFFFFF",
        borderRadius: "15px",
        padding: "10px",
        "& input": {
          backgroundColor: " transparent",
          border: "none",
          color: "#ffff",
          fontSize: "18px",
          fontWeight: 800,
          textAlign: "right",
          width: "100%",
          "&:focus-visible": {
            outline: "none !important",
          },
        },
      },
    },
    "& .buttonbox": {
      padding: "5px 0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& Button": {
        color: "#FFFFFF",
      },
    },
    "& .staked": {
      "& p": {
        padding: "5px 0px",
        color: "#FFFFFF",
      },
    },
    "& .compounding": {
      padding: "10px 0px",
      "& p": {
        padding: "5px 0px",
        color: "#FFFFFF",
      },
    },
    "& .currentrate": {
      border: "1px solid #FFFFFF",
      borderRadius: "15px",
      padding: "15px",
      "& h6": {
        fontWeight: "bold",
        color: "#FFFFFF",
      },
      "& p": {
        color: "#FFFFFF",
      },
      "& .value": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
  },
  mainhelpicon: {
    position: "relative",
    "& .textbox": {
      position: "absolute",
      width: "300px",
      padding: "20px",
      backgroundColor: "#000000",
      borderRadius: "15px",
      right: "-10px",
      top: "-115px",
      display: "none",
      "& p": {
        color: "#FFFFFF",
      },
      "&::after": {
        content: " '' ",
        width: "0",
        height: "0",
        borderLeft: "10px solid transparent",
        borderRight: "10px solid transparent",
        borderTop: "20px solid #000000",
        position: "absolute",
        bottom: "-20px",
        right: "20px",
      },
    },
    "&:hover": {
      "& .textbox": {
        display: "block",
      },
    },
  },
  detailsection: {},
  basecontent: {
    "& .detailcontent": {
      color: "#FFFFFF",
    },
    "& ul": {
      "& li": {
        color: "#FFFFFF",
      },
    },
  },
}));

function RoiCalculator({
  earningTokenSymbol,
  RoiCalculator,
  balanceOf,
  stakeName,
  APY,
  APYpercentage,
  stakeTokenPrice,
  rewardTokenPrice,
}) {
  const classes = useStyles();
  // const [roiCalculator, setRoiCalculator] = useState();
  const [checked, setChecked] = React.useState(true);
  const [alignment, setAlignment] = React.useState("left");
  console.log(APY, "apy----", balanceOf, "balanceOf");

  return (
    <>
      <Box className={classes.root}>
        <Box className="heading">
          <Typography variant="h4">ROI Calculator</Typography>
          {/* <IconButton>
            <CloseIcon
              onClick={() => {
                RoiCalculator();
              }}
            />
          </IconButton> */}
        </Box>
        {APY && (
          <div>
            <StyledThemeProvider>
              <RoiCalculatorModal
                linkLabel={stakeName}
                stakingTokenBalance={BigNumber(APY)} //staking token balance
                stakingTokenSymbol={stakeName} // token name
                stakingTokenPrice={stakeTokenPrice} //token Price im market
                earningTokenPrice={rewardTokenPrice}
                earningTokenSymbol={earningTokenSymbol}
                apr={APYpercentage}
                multiplier={"40X"}
                displayApr={"37.29"}
                linkHref={
                  "https://pancakeswap.finance/add/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                }
                isFarm={true}
              />
            </StyledThemeProvider>
          </div>
        )}
        {/* <Paper className={classes.maincontent}>
          <Box className="brewlabs">
            <Typography
              variant="body2"
              style={{ color: "#f3a52d", fontSize: "14px", fontWeight: 500 }}
            >
              BREWLABS STAKED
            </Typography>
            <Box className="brewlabsbox">
              <Grid container spacing={1}>
                <Grid item xs={12} align="right">
                 
                  <input
                    type="number"
                    min={0}
                    placeholder="0.0"
                    value={fromValue}
                    onChange={(e) => setFromValue(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} align="right">
                  <IconButton>
                    <SwapVertIcon onClick={swapTokenData} />
                  </IconButton>
                </Grid>
                <Grid item xs={12} align="right">
                  <Typography variant="body1">
                    
                    {toValue}BREWLABS
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className="buttonbox">
            <Button fullWidth>$100</Button>
            <Button fullWidth>$1000</Button>
            <Button fullWidth>MY BALANCE</Button>
            <Box className={classes.mainhelpicon}>
              <HelpOutlineIcon />
              <Box className="textbox">
                <Typography variant="body1">
                  “My Balance” here includes both BREWLABS in your wallet, and
                  BREWLABS already staked in this pool.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="staked">
            <Typography
              variant="body2"
              style={{ color: "#f3a52d", fontSize: "14px", fontWeight: 500 }}
            >
              STAKED FOR
            </Typography>
            <Box mt={1}>
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                fullWidth
              >
                <ToggleButton
                  value="left"
                  aria-label="1d"
                  style={{ color: "#fff" }}
                >
                  1D
                </ToggleButton>
                <ToggleButton
                  value="center"
                  aria-label="7d"
                  style={{ color: "#fff" }}
                >
                  7D
                </ToggleButton>
                <ToggleButton
                  value="right"
                  aria-label="30d"
                  style={{ color: "#fff" }}
                >
                  30D
                </ToggleButton>
                <ToggleButton
                  value="justify"
                  aria-label="1Y"
                  style={{ color: "#fff" }}
                >
                  1Y
                </ToggleButton>
                <ToggleButton
                  value="justify"
                  aria-label="5Y"
                  style={{ color: "#fff" }}
                >
                  5Y
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
          <Box className="compounding">
            <Typography variant="body2">COMPOUNDING EVERY</Typography>
            <Box style={{ display: "flex" }}>
              <Checkbox
                defaultChecked
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
              >
                <ToggleButton
                  value="left"
                  aria-label="1d"
                  style={{ color: "#fff" }}
                >
                  1D
                </ToggleButton>
                <ToggleButton
                  value="center"
                  aria-label="7d"
                  style={{ color: "#fff" }}
                >
                  7D
                </ToggleButton>
                <ToggleButton
                  value="justify"
                  aria-label="1Y"
                  style={{ color: "#fff" }}
                >
                  14D
                </ToggleButton>
                <ToggleButton
                  value="right"
                  aria-label="30d"
                  style={{ color: "#fff" }}
                >
                  30D
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowDownwardIcon />
          </Box>
          <Box style={{ padding: "20px 0px" }}>
            <Box className="currentrate">
              <Box>
                <Typography
                  variant="body2"
                  style={{
                    color: "#f3a52d",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  ROI AT CURRENT RATES
                </Typography>
              </Box>
              <Box mt={2} mb={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6} lg={6}>
                    <Typography
                      variant="h3"
                      style={{ fontWeight: "bold", color: "#FFFFFF" }}
                    >
                      $0.00
                    </Typography>
                  </Grid>
                  <Grid item xs={6} lg={6} align="right">
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  style={{ color: "#fff", fontSize: "12px" }}
                >
                  ~ 0 BREWLABS (0.00%)
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.detailsection}>
            <Box mt={2} mb={2}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6" style={{ color: "#FFFFFF" }}>
                    Details
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className={classes.basecontent}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={6} align="left">
                        <Typography variant="body2" className="detailcontent">
                          APR:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} align="right">
                        <Typography variant="body2" className="detailcontent">
                          16.06%
                        </Typography>
                      </Grid>
                      <Grid item xs={6} align="left">
                        <Typography variant="body2" className="detailcontent">
                          APY (1x daily compound)
                        </Typography>
                      </Grid>
                      <Grid item xs={6} align="right">
                        <Typography variant="body2" className="detailcontent">
                          17.42%
                        </Typography>
                      </Grid>
                    </Grid>
                    <ul>
                      <li>Calculated based on current rates.</li>
                      <li>
                        All figures are estimates provided for your convenience
                        only, and by no means represent guaranteed returns.
                      </li>
                    </ul>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Paper> */}
      </Box>
    </>
  );
}

export default RoiCalculator;
