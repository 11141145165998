import React from "react";
import {
  Typography,
  Box,
  makeStyles,
  Button,
  LinearProgress,
  Avatar,
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  NftImg: {
    borderRadius: 10,
    boxShadow: "0 11px 24px rgb(0, 0, 0, 0.12)",
    display: "block",
    miHeight: "300px",
    position: "relative",
  },
  bottomblock: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  bottomTop: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "10px 0 0",
  },
  playbutton: {
    position: "absolute",
    bottom: 5,
    right: 10,
  },
  label: {
    display: "inline-block",
    padding: ".2em .6em .3em",
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "16px",
    color: "#2DE866",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
    borderRadius: ".25em",
    backgroundColor: "#D6FAE1",
    marginTop: "6px",
    fontFamily: "'Montserrat', sans-serif",
  },

  nameBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 20px 0px",
    alignItems: "center",

    "& h4": {
      fontSize: "16px",
      fontWeight: "700",
      color: "#fff",
      marginBottom: "5px",
      textAlign: " right",
    },
    "& p": {
      fontSize: "12px",
      color: "#fff",
    },
    "& img": {
      width: "50px",
      // height: "40px",
      borderRadius: "50%",
    },
  },
}));

export default function NFTCard({
  pageView2,
  type,
  account,
  user,
  StakingHarvest,
  CompoundEarned,
  CompoundDivident,
  StakingDeposit,
  pendingReward,
  pendingDividends,
  StakingWidthdraw,
  stakeName,
  earned,
  reflected,
  APY,
  stakingConAddress,
  buttonLoader,
  harvestLoader,
  totalStaked1,
  depositFee,
  widthdrawFee,
  harvestclaimDividend,
}) {
  const classes = useStyles();

  return (
    <Box className=" width100">
      <Link to="/projects">
        <Box display="flex">
          <Box className="collectionSet width100">
            <Box mt={2} className="boxCard ">
              {/* <Box className="launchname"> */}{" "}
              <Box className={classes.nameBox}>
                {/* <img src="/images/token.png" alt="token" /> */}
                <img src="images/icon.png" alt="" />
                <Box className={classes.iconBox}>
                  <img src="images/gold.png" alt="" style={{ width: "18px" }} />
                </Box>

                <Box className="launchDetails">
                  <Typography variant="h4">
                    {stakeName ? stakeName : "MemePad Old Pool5"}
                  </Typography>
                  <Typography variant="body">RHINO</Typography>{" "}
                </Box>
                {/* <Box className="launchDetails">
                  <Typography variant="h5">RhinoCoin</Typography>
                  <Typography variant="body">RHINO</Typography>{" "}
                </Box> */}
              </Box>
              <Box className="progress">
                <Typography variant="h6">Progress</Typography>
                <LinearProgress variant="determinate" value={30} />
                <Box className="progressData">
                  <Typography variant="body">0</Typography>
                  <Typography variant="body">0.0 / 201.0 BNB</Typography>
                </Box>
              </Box>
              {type === "completed" ? (
                <Box className="rise">
                  <Typography variant="h6">Intial Price</Typography>
                  <Typography variant="h3">201.0 BNB</Typography>
                </Box>
              ) : (
                <Box className="rise">
                  <Typography variant="h6">Total Rises</Typography>
                  <Typography variant="h3">201.0 BNB</Typography>
                </Box>
              )}
              {type === "completed" ? (
                <Box className="sale">
                  <Typography variant="h6">ATH</Typography>
                  <Typography variant="h3">250,000,000,000,000.00</Typography>
                  <span className={classes.label}>30 %</span>
                </Box>
              ) : (
                <Box className="sale">
                  <Typography variant="h6">Rhino For Sale</Typography>
                  <Typography variant="h3">250,000,000,000,000.00</Typography>
                </Box>
              )}
              {type === "completed" ? (
                <Box className="buying">
                  <Typography variant="h6">Price</Typography>
                  <Typography variant="h3">BNB</Typography>
                  <span className={classes.label}>30 %</span>
                </Box>
              ) : (
                <Box className="buying">
                  <Typography variant="h6">Buying Coin</Typography>
                  <Typography variant="h3">BNB</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Link>
    </Box>
  );
}
