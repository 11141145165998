import React, { useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useLocation,
} from "react-router-dom";
import classnames from "classnames";
import { makeStyles, Box } from "@material-ui/core";
import IndexSwap from "../../pages/Swap/IndexSwap";
import IndexForm from "../../pages/AdminControl/IndexForm";
//icons

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Staking from "../../pages/staking/Staking";
// import Swap from "../../pages/Swap/Swap";
import Analytics from "../../pages/Analytics/Analytics";
import LiveStake from "../../pages/staking/LiveStake";
import FinishedStake from "../../pages/staking/FinishedStake";
import Rewards from "../../pages/staking/Rewards";
import Withdraw from "../../pages/staking/Withdraw";
import CreateStake from "../../pages/staking/CreateStake";
import About from "../../pages/About/About";
import Terms from "../../pages/Alert/Terms";
import Privacy from "../../pages/Alert/Privacy";
import Documentation from "../../pages/Alert/Documentation";
import { useLayoutState } from "../../context/LayoutContext";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: "24px 100px 0",
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    minHeight: "100vh",
    // paddingTop: "80px",
    zIndex: "1",
    overflow: "hidden",
    position: "relative",
    "@media (max-width:1440px)": {
      padding: "24px 30px 0",
    },
    [theme.breakpoints.down("md")]: {
      padding: "24px 9px",
      // paddingTop: "80px",
    },
  },
  contentShift: {
    zIndex: " 1",
    width: `calc(99vw - 240px)`,
    position: "absolute",
    right: "0",
    [theme.breakpoints.down("sm")]: {
      width: `100%`,
    },
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    "&:not(:first-child)": {
      paddingLeft: 15,
    },
  },

  buttonright: {
    // background: "linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
    fontSize: "14px ",
    border: "1px solid transparent",
    fontWeight: 600,
    height: "44px ",
    color: "#FFFFFF",
    minWidth: "135px ",
    borderRadius: "5px",
    boxShadow: "none ",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "45px ",
      minWidth: "120px ",
    },
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      color: "#fff",
      transition: "0.5s",
      boxSizing: " border-box",
      border: " 2px solid transparent",
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      backgroundImage:
        "linear-gradient(#000, #000), linear-gradient(#2599fa, #42E8E0)",
    },
  },
  mainBodyBox: {
    minHeight: "calc(100vh - 260px)",
  },
}));
function Layout(props) {
  const classes = useStyles();
  var layoutState = useLayoutState();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <Box className={classes.mainBodyBox}>
            <Switch>
              Staking
              <Route path="/app/dashboard" component={Dashboard} />
              <Route path="/app/About" component={About} />
              <Route path="/app/create-stakE" component={CreateStake} />
              <Route path="/app/withDraw-stake" component={Withdraw} />
              <Route path="/app/staking" component={Staking} />
              <Route path="/app/analytics" component={Analytics} />
              <Route path="/app/live-staking" component={LiveStake} />
              <Route path="/app/finished-staking" component={FinishedStake} />
              <Route path="/app/terms-conditions" component={Terms} />
              <Route path="/app/privacy-policy" component={Privacy} />
              <Route path="/app/documentation" component={Documentation} />
              <Route path="/app/swap" component={IndexSwap} />
              <Route path="/app/admin-control" component={IndexForm} />
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
            </Switch>
          </Box>
          <Footer />
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
